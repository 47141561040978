import React, { useEffect } from "react";
import Navbar from "../components/Navbar.js";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
export default function Choose_preferance() {
  const navigate = useNavigate();
  script();
  function script() {
    $("#first").show();
    $("#second").hide();
    $("#third").hide();
    $("#fourth").hide();
    $("span").click(function () {
      if ($(this).hasClass("first")) {
        $("#nprogress-bar").val("0");
        $(this).nextAll().removeClass("border-change");
        //$('.percent').html("0% Complete");
        $("#first").show();
        $("#second").hide();
        $("#third").hide();
        $("#fourth").hide();
      } else if ($(this).hasClass("second")) {
        $(this).nextAll().removeClass("border-change");
        $("#nprogress-bar").val("34");
        $(this).prevAll().addClass("border-change");
        $(this).addClass("border-change");
        //$('.percent').html("33% Complete");
        $("#second").show();
        $("#first").hide();
        $("#third").hide();
        $("#fourth").hide();
      } else if ($(this).hasClass("third")) {
        $(this).nextAll().removeClass("border-change");
        $("#nprogress-bar").val("67");
        $(this).prevAll().addClass("border-change");
        $(this).addClass("border-change");
        //$('.percent').html("66% Complete");
        $("#second").hide();
        $("#first").hide();
        $("#fourth").hide();
        $("#third").show();
      } else {
        $("#nprogress-bar").val("100");
        $(this).addClass("border-change");
        $(this).prevAll().addClass("border-change");
        //$('.percent').html("100% Complete");
        $("#third").hide();
        $("#first").hide();
        $("#fourth").show();
      }
    });
  }

  return (
    <div>
      <Navbar />
      <div className="page_box pb-5">
        <div className="create_box">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-8 mx-auto">
                <h2 className="h2tag mb-2">Choose your type preference</h2>
                <p className="para">
                  Choose “single” if you want to upload only one NFT. Choose
                  “Multiple” for upload between one to ten. Choose our unique
                  “Bulk Upload” feature for mass upload *Upload a whole
                  collection on a go*{" "}
                  <a href="#" target="_blank">
                    {" "}
                    Learn more about our bulk upload feature
                  </a>
                </p>
              </div>
            </div>

            <div className="flx">
              <div className="flx_box" onClick={() => navigate("/create-nft")}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="NFT"
                    id="single"
                  />
                  <label className="form-check-label" for="single">
                    <svg
                      width="48"
                      height="56"
                      viewBox="0 0 48 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.771094"
                        y="0.15"
                        width="46.4573"
                        height="55.7"
                        stroke="black"
                        stroke-width="0.3"
                      />
                      <rect
                        x="2.7959"
                        y="2.17578"
                        width="42.4078"
                        height="32.6214"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="2.7959"
                        y="39.1445"
                        width="16.3107"
                        height="2.71845"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="2.7959"
                        y="45.6719"
                        width="42.4078"
                        height="2.71845"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="2.7959"
                        y="50.0195"
                        width="42.4078"
                        height="2.71845"
                        fill="#E6E6E6"
                      />
                    </svg>

                    <span className="d-block mt-3">Single</span>
                    <p className="mb-0">For only one NFT upload.</p>
                  </label>
                </div>
              </div>
              <div
                className="flx_box"
                onClick={() => navigate("/create-collection")}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="NFT"
                    id="bulk"
                  />
                  <label className="form-check-label" for="bulk">
                    <span className="best">Best for creators</span>
                    <svg
                      width="59"
                      height="69"
                      viewBox="0 0 59 69"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.677551"
                        y="0.177551"
                        width="25.6857"
                        height="30.8333"
                        stroke="black"
                        stroke-width="0.355102"
                      />
                      <rect
                        x="1.71118"
                        y="1.21094"
                        width="23.6184"
                        height="18.168"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="1.71118"
                        y="21.8008"
                        width="9.08401"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="1.71118"
                        y="25.4336"
                        width="23.6184"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="1.71118"
                        y="27.8594"
                        width="23.6184"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="32.6368"
                        y="0.177551"
                        width="25.6857"
                        height="30.8333"
                        stroke="black"
                        stroke-width="0.355102"
                      />
                      <rect
                        x="33.6704"
                        y="1.21094"
                        width="23.6184"
                        height="18.168"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="33.6704"
                        y="21.8008"
                        width="9.08401"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="33.6704"
                        y="25.4336"
                        width="23.6184"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="33.6704"
                        y="27.8594"
                        width="23.6184"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="0.677551"
                        y="37.283"
                        width="25.6857"
                        height="30.8333"
                        stroke="black"
                        stroke-width="0.355102"
                      />
                      <rect
                        x="1.71118"
                        y="38.3164"
                        width="23.6184"
                        height="18.168"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="1.71118"
                        y="58.9062"
                        width="9.08401"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="1.71118"
                        y="62.5391"
                        width="23.6184"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="1.71118"
                        y="64.9648"
                        width="23.6184"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="32.6368"
                        y="37.283"
                        width="25.6857"
                        height="30.8333"
                        stroke="black"
                        stroke-width="0.355102"
                      />
                      <rect
                        x="33.6704"
                        y="38.3164"
                        width="23.6184"
                        height="18.168"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="33.6704"
                        y="58.9062"
                        width="9.08401"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="33.6704"
                        y="62.5391"
                        width="23.6184"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                      <rect
                        x="33.6704"
                        y="64.9648"
                        width="23.6184"
                        height="1.514"
                        fill="#E6E6E6"
                      />
                    </svg>

                    <span className="d-block mt-3">Bulk</span>
                    <p className="mb-0">Upload a whole collection on a go.</p>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="cont">
          <progress id="nprogress-bar" value="0" max="100"></progress>
          <div id="step">
            <span className="first border-change">
              <i className="fa fa-flask"></i>
            </span>
            <span className="second">
              <i className="fa fa-paint-brush"></i>
            </span>
            <span className="third">
              <i className="fa fa-code"></i>
            </span>
            <span className="fourth">
              <i className="fa fa-rocket"></i>
            </span>
          </div>
        </div> */}

        <div className="wizard-progress">
          <div className="step complete">
            <div>
              <div className="node"></div> Choose blockchain
            </div>
          </div>
          <div className="step inpage">
            Choose Type
            <div className="node"></div>
          </div>
          <div className="step">
            Create NFT
            <div className="node"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
