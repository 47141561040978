import React, { useEffect } from "react";
import Navbar from "../components/Navbar.js";
import search from "../assets/images/search.svg";
import btnarw_white from "../assets/images/btnarw_white.svg";
import { useLocation } from "react-router-dom";
import { getOpenSwapNfts, swapCompleteAction } from "../actions/nfts";
import Web3 from "web3";
import HashLoader from "react-spinners/HashLoader";
import { useState } from "react";
import config from "../lib/config";
import SwapABI from "../ABI/SwapABI.json";
import { useSelector } from "react-redux";
import { toastAlert } from "../lib/toastAlert.js";

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

export default function Swap(props) {
  const { state } = useLocation();
  const walletConnection = useSelector((state) => state.walletConnection);
  console.log(state, "statestatestatestate");
  const [toUserNfts, setToUserNfts] = useState([]);
  const [active, setActive] = useState(false);
  let [loading, setLoading] = useState(false);
  const [active1, setActive1] = useState(false);
  const [actviveNft, setActiveNft] = useState();
  const [searchNFT, setsearchNFT] = useState("");

  useEffect(() => {
    getToUserSwap();
  }, [searchNFT]);

  const getToUserSwap = async () => {
    const payload = {
      address: state?.fromUser
    };
    const swapList = await getOpenSwapNfts(payload);
    console.log(swapList?.data?.data, "edcfedwf");
    const filteredItems = swapList?.data?.data.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(searchNFT.toLowerCase()) !==
        -1
    );
    setToUserNfts(filteredItems);
  };

  const onActive = (nft, i) => {
    setActive(i);
    setActive1(true);
    setActiveNft(nft);
  };

  const swapComplete = async () => {
    console.log(actviveNft, "actviveNft");
    if (walletConnection.address) {
      setLoading(true);
      const web3 = new Web3(walletConnection.provider);
      let signer = walletConnection.address.toLowerCase();
      const messageHash = web3.utils.soliditySha3(Number(actviveNft?.nftId));
      await web3.eth.personal
        .sign(messageHash, signer)
        .then(async (signature) => {
          console.log(signature, "signaturesignature");

          const ContractSwap = new web3.eth.Contract(
            SwapABI,
            config.swapContract
          );
          console.log(
            state?.fromSign,
            signature,
            actviveNft?.nftId,
            state?.fromTokenId,
            state?.fromContract,
            actviveNft?.contractAddress,
            "efcedf__dewfd"
          );
          await ContractSwap.methods
            .swapNFT(
              state?.fromSign,
              signature,
              actviveNft?.nftId,
              state?.fromTokenId,
              actviveNft?.contractAddress,
              state?.fromContract
            )
            .send({ from: walletConnection.address })
            .then(async (result) => {
              console.log(result, "ResulttttttT____________");
              const payload = {
                currAddr: walletConnection.address,
                sign: signature,
                swapFromAddr: state?.fromUser,
                nftId: actviveNft.nftId,
                condAddr: actviveNft.contractAddress,
                nftName: actviveNft.nftName,
                fromNFT: state?.fromTokenId,
                fromContractAddress: state?.fromContract,
                toContractAddress: actviveNft?.contractAddress
              };
              console.log(payload, "payload");
              const comp = await swapCompleteAction(payload);
              if (comp?.data?.status) {
                toastAlert("success", comp?.data?.message);
                setTimeout(() => {
                  setLoading(false);
                  window.location.href = "/";
                }, 2000);
              }
            })
            .catch((err) => {
              toastAlert("error", "Transaction rejected");
              setLoading(false);
              console.log(err);
            });
        })
        .catch((err) => {
          toastAlert("error", "Sign rejected");
          setLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Navbar />
      {localStorage.getItem("nicholousWallAddr") ? (
        <div className="page_box">
          <div className="explore swap">
            <div className="container">
              <div className="text-center row">
                <div className="col-lg-9 mx-auto">
                  <h2 className="h2tag mb-2">
                    Choose the Nft you want to swap with
                  </h2>
                  <p className="para head">
                    Choose among your items the one you would like to swap with
                    the other party and wait for the other party to accept it.
                  </p>
                </div>
              </div>
              <div className="h_option">
                <div className="input-group sr_ch">
                  <span className="input-group-text">
                    <img src={search} alt="img" className="img-fluid" />
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    id="floatingInputGroup1"
                    placeholder="Search item"
                    onChange={(e) => setsearchNFT(e.target.value)}
                  />
                </div>
              </div>
              {/* add active cls for slected box  */}
              <section className="popular_nft bg-transparent swap_pad">
                <div className="row flex-wrap">
                  {toUserNfts?.length > 0 ? (
                    toUserNfts.map((nft, i) => {
                      return (
                        <div className="col-lg-3">
                          <div className="box" onClick={() => onActive(nft, i)}>
                            <div className="img_box">
                              {nft.localImage.split(".").pop() == "mp4" ? (
                                <>
                                  {nft.isCollNft ? (
                                    <video
                                      src={`${config.baseUrl}/${nft.localImage}`}
                                      style={{ width: "100%" }}
                                      type="video/mp4"
                                      alt="Collections"
                                      className="lazy nft__item_preview nft__item_preview_video"
                                      controls
                                      controlsList="nodownload"
                                    />
                                  ) : (
                                    <video
                                      src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                      style={{ width: "100%" }}
                                      type="video/mp4"
                                      alt="Collections"
                                      // className="lazy nft__item_preview nft__item_preview_video"
                                      className={
                                        nft.sensitiveContent
                                          ? "lazy nft__item_preview nft__item_preview_video blue_img"
                                          : "lazy nft__item_preview nft__item_preview_video"
                                      }
                                      controls
                                      controlsList="nodownload"
                                    />
                                  )}
                                </>
                              ) : nft.localImage.split(".").pop() == "mp3" ? (
                                <>
                                  {nft.isCollNft ? (
                                    <div style={{ textAlign: "center" }}>
                                      <img
                                        style={{
                                          padding: "16px",
                                          width: "256px",
                                          height: "256px"
                                        }}
                                        src={
                                          config.baseUrl +
                                          "/" +
                                          "images/music.png"
                                        }
                                        alt="img"
                                        className="img-fluid"
                                      />
                                      <audio
                                        src={`${config.baseUrl}/${nft.localImage}`}
                                        type="audio/mp3"
                                        controls
                                        controlsList="nodownload"
                                        className="audio home"
                                      ></audio>
                                    </div>
                                  ) : (
                                    <div style={{ textAlign: "center" }}>
                                      <img
                                        style={{
                                          padding: "16px",
                                          width: "256px",
                                          height: "256px"
                                        }}
                                        src={
                                          config.baseUrl +
                                          "/" +
                                          "images/music.png"
                                        }
                                        alt="img"
                                        className="img-fluid"
                                      />
                                      <audio
                                        src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                        type="audio/mp3"
                                        controls
                                        controlsList="nodownload"
                                        className="audio home"
                                      ></audio>
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {nft.isCollNft ? (
                                    <img
                                      src={`${config.baseUrl}/${nft.localImage}`}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  ) : (
                                    <img
                                      src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                      alt="img"
                                      // className="img-fluid"
                                      className={
                                        nft.sensitiveContent
                                          ? "img-fluid blue_img"
                                          : "img-fluid"
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </div>
                            <div className="blw_box">
                              <h5>{nft.nftName}</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>
                                    {nft.price} {config.Currency}
                                  </h6>
                                </div>
                                {/* <div>
                                <span>Last Sale</span>
                                <p>4.56 MATIC</p>
                              </div> */}
                              </div>
                              <div
                                className={`round_dot ${
                                  active1 && active == i ? "darked" : ""
                                }`}
                              ></div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <>No data</>
                  )}
                </div>
              </section>
            </div>
          </div>
          <div className="complete_swap text-end">
            <div className="container">
              {actviveNft ? (
                <button className="primary_btn dark" onClick={swapComplete}>
                  <span>Complete swap</span>
                  <img
                    src={btnarw_white}
                    alt="img"
                    className="img-fluid ms-3"
                  />
                </button>
              ) : (
                <button className="primary_btn dark" disabled={true}>
                  Please select any NFT to swap
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <h3 style={{ textAlign: "center" }}>Please connect wallet</h3>
      )}

      {loading && (
        <div className="loadBefore">
          <HashLoader
            color={"#9a55ff"}
            loading={loading}
            cssOverride={override}
            size={60}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </div>
  );
}
