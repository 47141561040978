import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";
import info from "../../assets/images/info.svg";
import Sidebar from "../../components/sidebar.js";
import btnarw_white from "../../assets/images/btnarw_white.svg";
import { useSelector } from "react-redux";
import { getsingleUsers, notifivationEnables } from "../../actions/users";
import { toastAlert } from "../../lib/toastAlert.js";

export default function Notification(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  // const getUsers = useSelector((state) => state.getUsers);
  const [SalesEnable, setSales] = useState(false);
  const [listingsEnable, setListings] = useState(false);
  const [successBids, setSuccessBids] = useState(false);
  const [ExpireBids, setExpireBids] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [getUsers, setGetuser] = useState();

  const CheckedChange = (e) => {
    if (e && e.target && e.target.name) {
      if (e.target.name == "salesEnable") {
        if (SalesEnable == false) {
          setSales(true);
        } else {
          setSales(false);
        }
      } else if (e.target.name == "listingEnable") {
        console.log(listingsEnable, "listingEnable");
        if (listingsEnable == false) {
          setListings(true);
        } else {
          setListings(false);
        }
      } else if (e.target.name == "acceptBidEnable") {
        if (successBids == false) {
          setSuccessBids(true);
        } else {
          setSuccessBids(false);
        }
      } else if (e.target.name == "expireBidEnable") {
        if (ExpireBids == false) {
          setExpireBids(true);
        } else {
          setExpireBids(false);
        }
      } else if (e.target.name == "transferEnable") {
        if (transfer == false) {
          setTransfer(true);
        } else {
          setTransfer(false);
        }
      }
    }
  };

  useEffect(() => {
    getSingU();
  }, [walletConnection.address]);

  const getSingU = async () => {
    if (walletConnection.address) {
      const payload = {
        currAddr: walletConnection.address
      };
      const data = await getsingleUsers(payload);
      console.log(data, "data");
      setGetuser(data?.user);
      setSales(data?.user?.notifications?.sales);
      setListings(data?.user?.notifications?.listings);
      setSuccessBids(data?.user?.notifications?.successBids);
      setExpireBids(data?.user?.notifications?.expireBids);
      setTransfer(data?.user?.notifications?.transfer);
    }
  };

  console.log(
    SalesEnable,
    listingsEnable,
    successBids,
    ExpireBids,
    transfer,
    "TrueOrFalse"
  );
  const handleSubmit = async () => {
    if (walletConnection.address) {
      const payload = {
        address: walletConnection.address,
        SalesEnable,
        listingsEnable,
        successBids,
        ExpireBids,
        transfer
      };
      console.log(payload, "fedfedfdes");
      const data = await notifivationEnables(payload);
      if (data?.data?.status) {
        toastAlert("success", data?.data?.message);
      }
    }
  };

  return (
    <div>
      <Navbar />
      {localStorage.getItem("nicholousWallAddr") ? (
        <div className="page_box settingpad">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <Sidebar />
              </div>
              <div className="col-lg-7">
                <div className="right_box">
                  <div class="create_nft half_side">
                    <div className="mb-4">
                      <label for="exampleInputEmail1" className="form-label">
                        Notification{" "}
                      </label>
                      <p>
                        Select the kind of notification you’d like to be getting
                        in your email{" "}
                      </p>
                    </div>

                    <div className="mb-4">
                      <div className="form-check form-switch">
                        <p className="mb-0">
                          Sales
                          <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Sales">
                                  <img
                                    src={info}
                                    alt="img"
                                    className="ms-2 img-fluid"
                                    style={{ cursor: 'pointer' }}
                                  />
                                </span>
                        </p>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="salesEnable"
                          name="salesEnable"
                          // defaultValue={getUsers?.notifications?.sales}
                          defaultChecked={getUsers?.notifications?.sales}
                          onClick={(e) => CheckedChange(e)}
                        />
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="form-check form-switch">
                        <p className="mb-0">
                          Listings
                          <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Listings">
                                  <img
                                    src={info}
                                    alt="img"
                                    className="ms-2 img-fluid"
                                    style={{ cursor: 'pointer' }}
                                  />
                                </span>
                        </p>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="listingEnable"
                          name="listingEnable"
                          // defaultValue={getUsers?.notifications?.listings}
                          defaultChecked={getUsers?.notifications?.listings}
                          onClick={(e) => CheckedChange(e)}
                        />
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="form-check form-switch">
                        <p className="mb-0">
                          Successful bids
                          <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Successful bids">
                                  <img
                                    src={info}
                                    alt="img"
                                    className="ms-2 img-fluid"
                                    style={{ cursor: 'pointer' }}
                                  />
                                </span>
                        </p>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="acceptBidEnable"
                          name="acceptBidEnable"
                          // defaultValue={getUsers?.notifications?.successBids}
                          defaultChecked={getUsers?.notifications?.successBids}
                          onClick={(e) => CheckedChange(e)}
                        />
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="form-check form-switch">
                        <p className="mb-0">
                          Expired bids
                          <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Expired bids">
                                  <img
                                    src={info}
                                    alt="img"
                                    className="ms-2 img-fluid"
                                    style={{ cursor: 'pointer' }}
                                  />
                                </span>
                        </p>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="expireBidEnable"
                          name="expireBidEnable"
                          // defaultValue={getUsers?.notifications?.expireBids}
                          defaultChecked={getUsers?.notifications?.expireBids}
                          onClick={(e) => CheckedChange(e)}
                        />
                      </div>
                    </div>

                    <div className="mb-4">
                      <div className="form-check form-switch">
                        <p className="mb-0">
                          Transfers
                          <span class="d-inline-block" tabindex="0" data-bs-toggle="tooltip" title="Transfers">
                                  <img
                                    src={info}
                                    alt="img"
                                    className="ms-2 img-fluid"
                                    style={{ cursor: 'pointer' }}
                                  />
                                </span>
                        </p>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="transferEnable"
                          name="transferEnable"
                          // defaultValue={getUsers?.notifications?.transfer}
                          defaultChecked={getUsers?.notifications?.transfer}
                          onClick={(e) => CheckedChange(e)}
                        />
                      </div>
                      <button
                        onClick={handleSubmit}
                        className="primary_btn dark w-100 mt-5"
                      >
                        Submit
                        <img
                          src={btnarw_white}
                          alt="img"
                          className="ms-3 img-fluid"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h3 style={{ textAlign: "center" }}>Please connect wallet</h3>
      )}

      <Footer />
    </div>
  );
}
