import React from "react";
import Navbar from "../components/Navbar.js";
import polygonlogo from "../assets/images/polygon_logo.svg";
import { useNavigate } from "react-router-dom";

export default function Choose_blockchain() {
  const navigate = useNavigate();

  return (
    <div>
      <Navbar />
      <div className="page_box">
        <div className="create_box">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-8 mx-auto">
                <h2 className="h2tag mb-2">Choose your preferred blockchain</h2>
                <p className="para">
                  Choose the most suitable blockchain for your needs.And note,
                  you need to connect your wallet for you to be able to create
                  your NFT.{" "}
                  <a href="#" target="_blank">
                    {" "}
                    Learn more about wallet
                  </a>
                </p>
              </div>
            </div>

            <div className="flx">
              <div
                className="flx_box"
                onClick={() => navigate("/choose-preference")}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="polygon"
                    id="polygon"
                  />
                  <label className="form-check-label" for="polygon">
                    <img src={polygonlogo} alt="img" className="img-fluid" />
                    <span className="d-block mt-3">Polygon</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wizard-progress">
          <div className="step inpage in_progress">
            <div>
              <div className="node"></div> Choose blockchain
            </div>
          </div>
          <div className="step">
            Choose Type
            <div className="node"></div>
          </div>
          <div className="step">
            Create NFT
            <div className="node"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
