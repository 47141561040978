import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import plus from "../assets/images/plus.svg";
import polygonicon from "../assets/images/polygon_icon.svg";
import copyIcon from "../assets/images/copy.svg";
import option from "../assets/images/option.svg";
import twitter from "../assets/images/twitter.svg";
import instagram from "../assets/images/instagram.svg";
import facebook from "../assets/images/facebook.svg";
import upload from "../assets/images/upload.svg";
import swap_btnarw from "../assets/images/swap_btnarw.svg";
import Web3 from "web3";
import activities_user from "../assets/images/activities_user.svg";
import activities_arw from "../assets/images/activities_arw.svg";
import msg from "../assets/images/msg.svg";
import copy from "copy-to-clipboard";
import config from "../lib/config";
import { useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { halfAddrShow } from "../helper/halfAddr.js";
import {
  getCategory,
  getOwnNFT,
  getOpenSwapNfts,
  getTransactionByUser,
  getSwapHist,
  checkUserreport
} from "../actions/nfts";
import moment from "moment";
import Countdown from "react-countdown";
import { toastAlert } from "../lib/toastAlert";
import {
  getOwnerByAddr,
  FollowCallAction,
  User_Follow_Get_Action,
  initMsgUpdate,
  SetReportpage,
  getOwnCollectionList
} from "../actions/users";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Numberconvert } from "../helper/numberConvert.js";

const ReportPageValidate = Yup.object().shape({
  reporttitle: Yup.string()
    .required("Required")
    .test('value',
      'Required',
      (value) => value.trim().length != 0
    )
    .matches(
      /^([a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only normal character"
    ),
  reportcontent: Yup.string()
    .required("Required")
    .test('value',
      'Required',
      (value) => value.trim().length != 0
    )
  // .matches(
  //   /^([a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
  //   "Insert only normal character"
  // )
});

export default function Profile1(props) {
  const navigate = useNavigate();
  const { address } = useParams();
  const [copyTxt, setCopyTxt] = useState(false);
  // const getUsers = useSelector((state) => state.getUsers);
  const walletConnection = useSelector((state) => state.walletConnection);
  const [myNft, setMyNft] = useState([]);
  const [ownNft, setOwnNft] = useState([]);
  const [getUsers, setUser] = useState();
  const [isFollow, SetIsFollow] = useState(false);
  const [FollowingCount, setFollowingCount] = useState(0);
  const [FollowerCount, setFollowerCount] = useState(0);
  const [cateFilter, setCategoryFilter] = useState("all");
  const [catagory, setCatagory] = useState([]);
  const [openSwap, setOpenSwap] = useState([]);
  const [CollectionList, setCollectionList] = useState([]);
  const [activity, setActivity] = useState([]);
  const [swapHis, setSwapHis] = useState([]);
  const [disabledbtn, setdisabledbtn] = useState(false)
  // console.log(getUsers, "getUsers");

  const renderer1 = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <span></span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s to start
        </span>
      );
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [])

  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <span>Ended</span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    }
  };

  useEffect(() => {
    getOwnNft();
    getOwnerDetails();
    Check_follow();
    getAllCatagory();
    getCollectionList();
    openSwapNfts();
  }, [walletConnection.address, cateFilter]);

  const halfAddrShow1 = (addr) => {
    if (addr) {
      return addr.substring(0, 3) + "......" + addr.slice(addr.length - 4);
    } else {
      return "";
    }
  };

  useEffect(() => {
    getTrans();
  }, [walletConnection.address]);

  const getOwnerDetails = async () => {
    const payload = {
      address
    };
    const OnrData = await getOwnerByAddr(payload);
    console.log(OnrData, "OnrData");
    setUser(OnrData?.data?.data);
  };

  const getAllCatagory = async () => {
    console.log("log");
    const result = await getCategory();
    console.log(result, "resultresulty");
    setCatagory(result?.data?.data);
  };

  const getCollectionList = async () => {
    const payload = {
      address: address
    };
    console.log(payload, "payload    ");
    const myData = await getOwnCollectionList(payload, cateFilter);
    console.log(myData, "myData");

    setCollectionList(myData.data.collection);
  };
  const getOwnNft = async () => {
    const payload = {
      address: address
    };
    console.log(payload, "payload    ");
    const myData = await getOwnNFT(payload, cateFilter);
    console.log(myData, "myData");
    setMyNft(myData?.data?.dataC);
    setOwnNft(myData?.data?.dataO);
  };

  const Check_follow = async () => {
    if (walletConnection.address) {
      const payload = {
        userAddr: walletConnection.address,
        followaddress: address
      };

      const follow = await User_Follow_Get_Action(payload);
      console.log(follow, "count");
      SetIsFollow(follow?.data?.isFollow);
      setFollowingCount(follow?.data?.following);
      setFollowerCount(follow?.data?.followers);
    }
  };

  const FollowAction_Call = async () => {
    if (walletConnection.address) {
      const web3 = new Web3(walletConnection.provider);
      console.log(web3, "web3web3web3");
      let msg = "I would like to follow user: " + address;
      if (isFollow == "yes") {
        msg = "I would like to stop follow user: " + address;
      }
      // await web3.eth.sign(msg, walletConnection.address);

      const payload = {
        currAddr: walletConnection.address,
        userParamAddr: address
      };

      const follow = await FollowCallAction(payload);
      console.log(follow, "changeType");
      SetIsFollow(follow?.data?.changeType);

      Check_follow();
    }
  };

  const checkReportUser = async () => {
    const payload = {
      useraddress: address,
      address: walletConnection.address
    }
    const result = await checkUserreport(payload)
    console.log(result, 'resultresultresultresult11111111111111111111');
    if (result.status) {
      toastAlert('error', result.message)
    } else {
      // Reportmodelref.current.focus()
      window.$("#" + "Reportconfirm").modal("show");
    }

  }

  const initialMessage = async () => {
    if (walletConnection.address) {
      const payload = {
        address,
        currAddr: walletConnection.address
      };
      const iniMsd = await initMsgUpdate(payload);
      if (iniMsd.data?.status) {
        navigate("/chat", { state: address });
      }
    }
  };

  const openSwapNfts = async () => {
    if (walletConnection.address) {
      const payload = {
        address: address
      };
      const data = await getOpenSwapNfts(payload);
      console.log(data, "SwapData");
      setOpenSwap(data?.data?.data);
    }
  };

  const SwapHist = async () => {
    if (walletConnection.address) {
      const payload = {
        address: getUsers?.curraddress
      };
      const data = await getSwapHist(payload);
      console.log(data, "SwapDataHid");
      setSwapHis(data?.data?.data);
    }
  };

  const getTrans = async () => {
    if (walletConnection.address) {
      const payload = {
        address: address
      };
      const data = await getTransactionByUser(payload);
      console.log(data, "datadatadatadata");
      setActivity(data?.data?.data);
    }
  };

  const changeFilter = (e) => {
    console.log(e.target.value, "changeValue");
    setCategoryFilter(e.target.value);
  };

  return (
    <div>
      <Navbar />
      {localStorage.getItem("nicholousWallAddr") ? (
        <>
          <div className="page_box prf_page">
            <div className="prf_ban">
              <img
                src={
                  getUsers?.coverimage
                    ? config.baseUrl + "/usersProfile/" + getUsers?.coverimage
                    : require("../assets/images/prf_ban.png")
                }
                alt="img"
                className="img-fluid prf_banimg"
              />
              <div className="container">
                <div className="prf_detail">
                  <div className="prf_ban_user">
                    <img
                      src={
                        getUsers?.image
                          ? config.baseUrl + "/usersProfile/" + getUsers?.image
                          : require("../assets/images/LogosSampleAvator.png")
                      }
                      alt="img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="row">
                    <div className="col-xl-9">
                      <h2 className="h2tag">
                        {getUsers?.name
                          ? getUsers?.name
                          : halfAddrShow(getUsers?.curraddress)}
                      </h2>
                      {walletConnection.address != getUsers?.curraddress && (
                        <div className="btn_flx">
                          <div style={{ display: 'flex' }}>


                            <button
                              className="primary_btn"
                              onClick={FollowAction_Call}
                            >
                              <span>
                                {isFollow == "yes" ? "Unfollw" : "Follow"}
                              </span>
                              <img
                                src={plus}
                                alt="img"
                                className="img-fluid ms-3"
                              />
                            </button>
                            {/* <Link to="/chat" state={address}> */}
                            <button
                              className="primary_btn msg"
                              onClick={initialMessage}
                            >
                              Message
                            </button>
                            {/* </Link> */}
                          </div>

                          <div className="d-flex align-items-center">
                            <button
                              className="primary_btn addr mb-0"
                              onClick={() => {
                                copy(getUsers?.curraddress);
                                setCopyTxt(true);
                                setTimeout(function () {
                                  setCopyTxt(false);
                                }, 800);
                              }}
                            >
                              <span>{halfAddrShow(getUsers?.curraddress)}</span>
                              {copyTxt ? (
                                <i
                                  className="fa fa-check-circle ms-3"
                                  aria-hidden="true"
                                  style={{ color: "green" }}
                                ></i>
                              ) : (
                                <img
                                  src={copyIcon}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              )}
                            </button>
                            {/* <ul className="social mb-0 social_mob mb-0">
                              <li>
                                <a href="#" target="_blank">
                                  <img
                                    src={msg}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                </a>
                              </li>
                            </ul> */}
                            
                            <div className="dropdown mb-0">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img
                                  src={option}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </button>
                              <ul
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton1"
                              >
                                <li>
                                  <a
                                    className="dropdown-item"
                                    href="#"
                                    onClick={() => checkReportUser()}
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#Reportconfirm"
                                  >
                                    Report User
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      )}

                      <p className="para pb-3">{getUsers?.bio}</p>
                      <ul className="social mb-0">
                        <li>
                          <a
                            class="dropdown-item"
                            href={`https://www.facebook.com/sharer/sharer.php?u=${config.frontUrl}/collection/${getUsers?.facebook}`}
                            target="_blank"
                          >
                            <img src={facebook} alt="img" className="img-fluid" />
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href={`https://twitter.com/share?url=${config.frontUrl}/collection/${getUsers?.twitter}`}
                            target="_blank"
                          >
                            <img src={twitter} alt="img" className="img-fluid" />
                          </a>
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            href={`http://pinterest.com/pin/create/button/?url=${config.frontUrl}/collection/${getUsers?.instagram}`}
                            target="_blank"
                          >
                            <img
                              src={instagram}
                              alt="img"
                              className="img-fluid"
                            />
                          </a>
                        </li>
                        {/* <li>
                        <a href="#" target="_blank">
                          <img src={upload} alt="img" className="img-fluid" />
                        </a>
                      </li> */}
                      </ul>
                    </div>
                    <div className="col-xl-3">
                      <div className="box_flx">
                        <div className="box">
                          <p className="para">Followers</p>
                          <h4>{FollowerCount}</h4>
                        </div>
                        <div className="box">
                          <p className="para">Following</p>
                          <h4>{FollowingCount}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="explore">
              <div className="container">
                <div className="overflow">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active ps-0"
                        id="one-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#one"
                        type="button"
                        role="tab"
                        aria-controls="one"
                        aria-selected="true"
                        onClick={() => setCategoryFilter("all")}
                      >
                        Created
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="two-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#two"
                        type="button"
                        role="tab"
                        aria-controls="two"
                        aria-selected="false"
                        onClick={() => setCategoryFilter("all")}
                      >
                        Owned
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="three-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#three"
                        type="button"
                        role="tab"
                        aria-controls="three"
                        aria-selected="false"
                      >
                        Collections
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="four-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#four"
                        type="button"
                        role="tab"
                        aria-controls="four"
                        aria-selected="false"
                      >
                        Swap history
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="five-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#five"
                        type="button"
                        role="tab"
                        aria-controls="five"
                        aria-selected="false"
                      >
                        Open swaps
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="six-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#six"
                        type="button"
                        role="tab"
                        aria-controls="six"
                        aria-selected="false"
                      >
                        Activities
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="one"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                  >
                    <div className="h_option">
                      <div className="flx">
                        <div className="sel">
                          <select
                            value={cateFilter}
                            onChange={(e) => changeFilter(e)}
                          >
                            <option value={"all"}>All Categories</option>
                            {catagory &&
                              catagory.length > 0 &&
                              catagory.map((list) => {
                                return (
                                  <>
                                    <option value={`${list.Category}`}>
                                      {list.Category}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                        <div className="icon">
                          <img
                            src={polygonicon}
                            alt="img"
                            className="img-fluid user"
                          />
                        </div>
                      </div>
                    </div>
                    <section className="popular_nft bg-transparent">
                      <div className="row flex-wrap">
                        {myNft.length > 0 &&
                          myNft.map((nft) => {
                            let currDate = new Date();
                            let startdate = new Date(nft.startTime);
                            let enddate = new Date(nft.endTime);
                            let auction = "false";
                            let finish = "";
                            let enddate1 = "";
                            let showlist = "true";
                            let display_item = 0;
                            if (nft.PutOnSaleType == "TimedAuction") {
                              auction = "true";
                              let a = moment(nft.startTime);
                              let b = moment(nft.endTime);
                              let c = moment();
                              a.diff(b); // 86400000
                              let diffInMs = a.diff(c);
                              display_item = a.diff(c);
                              finish = b.diff(c);
                              enddate1 = parseFloat(diffInMs);
                              if (finish > 0) {
                                showlist = "true";
                              } else {
                                var auctionTxt = "Ended";
                                showlist = "false";
                              }
                            }
                            return (
                              <div className="col-lg-3">
                                <div className="box">
                                  {nft.PutOnSaleType == "TimedAuction" &&
                                    showlist == "true" &&
                                    display_item <= 0 && (
                                      <div className="de_countdown">
                                        <Countdown
                                          date={enddate}
                                          autoStart={true}
                                          onStart={() => startdate}
                                          renderer={renderer}
                                        />
                                      </div>
                                    )}
                                  {nft.PutOnSaleType == "TimedAuction" &&
                                    showlist == "true" &&
                                    display_item > 0 && (
                                      <div className="de_countdown">
                                        <Countdown
                                          date={startdate}
                                          autoStart={true}
                                          onStart={() => Date.now()}
                                          renderer={renderer1}
                                        />
                                      </div>
                                    )}
                                  <div className="img_box">
                                    {nft.localImage.split(".").pop() ==
                                      "mp4" ? (
                                      <>
                                        {nft.isCollNft ? (
                                          <video
                                            src={`${config.baseUrl}/${nft.localImage}`}
                                            style={{ width: "100%" }}
                                            type="video/mp4"
                                            alt="Collections"
                                            className="lazy nft__item_preview nft__item_preview_video"
                                            controls
                                            controlsList="nodownload"
                                          />
                                        ) : (
                                          <video
                                            src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                            style={{ width: "100%" }}
                                            type="video/mp4"
                                            alt="Collections"
                                            className="lazy nft__item_preview nft__item_preview_video"
                                            controls
                                            controlsList="nodownload"
                                          />
                                        )}
                                      </>
                                    ) : nft.localImage.split(".").pop() ==
                                      "mp3" ? (
                                      <>
                                        {nft.isCollNft ? (
                                          <div style={{ textAlign: "center" }}>
                                            <img
                                              style={{
                                                padding: "16px",
                                                width: "256px",
                                                height: "256px"
                                              }}
                                              src={
                                                config.baseUrl +
                                                "/" +
                                                "images/music.png"
                                              }
                                              alt="img"
                                              className="img-fluid"
                                            />
                                            <audio
                                              src={`${config.baseUrl}/${nft.localImage}`}
                                              type="audio/mp3"
                                              controls
                                              controlsList="nodownload"
                                              className="audio home"
                                            ></audio>
                                          </div>
                                        ) : (
                                          <div style={{ textAlign: "center" }}>
                                            <img
                                              style={{
                                                padding: "16px",
                                                width: "256px",
                                                height: "256px"
                                              }}
                                              src={
                                                config.baseUrl +
                                                "/" +
                                                "images/music.png"
                                              }
                                              alt="img"
                                              className="img-fluid"
                                            />
                                            <audio
                                              src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                              type="audio/mp3"
                                              controls
                                              controlsList="nodownload"
                                              className="audio home"
                                            ></audio>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {nft.isCollNft ? (
                                          <img
                                            src={`${config.baseUrl}/${nft.localImage}`}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        ) : (
                                          <img
                                            src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="blw_box">
                                    <h5>{nft.nftName}</h5>
                                    <div className="flx_ctn">
                                      <div className="mb-3">
                                        <span>Price</span>
                                        <h6>
                                          {Numberconvert(nft.price)}{" "}
                                          {config.Currency}
                                        </h6>
                                      </div>
                                      <div>
                                        <span>Last Sale</span>
                                        <p>
                                          {" "}
                                          {nft?.result
                                            ? Numberconvert(
                                              nft?.result?.amount
                                            ) + " MATIC"
                                            : "Not yet"}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="two"
                    role="tabpanel"
                    aria-labelledby="two-tab"
                  >
                    <div className="h_option">
                      <div className="flx">
                        <div className="sel">
                          <select
                            value={cateFilter}
                            onChange={(e) => changeFilter(e)}
                          >
                            <option value={"all"}>All Categories</option>

                            {catagory && catagory.length > 0
                              ? catagory.map((list) => {
                                return (
                                  <option value={`${list.Category}`}>
                                    {list.Category}
                                  </option>
                                );
                              })
                              : ""}
                          </select>
                        </div>
                        <div className="icon">
                          <img
                            src={polygonicon}
                            alt="img"
                            className="img-fluid user"
                          />
                        </div>
                      </div>
                    </div>
                    <section className="popular_nft bg-transparent">
                      <div className="row flex-wrap">
                        {ownNft.length > 0 &&
                          ownNft.map((nft) => {
                            return (
                              <div className="col-lg-3">
                                <div className="box">
                                  <div className="img_box">
                                    {nft.localImage.split(".").pop() ==
                                      "mp4" ? (
                                      <>
                                        {nft.isCollNft ? (
                                          <video
                                            src={`${config.baseUrl}/${nft.localImage}`}
                                            style={{ width: "100%" }}
                                            type="video/mp4"
                                            alt="Collections"
                                            className="lazy nft__item_preview nft__item_preview_video"
                                            controls
                                            controlsList="nodownload"
                                          />
                                        ) : (
                                          <video
                                            src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                            style={{ width: "100%" }}
                                            type="video/mp4"
                                            alt="Collections"
                                            className="lazy nft__item_preview nft__item_preview_video"
                                            controls
                                            controlsList="nodownload"
                                          />
                                        )}
                                      </>
                                    ) : nft.localImage.split(".").pop() ==
                                      "mp3" ? (
                                      <>
                                        {nft.isCollNft ? (
                                          <div style={{ textAlign: "center" }}>
                                            <img
                                              style={{
                                                padding: "16px",
                                                width: "256px",
                                                height: "256px"
                                              }}
                                              src={
                                                config.baseUrl +
                                                "/" +
                                                "images/music.png"
                                              }
                                              alt="img"
                                              className="img-fluid"
                                            />
                                            <audio
                                              src={`${config.baseUrl}/${nft.localImage}`}
                                              type="audio/mp3"
                                              controls
                                              controlsList="nodownload"
                                              className="audio home"
                                            ></audio>
                                          </div>
                                        ) : (
                                          <div style={{ textAlign: "center" }}>
                                            <img
                                              style={{
                                                padding: "16px",
                                                width: "256px",
                                                height: "256px"
                                              }}
                                              src={
                                                config.baseUrl +
                                                "/" +
                                                "images/music.png"
                                              }
                                              alt="img"
                                              className="img-fluid"
                                            />
                                            <audio
                                              src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                              type="audio/mp3"
                                              controls
                                              controlsList="nodownload"
                                              className="audio home"
                                            ></audio>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {nft.isCollNft ? (
                                          <img
                                            src={`${config.baseUrl}/${nft.localImage}`}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        ) : (
                                          <img
                                            src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        )}
                                      </>
                                    )}
                                    {/* <img
                                  src={require("../assets/images/p1.png")}
                                  alt="img"
                                  className="img-fluid"
                                /> */}
                                  </div>
                                  <div className="blw_box">
                                    <h5>{nft.nftName}</h5>
                                    <div className="flx_ctn">
                                      <div className="mb-3">
                                        <span>Price</span>
                                        <h6>
                                          {Numberconvert(nft.price)} MATIC
                                        </h6>
                                      </div>
                                      <div>
                                        <span>Last Sale</span>
                                        <p>
                                          {" "}
                                          {nft?.result
                                            ? Numberconvert(
                                              nft?.result?.amount
                                            ) + " MATIC"
                                            : "Not yet"}{" "}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="three"
                    role="tabpanel"
                    aria-labelledby="three-tab"
                  >
                    <div className="h_option">
                      <div className="flx">
                        <div className="sel">
                          <select
                            value={cateFilter}
                            onChange={(e) => changeFilter(e)}
                          >
                            <option value={"all"}>All Categories</option>
                            {catagory &&
                              catagory.length > 0 &&
                              catagory.map((list) => {
                                return (
                                  <>
                                    <option value={`${list.Category}`}>
                                      {list.Category}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                        <div className="icon">
                          <img
                            src={polygonicon}
                            alt="img"
                            className="img-fluid user"
                          />
                        </div>
                      </div>
                    </div>
                    <section className="recent_swap pt-4 mt-3">
                      <div className="table-responsive">
                        <table className="table table-primary">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>COLLECTION</th>
                              <th>VOLUME</th>
                              <th>% CHANGE</th>
                              <th>FLOOR PRICE</th>
                              {/* <th>% CHANGE</th> */}
                              <th>ITEMS</th>
                              <th>OWNERS</th>
                              <th>SALES</th>
                            </tr>
                          </thead>
                          <tbody>
                            {CollectionList &&
                              CollectionList.length > 0 &&
                              CollectionList.map((list, index) => {
                                return (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      <div className="flx">
                                        <img
                                          // src={require("../assets/images/prf.png")}
                                          src={`${config.baseUrl}/collections/${list?.bannerImage}`}
                                          alt="img"
                                          className="img-fluid user_mini"
                                        />
                                        <span>{list?.name}</span>
                                      </div>
                                    </td>
                                    <td>{list?.collectionQuery?.Totalvolume.toFixed(5)} MATIC</td>
                                    <td className="red">{((list?.onedayValues?.OneDayVolume / list?.collectionQuery?.Totalvolume) * 100) == Infinity || isNaN((list?.onedayValues?.OneDayVolume / list?.collectionQuery?.Totalvolume) * 100) ? '0' : ((list?.onedayValues?.OneDayVolume / list?.collectionQuery?.Totalvolume) * 100)}% </td>
                                    {/* <td className="red">-5.4%</td> */}
                                    <td>{list?.MinimumPrice?.min == null ? 0 : list?.MinimumPrice?.min}{" "}
                                      {config.Currency}</td>
                                    {/* <td className="red">-12.6%</td> */}
                                    <td> {list?.collectionQuery?.totalPrice?.length > 0
                                      ? list?.collectionQuery?.totalPrice.length
                                      : 0}</td>
                                    <td> {list?.collectionQuery?.totalusers.length > 0
                                      ? list?.collectionQuery.totalusers.length
                                      : 0}</td>
                                    <td>{list?.salecount ? list?.salecount : 0}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="four"
                    role="tabpanel"
                    aria-labelledby="four-tab"
                  >
                    <div className="h_option">
                      <div className="flx">
                        <div className="sel">
                          <select>
                            <option>All Categories</option>
                            <option>Trending</option>
                          </select>
                        </div>
                        <div className="icon">
                          <img
                            src={polygonicon}
                            alt="img"
                            className="img-fluid user"
                          />
                        </div>
                      </div>
                    </div>
                    <section className="recent_swap recent_swap_t pt-4 mt-3">
                      <div className="table-responsive">
                        <table className="table table-primary">
                          <thead>
                            <tr>
                              <th className="ps-5">#</th>
                              <th>PARTY A</th>
                              <th>ITEM A</th>
                              <th>STATUS</th>
                              <th>PARTY B</th>
                              <th>ITEM B</th>
                            </tr>
                          </thead>
                          <tbody>
                            {swapHis.length > 0 ? (
                              swapHis.map((swap, i) => {
                                return (
                                  <tr>
                                    <td className="ps-5">{i + 1}</td>
                                    <td>
                                      <div className="flx">
                                        <img
                                          src={
                                            swap?.formUser?.image
                                              ? config.baseUrl +
                                              "/usersProfile/" +
                                              swap?.formUser?.image
                                              : require("../assets/images/LogosSampleAvator.png")
                                          }
                                          alt="img"
                                          className="img-fluid user"
                                        />
                                        <span>
                                          {swap?.formUser?.username
                                            ? swap?.formUser?.username
                                            : halfAddrShow(swap?.fromUser)}
                                        </span>
                                      </div>
                                    </td>
                                    <td>{swap?.fromTokenName}</td>
                                    <td>
                                      <button
                                        className={`${swap?.status == 0
                                          ? `pending`
                                          : `completed`
                                          }`}
                                      >
                                        {swap?.status == 0
                                          ? "Pending"
                                          : "Completed"}
                                      </button>
                                    </td>

                                    <td>
                                      {swap?.status == 1 && (
                                        <div className="flx">
                                          <img
                                            src={
                                              swap?.TOUser?.[0]?.image
                                                ? config.baseUrl +
                                                "/usersProfile/" +
                                                swap?.TOUser?.[0]?.image
                                                : require("../assets/images/LogosSampleAvator.png")
                                            }
                                            alt="img"
                                            className="img-fluid user"
                                          />
                                          <span>
                                            {" "}
                                            {swap?.TOUser?.[0]?.username
                                              ? swap?.TOUser?.[0]?.username
                                              : halfAddrShow1(swap?.toUser)}
                                          </span>
                                        </div>
                                      )}
                                    </td>
                                    <td>
                                      {swap?.status == 1 && swap?.toTokenName}
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>No Datas</tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="five"
                    role="tabpanel"
                    aria-labelledby="five-tab"
                  >
                    <section className="popular_nft bg-transparent">
                      <div className="row flex-wrap">
                        {openSwap.length > 0 ? (
                          openSwap.map((nft) => {
                            return (
                              <div className="col-lg-3">
                                <div className="box">
                                  <div className="img_box">
                                    {nft.localImage.split(".").pop() ==
                                      "mp4" ? (
                                      <>
                                        {nft.isCollNft ? (
                                          <video
                                            src={`${config.baseUrl}/${nft.localImage}`}
                                            style={{ width: "100%" }}
                                            type="video/mp4"
                                            alt="Collections"
                                            className="lazy nft__item_preview nft__item_preview_video"
                                            controls
                                            controlsList="nodownload"
                                          />
                                        ) : (
                                          <video
                                            src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                            style={{ width: "100%" }}
                                            type="video/mp4"
                                            alt="Collections"
                                            className="lazy nft__item_preview nft__item_preview_video"
                                            controls
                                            controlsList="nodownload"
                                          />
                                        )}
                                      </>
                                    ) : nft.localImage.split(".").pop() ==
                                      "mp3" ? (
                                      <>
                                        {nft.isCollNft ? (
                                          <div style={{ textAlign: "center" }}>
                                            <img
                                              style={{
                                                padding: "16px",
                                                width: "256px",
                                                height: "256px"
                                              }}
                                              src={
                                                config.baseUrl +
                                                "/" +
                                                "images/music.png"
                                              }
                                              alt="img"
                                              className="img-fluid"
                                            />
                                            <audio
                                              src={`${config.baseUrl}/${nft.localImage}`}
                                              type="audio/mp3"
                                              controls
                                              controlsList="nodownload"
                                              className="audio home"
                                            ></audio>
                                          </div>
                                        ) : (
                                          <div style={{ textAlign: "center" }}>
                                            <img
                                              style={{
                                                padding: "16px",
                                                width: "256px",
                                                height: "256px"
                                              }}
                                              src={
                                                config.baseUrl +
                                                "/" +
                                                "images/music.png"
                                              }
                                              alt="img"
                                              className="img-fluid"
                                            />
                                            <audio
                                              src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                              type="audio/mp3"
                                              controls
                                              controlsList="nodownload"
                                              className="audio home"
                                            ></audio>
                                          </div>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {nft.isCollNft ? (
                                          <img
                                            src={`${config.baseUrl}/${nft.localImage}`}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        ) : (
                                          <img
                                            src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                            alt="img"
                                            className="img-fluid"
                                          />
                                        )}
                                      </>
                                    )}
                                  </div>
                                  <div className="blw_box">
                                    <h5>{nft.nftName}</h5>
                                    <div className="flx_ctn">
                                      <div className="mb-3">
                                        <span>Price</span>
                                        <h6>
                                          {nft.price} {config.Currency}
                                        </h6>
                                      </div>
                                      <div>
                                        {/* <span>Last Sale</span> */}
                                        {/* <p> {nft?.result
                                            ? nft?.result?.amount + " MATIC"
                                            : "Not yet"}{" "}</p> */}
                                      </div>
                                    </div>
                                    <Link
                                      to={`${config.frontUrl}/nft-detail/${nft.contractAddress}/${nft.nftId}`}
                                    >
                                      <button className="primary_btn w-100">
                                        <span>Swap</span>
                                        <img
                                          src={swap_btnarw}
                                          alt="img"
                                          className="img-fluid ms-3"
                                        />
                                      </button>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <>No Data</>
                        )}
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="six"
                    role="tabpanel"
                    aria-labelledby="six-tab"
                  >
                    <section className="activities">
                      {activity.length > 0 ? (
                        activity.map((history) => {
                          return (
                            <div className="outbox_flx">
                              <div className="box_flx">
                                <div className="img_box">
                                  {history.image.split(".").pop() == "mp4" ? (
                                    <video
                                      src={history.ipfsimage}
                                      style={{ width: "103px" }}
                                      type="video/mp4"
                                      alt="Collections"
                                      className="lazy nft__item_preview nft__item_preview_video"
                                      controls
                                      controlsList="nodownload"
                                    />
                                  ) : history.image.split(".").pop() ==
                                    "mp3" ? (
                                    <audio
                                      src={history.ipfsimage}
                                      style={{ width: "103px" }}
                                      type="audio/mp3"
                                      controls
                                      controlsList="nodownload"
                                      className="audio home"
                                    ></audio>
                                  ) : (
                                    <img
                                      src={history.ipfsimage}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  )}
                                </div>
                                <div>
                                  <p className="para mb-0">
                                    {history?.actiontype == 0
                                      ? "Mint NFT"
                                      : history?.actiontype == 1
                                        ? "Listing"
                                        : history?.actiontype == 2
                                          ? "Price Change"
                                          : history?.actiontype == 3
                                            ? "Place Bid"
                                            : history?.actiontype == 4
                                              ? "Edit Bid"
                                              : history?.actiontype == 5
                                                ? "Cancel Bid"
                                                : history?.actiontype == 6
                                                  ? "Transfer NFT"
                                                  : history?.actiontype == 7
                                                    ? "Burn NFT"
                                                    : history?.actiontype == 8
                                                      ? "Accept Bid"
                                                      : history?.actiontype == 9
                                                        ? "Purchase NFT"
                                                        : history?.actiontype == 10
                                                          ? "Cancel Sale"
                                                          : history?.actiontype == 11
                                                            ? "Expired Bid"
                                                            : history?.actiontype == 12
                                                              ? "Listing (Timed Auction)"
                                                              : history?.actiontype == 13
                                                                ? "Cancel (Timed Auction)"
                                                                : ""}
                                  </p>
                                  {history?.actiontype == 0 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT minted by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 1 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT listing{" "}
                                      {Numberconvert(history?.amount)}{" "}
                                      {config.Currency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 2 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT price changed{" "}
                                      {Numberconvert(history?.amount)}{" "}
                                      {config.Currency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 3 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT bid placed{" "}
                                      {Numberconvert(history?.amount)}{" "}
                                      {config.TokenCurrency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 4 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT bid edited{" "}
                                      {Numberconvert(history?.amount)}{" "}
                                      {config.TokenCurrency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 5 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT bid cancelled{" "}
                                      {Numberconvert(history?.amount)}{" "}
                                      {config.TokenCurrency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 6 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT transfer by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(
                                          history?.useraddress
                                        )} to{" "}
                                        {halfAddrShow(history?.touseraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 7 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT burned by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 8 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT bid accept{" "}
                                      {Numberconvert(history?.amount)}{" "}
                                      {config.TokenCurrency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 9 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT purchased{" "}
                                      {Numberconvert(history?.amount)}{" "}
                                      {config.Currency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 10 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT sale cancelled{" "}
                                      {Numberconvert(history?.amount)}{" "}
                                      {config.Currency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 11 ? (
                                    <span className="d-block mb-3 mt-1">
                                      Your {history?.nftName} NFT bid expired{" "}
                                      {Numberconvert(history?.amount)}{" "}
                                      {config.TokenCurrency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 12 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT listing (Timed
                                      Auction) {Numberconvert(history?.amount)}{" "}
                                      {config.TokenCurrency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : history?.actiontype == 13 ? (
                                    <span className="d-block mb-3 mt-1">
                                      1 {history?.nftName} NFT cancel Timed
                                      Auction {Numberconvert(history?.amount)}{" "}
                                      {config.TokenCurrency} by{" "}
                                      <span className="name">
                                        {" "}
                                        {halfAddrShow(history?.useraddress)}
                                      </span>
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                  {/* <span className="d-block mb-3 ">
                              1 {history?.nftName} minted by{" "}
                              <span className="name">
                                {" "}
                                {halfAddrShow(history?.useraddress)}
                              </span>
                            </span> */}
                                  <span>
                                    {moment(history.createdAt).format("lll")}
                                  </span>
                                </div>
                              </div>
                              {history?.trasHash ? (
                                <Link
                                  to={config.Scan + "/" + history?.trasHash}
                                  target="_blank"
                                >
                                  <img
                                    src={activities_arw}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>
                          );
                        })
                      ) : (
                        <div>No Activities yet</div>
                      )}
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade modal-md placebid_modal"
            id="Reportconfirm"
            tabIndex={-1}
            aria-labelledby="swapconfirmLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <h3>Report Page Model</h3>
                <div className="modal-body tabmodal">
                  <Formik
                    initialValues={{
                      reporttitle: "",
                      reportcontent: ""
                    }}
                    validationSchema={ReportPageValidate}
                    onSubmit={async ({ reporttitle, reportcontent }) => {
                      console.log(reporttitle, reportcontent, "Formik");
                      setdisabledbtn(true)
                      const payload = {
                        ReportUserAddress: walletConnection.address,
                        ReportSpanAddress: address,
                        reporttitle: reporttitle,
                        reportcontent: reportcontent,
                        currentuser: localStorage.getItem("nicholousWallAddr"),
                        reporttype: "User"
                      };
                      console.log(payload);
                      const result = await SetReportpage(payload);
                      if (result.status) {
                        toastAlert("success", result.message);
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      }
                    }}
                  >
                    {({ errors, touched,resetForm }) => (
                      <Form>
                        <div className="row mb-5">
                          <div className="col-md-12 mx-auto">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Title
                            </label>
                            <Field
                              name="reporttitle"
                              type="text"
                              placeholder="Report title"
                              style={{ width: "100%", padding: "10px" }}
                            />
                            <br />
                            {errors.reporttitle && touched.reporttitle ? (
                              <span className="error-msg">
                                {errors.reporttitle}
                              </span>
                            ) : null}
                          </div>
                          <div
                            className="col-md-12 mx-auto"
                            style={{ marginTop: "10px" }}
                          >
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Content
                            </label>
                            <Field
                              name="reportcontent"
                              type="text"
                              component="textarea"
                              rows="10"
                              style={{ width: "100%" }}
                            />
                            <br />
                            {errors.reportcontent && touched.reportcontent ? (
                              <span className="error-msg">
                                {errors.reportcontent}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="primary_btn dark w-100 mb-3 mt-2"
                          disabled={disabledbtn}
                        >
                          Submit
                        </button>
                  <button
                    type="reset"
                    className="primary_btn dark w-100 mb-3 mt-2"
                    // disabled={disabledbtn}
                    onClick={resetForm}
                    data-bs-toggle="modal"
                    data-bs-target="#Reportconfirm"
                    >
                    Cancel
                    </button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <h3 style={{ textAlign: "center" }}>Please connect wallet</h3>
      )}

      <Footer />
    </div>
  );
}
