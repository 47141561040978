let EnvName = "demo";

let NetworkId = "";
let Currency = "";
let TokenCurrency = "";
let baseUrl = "";
let frontUrl = "";
let CollContract = "";
let SingleNFT = "";
let tokenContract = "";
let swapContract = "";
let networkVersion = "";
let decimalvalues = "";
let IPFS_IMG = "https://ipfs.io/ipfs";
let rpcUrl = "";
let scan = "";

if (EnvName === "demo") {
  NetworkId = 80001;
  Currency = "MATIC";
  TokenCurrency = "WMATIC";
  baseUrl = "https://nicholasnftapi.wearedev.team";
  frontUrl = "https://nicholasnft-frontend.pages.dev";
  CollContract = "0x6d2dfeb2d86222fed04e69c366d40dfd6fcdb738"; //  0x5e2bd403c5348bed2a89e96e3884300d8868ae38
  SingleNFT = "0xA6bBedE9E3cC52b23eAd175F56Af528794cF3905"; //0x3dde273bd86e5dd34eb8dd31308f335705a0c185
  tokenContract = "0xda25017e14fb85301005612fd5fef77bd5ea4cd0";

  swapContract = "0x3e956175f93243da4bc8b8bdd99ce1a9dce48c9b";
  networkVersion = 80001;
  decimalvalues = 1e18;
  rpcUrl = "https://rpc-mumbai.maticvigil.com";
  scan = "https://mumbai.polygonscan.com/tx";
} else {
  NetworkId = 80001;
  Currency = "MATIC";
  TokenCurrency = "WMATIC";
  baseUrl = "http://localhost:3040";
  frontUrl = "http://localhost:3000";
  CollContract = "0x6d2dfeb2d86222fed04e69c366d40dfd6fcdb738"; // 0x2284d3c17255c906fa214ce149891b616a6c3019
  SingleNFT = "0xA6bBedE9E3cC52b23eAd175F56Af528794cF3905";
  tokenContract = "0xda25017e14fb85301005612fd5fef77bd5ea4cd0";

  swapContract = "0x3e956175f93243da4bc8b8bdd99ce1a9dce48c9b";
  networkVersion = 80001;
  decimalvalues = 1e18;
  rpcUrl = "https://rpc-mumbai.maticvigil.com";
  scan = "https://mumbai.polygonscan.com/tx";
}

let key = {
  NetworkId: NetworkId,
  Currency: Currency,
  TokenCurrency: TokenCurrency,
  baseUrl: baseUrl,
  CollContract: CollContract,
  SingleNFT: SingleNFT,
  tokenContract: tokenContract,
  networkVersion: networkVersion,
  decimalValues: decimalvalues,
  frontUrl: frontUrl,
  IPFS_IMG: IPFS_IMG,
  rpcUrl: rpcUrl,
  swapContract: swapContract,
  Scan: scan
};

export default key;
