import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import verified from "../assets/images/verified.svg";
import plus from "../assets/images/plus.svg";
import polygonicon from "../assets/images/polygon_icon.svg";
import search from "../assets/images/search.svg";
import config from "../lib/config";
import {
  get_allNfts,
  get_allColletions,
  get_allUsers,
  getCategory
} from "../actions/nfts";
import { FollowCallAction, User_Follow_Get_Action } from "../actions/users";
import { useSelector } from "react-redux";
import { halfAddrShow } from "../helper/halfAddr.js";
import Web3 from "web3";
import moment from "moment";
import Countdown from "react-countdown";
import { Link } from "react-router-dom";
import { Numberconvert } from "../helper/numberConvert.js";
// Scroll to Top

export default function Explore(props) {
  const ref = useRef(null);
  const walletConnection = useSelector((state) => state.walletConnection);
  const [collectionList, setCollectionList] = useState([]);
  const [NFTList, setNFTList] = useState([]);
  const [UsersList, setUsersList] = useState([]);
  const [isFollow, SetIsFollow] = useState(false);
  const [FollowingCount, setFollowingCount] = useState(0);
  const [FollowerCount, setFollowerCount] = useState(0);
  const [flwIndex, setFlwIndex] = useState();

  const [CategoryList, SetCategoryList] = useState();
  const [Categorytype, SetCategorytype] = useState("all");
  const [SerachNft, SetSerachNft] = useState("");
  const [SerachUser, SetSerachUser] = useState("");
  const [timeOption, SettimeOption] = useState(".");
  const [collTimeOption, setCollTImeOption] = useState(".");
  const [SeacrchColl, setSearchColl] = useState("");

  const [status, setStatus] = useState(false);
  console.log(walletConnection, "walletConnection");

  const renderer1 = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s to start
        </span>
      );
    }
  };
  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <span>Ended</span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    getAllUsers();
    getAllNfts();
    getAllCollection();
    Get_Category_List();
  }, [
    walletConnection.address,
    Categorytype,
    SerachNft,
    timeOption,
    SerachUser,
    collTimeOption,
    SeacrchColl,
    //  UsersList
    status
  ]);

  const getAllUsers = async () => {
    const users = await get_allUsers();
    let filData = users?.data?.data.filter(
      (ele) => ele.curraddress !== walletConnection.address
    );
    // setUsersList(filData);
    console.log(filData, "filDatafilData");
    const filteredItems = filData.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(SerachUser.toLowerCase()) !==
        -1
    );

    setUsersList(filteredItems);
  };

  const getAllNfts = async () => {
    const nfts = await get_allNfts({
      categoryType: Categorytype,
      SerachNft: SerachNft,
      timeOption: timeOption
    });
    console.log(nfts, "nfts");
    setNFTList(nfts?.data?.data);
  };
  const getAllCollection = async () => {
    const payload = {
      categoryType: Categorytype,
      collTime: collTimeOption,
      search: SeacrchColl
    };
    console.log(payload, "COllectionPayload");
    const coll = await get_allColletions(payload);
    console.log(coll, "PayloadCollection");
    coll?.data?.collection.map((ele, i) => {
      ele.SNo = i + 1;
    });
    setCollectionList(coll?.data?.collection);
  };

  ///get Category
  const Get_Category_List = async () => {
    const category = await getCategory();
    console.log(category.data.data, "category");
    SetCategoryList(category.data.data);
  };

  //   const Check_follow = async () => {
  //    if (walletConnection.address) {
  //      const payload = {
  //        userAddr: walletConnection.address,
  //        followaddress: getUsers?.curraddress
  //      };

  //      const follow = await User_Follow_Get_Action(payload);
  //      console.log(follow, "count");
  //      SetIsFollow(follow?.data?.isFollow);
  //      setFollowingCount(follow?.data?.following);
  //      setFollowerCount(follow?.data?.followers);
  //    }
  //  };

  const FollowAction_Call = async (address, index) => {
    if (walletConnection.address) {
      setFlwIndex(index);
      // const web3 = new Web3(walletConnection.provider);
      // console.log(web3, "web3web3web3");
      // let msg = "I would like to follow user: " + address;
      // if (isFollow == "yes") {
      //   msg = "I would like to stop follow user: " + address;
      // }
      // await web3.eth.sign(msg, walletConnection.address);
      setStatus(false);

      console.log(
        walletConnection.address,
        address,
        "adddddddddddddddd",
        index
      );
      const payload = {
        currAddr: walletConnection.address,
        userParamAddr: address
      };

      const follow = await FollowCallAction(payload);
      // console.log(follow?.data?.user, "changeType");
      // let filData = follow?.data?.user.filter(
      //   (ele) => ele.curraddress !== walletConnection.address
      // );
      // // setUsersList(filData);
      // console.log(filData, "filDatafilData");
      // const filteredItems = filData.filter(
      //   (item) =>
      //     JSON.stringify(item).toLowerCase().indexOf(SerachUser.toLowerCase()) !==
      //     -1
      // );

      // setUsersList(filteredItems);
      SetIsFollow(follow?.data, "changetype");
      setStatus(true);
      Check_follow(address);

      // window.location.reload();
    }
  };

  const Check_follow = async (address) => {
    if (walletConnection.address) {
      const payload = {
        userAddr: walletConnection.address,
        followaddress: address
      };

      const follow = await User_Follow_Get_Action(payload);
      console.log(follow, "Check_followcount");
      SetIsFollow(follow?.data?.isFollow);
      setFollowingCount(follow?.data?.following);
      setFollowerCount(follow?.data?.followers);
    }
  };

  function findObjectWithLowestValue(objects) {
    const data = objects.filter((ele) => ele.price > 0);
    const mm = data.map((ele) => ele.price);
    console.log(mm.sort(), "datadatadatadata");
    return mm[0];
  }

  function sumOfCollVolumn(objects) {
    const data = objects.filter((ele) => ele.price > 0);
    const mm = data.map((ele) => ele.price);
    const sum = mm.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return sum.toFixed(3);
  }

  const changeCatagory = async (e) => {
    SetCategoryList(e.target.value);
  };

  console.log(collectionList, NFTList, UsersList, "fefeefef");

  return (
    <div>
      <Navbar />
      <div className="page_box">
        <div className="explore">
          <div className="container">
            <h2 className="h2tag">Explore collections of rarities</h2>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active ps-0"
                  id="one-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#one"
                  type="button"
                  role="tab"
                  aria-controls="one"
                  aria-selected="true"
                  onClick={() => SetCategorytype("all")}
                >
                  Collections
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="two-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#two"
                  type="button"
                  role="tab"
                  aria-controls="two"
                  aria-selected="false"
                  onClick={() => SetCategorytype("all")}
                >
                  NFTs
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="three-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#three"
                  type="button"
                  role="tab"
                  aria-controls="three"
                  aria-selected="false"
                >
                  Users
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="one"
                role="tabpanel"
                aria-labelledby="one-tab"
              >
                <div className="h_option">
                  <div className="flx">
                    <div className="sel">
                      <select
                        value={Categorytype}
                        onChange={(e) => SetCategorytype(e.target.value)}
                      >
                        <option value={"all"}>All Categories</option>
                        {CategoryList &&
                          CategoryList?.length > 0 &&
                          CategoryList?.map((item) => {
                            return (
                              <option value={item.Category}>
                                {item.Category}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="icon">
                      <img
                        src={polygonicon}
                        alt="img"
                        className="img-fluid user"
                      />
                    </div>
                  </div>
                  <div className="input-group sr_ch mob_hide">
                    <span className="input-group-text">
                      <img src={search} alt="img" className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGroup1"
                      placeholder="Search  collection"
                      onChange={(e) => setSearchColl(e.target.value)}
                    />
                  </div>
                  <ul className="mob_hide">
                    <li
                      className={collTimeOption == "." ? "active" : ""}
                      onClick={() => setCollTImeOption(".")}
                    >
                      All
                    </li>

                    <li
                      className={collTimeOption == 5 ? "active" : ""}
                      onClick={() => setCollTImeOption(5)}
                    >
                      90d
                    </li>
                    <li
                      className={collTimeOption == 4 ? "active" : ""}
                      onClick={() => setCollTImeOption(4)}
                    >
                      30d
                    </li>
                    <li
                      className={collTimeOption == 3 ? "active" : ""}
                      onClick={() => setCollTImeOption(3)}
                    >
                      7d
                    </li>
                    <li
                      className={collTimeOption == 2 ? "active" : ""}
                      onClick={() => setCollTImeOption(2)}
                    >
                      24h
                    </li>
                    <li
                      className={collTimeOption == 1 ? "active" : ""}
                      onClick={() => setCollTImeOption(1)}
                    >
                      1h
                    </li>
                  </ul>
                </div>
                <section className="recent_swap">
                  <div class="table-responsive">
                    <table class="table table-primary">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>COLLECTION</th>
                          <th>VOLUME</th>
                          <th>% 24CHANGE</th>
                          <th>FLOOR PRICE</th>
                          {/* <th>% CHANGE</th> */}
                          <th>ITEMS</th>
                          <th>OWNERS</th>
                          <th>SALES</th>
                        </tr>
                      </thead>
                      <tbody>
                        {collectionList &&
                          collectionList.length > 0 &&
                          collectionList.map((coll) => {
                            return (
                              <tr>
                                <td>{coll?.SNo}</td>
                                <td>
                                  <div className="flx">
                                    <img
                                      src={
                                        config.baseUrl +
                                        "/collections/" +
                                        coll?.bannerImage
                                      }
                                      alt="img"
                                      className="img-fluid user_mini"
                                    />
                                    <span>{coll?.name}</span>
                                  </div>
                                </td>
                                <td>
                                  {coll?.collectionQuery?.Totalvolume?.toFixed(
                                    4
                                  )}{" "}
                                  &nbsp;{config.Currency}
                                </td>
                                {/* {console.log(((coll?.volume/coll?.totalvolume)*100)==Infinity)} */}
                                <td className="red">
                                  {(coll?.onedayValues?.OneDayVolume /
                                    coll?.collectionQuery?.Totalvolume) *
                                    100 ==
                                    Infinity ||
                                  isNaN(
                                    (coll?.onedayValues?.OneDayVolume /
                                      coll?.collectionQuery?.Totalvolume) *
                                      100
                                  )
                                    ? 0
                                    : (coll?.onedayValues?.OneDayVolume /
                                        coll?.collectionQuery?.Totalvolume) *
                                      100}{" "}
                                  %
                                </td>
                                {/* <td className="red">-5.4%</td> */}
                                <td>
                                  {coll?.MinimumPrice?.min == null
                                    ? 0
                                    : coll?.MinimumPrice?.min}{" "}
                                  &nbsp; 
                                  {config.Currency}
                                </td>
                                {/* <td className="red">-12.6%</td> */}
                                <td>
                                  {coll?.collectionQuery?.totalPrice?.length > 0
                                    ? coll?.collectionQuery?.totalPrice.length
                                    : 0}
                                </td>
                                <td>
                                  {coll?.collectionQuery?.totalusers.length > 0
                                    ? coll?.collectionQuery.totalusers.length
                                    : 0}
                                </td>
                                <td>
                                  {" "}
                                  {coll?.salecount ? coll?.salecount : 0}
                                </td>
                              </tr>
                            );
                          })}

                        {/* <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>
                            <div className="flx">
                              <img
                                src={require("../assets/images/prf.png")}
                                alt="img"
                                className="img-fluid user_mini"
                              />
                              <span>Gorilla DJ Party</span>
                            </div>
                          </td>
                          <td>4,239 MATIC</td>
                          <td className="red">-5.4%</td>
                          <td>7.33 MATIC</td>
                          <td className="red">-12.6%</td>
                          <td>10K</td>
                          <td>4.6K</td>
                          <td>31</td>
                        </tr> */}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
              <div
                className="tab-pane fade"
                id="two"
                role="tabpanel"
                aria-labelledby="two-tab"
              >
                <div className="h_option">
                  <div className="flx">
                    <div className="sel">
                      <select
                        value={Categorytype}
                        onChange={(e) => SetCategorytype(e.target.value)}
                      >
                        <option value={"all"}>All Categories</option>
                        {CategoryList?.length > 0 &&
                          CategoryList.map((item) => {
                            return (
                              <option value={item.Category}>
                                {item.Category}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div className="icon">
                      <img
                        src={polygonicon}
                        alt="img"
                        className="img-fluid user"
                      />
                    </div>
                  </div>
                  <div className="input-group sr_ch mob_hide">
                    <span className="input-group-text">
                      <img src={search} alt="img" className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGroup1"
                      placeholder="Search  nft"
                      onChange={(e) => SetSerachNft(e.target.value)}
                    />
                  </div>

                  <ul className="mob_hide">
                    <li
                      className={timeOption == "." ? "active" : ""}
                      onClick={() => SettimeOption(".")}
                    >
                      All
                    </li>
                    <li
                      className={timeOption == 1 ? "active" : ""}
                      onClick={() => SettimeOption(1)}
                    >
                      90d
                    </li>
                    <li
                      className={timeOption == 2 ? "active" : ""}
                      onClick={() => SettimeOption(2)}
                    >
                      30d
                    </li>
                    <li
                      className={timeOption == 3 ? "active" : ""}
                      onClick={() => SettimeOption(3)}
                    >
                      7d
                    </li>
                    <li
                      className={timeOption == 4 ? "active" : ""}
                      onClick={() => SettimeOption(4)}
                    >
                      24h
                    </li>
                    <li
                      className={timeOption == 5 ? "active" : ""}
                      onClick={() => SettimeOption(5)}
                    >
                      1h
                    </li>
                  </ul>
                </div>
                <section className="popular_nft bg-transparent">
                  <div className="row flex-wrap">
                    {NFTList.map((nft) => {
                      let currDate = new Date();
                      let startdate = new Date(nft.startTime);
                      let enddate = new Date(nft.endTime);
                      let auction = "false";
                      let finish = "";
                      let enddate1 = "";
                      let showlist = "true";
                      let display_item = 0;
                      if (nft.PutOnSaleType == "TimedAuction") {
                        auction = "true";
                        let a = moment(nft.startTime);
                        let b = moment(nft.endTime);
                        let c = moment();
                        a.diff(b); // 86400000
                        let diffInMs = a.diff(c);
                        display_item = a.diff(c);
                        finish = b.diff(c);
                        enddate1 = parseFloat(diffInMs);
                        if (finish > 0) {
                          showlist = "true";
                        } else {
                          var auctionTxt = "Ended";
                          showlist = "false";
                        }
                      }
                      return (
                        <>
                          <div className="col-lg-3">
                            <Link
                              to={`/nft-detail/${nft.contractAddress}/${nft.nftId}`}
                            >
                              <div className="box">
                                {nft.PutOnSaleType == "TimedAuction" &&
                                  // showlist == "true" &&
                                  display_item <= 0 && (
                                    <div className="de_countdown">
                                      <Countdown
                                        date={enddate}
                                        autoStart={true}
                                        onStart={() => startdate}
                                        renderer={renderer}
                                      />
                                    </div>
                                  )}
                                {nft.PutOnSaleType == "TimedAuction" &&
                                  // showlist == "true" &&
                                  display_item > 0 && (
                                    <div className="de_countdown">
                                      <Countdown
                                        date={startdate}
                                        autoStart={true}
                                        onStart={() => Date.now()}
                                        renderer={renderer1}
                                      />
                                    </div>
                                  )}
                                <div className="img_box">
                                  {/* <img
                                  src={nft.isCollNft ? `${config.baseUrl}/tokens/${nft.localImage}`}
                                  alt="img"
                                  className="img-fluid"
                                /> */}
                                  {nft.localImage.split(".").pop() == "mp4" ? (
                                    <>
                                      {nft.isCollNft ? (
                                        <video
                                          src={`${config.baseUrl}/${nft.localImage}`}
                                          style={{ width: "100%" }}
                                          type="video/mp4"
                                          alt="Collections"
                                          className="lazy nft__item_preview nft__item_preview_video"
                                          controls
                                          controlsList="nodownload"
                                        />
                                      ) : (
                                        <video
                                          src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                          style={{ width: "100%" }}
                                          type="video/mp4"
                                          alt="Collections"
                                          className={
                                            nft.sensitiveContent
                                              ? "lazy nft__item_preview nft__item_preview_video blue_img"
                                              : "lazy nft__item_preview nft__item_preview_video"
                                          }
                                          // className="lazy nft__item_preview nft__item_preview_video"
                                          controls
                                          controlsList="nodownload"
                                        />
                                      )}
                                    </>
                                  ) : nft.localImage.split(".").pop() ==
                                    "mp3" ? (
                                    <>
                                      {nft.isCollNft ? (
                                        <div style={{ textAlign: "center" }}>
                                          <img
                                            style={{
                                              padding: "16px",
                                              width: "256px",
                                              height: "256px"
                                            }}
                                            src={
                                              config.baseUrl +
                                              "/" +
                                              "images/music.png"
                                            }
                                            alt="img"
                                            className={
                                              nft.sensitiveContent
                                                ? "img-fluid blue_img"
                                                : "img-fluid"
                                            }
                                            // className="img-fluid"
                                          />
                                          <audio
                                            src={`${config.baseUrl}/${nft.localImage}`}
                                            type="audio/mp3"
                                            controls
                                            controlsList="nodownload"
                                            className="audio home"
                                          ></audio>
                                        </div>
                                      ) : (
                                        <div style={{ textAlign: "center" }}>
                                          <img
                                            style={{
                                              padding: "16px",
                                              width: "256px",
                                              height: "256px"
                                            }}
                                            src={
                                              config.baseUrl +
                                              "/" +
                                              "images/music.png"
                                            }
                                            alt="img"
                                            className={
                                              nft.sensitiveContent
                                                ? "img-fluid blue_img"
                                                : "img-fluid"
                                            }
                                            // className="img-fluid"
                                          />
                                          <audio
                                            src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                            type="audio/mp3"
                                            controls
                                            controlsList="nodownload"
                                            className="audio home"
                                          ></audio>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {nft.isCollNft ? (
                                        <img
                                          src={`${config.baseUrl}/${nft.localImage}`}
                                          alt="img"
                                          // className={nft.sensitiveContent?"img-fluid blue_img":"img-fluid"}
                                          className="img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                          alt="img"
                                          className={
                                            nft.sensitiveContent
                                              ? "img-fluid blue_img"
                                              : "img-fluid"
                                          }
                                          // className="img-fluid"
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="blw_box">
                                  <h5>{nft?.nftName}</h5>
                                  <div className="flx_ctn">
                                    <div className="mb-3">
                                      <span>Price</span>
                                      <h6>
                                        {Numberconvert(nft.price)}{" "}
                                        {config.Currency}
                                      </h6>
                                    </div>
                                    <div>
                                      <span>Last Sale</span>
                                      <p>
                                        {nft.result ? (
                                          <>
                                            {nft.result.amount}
                                            {config.Currency}
                                          </>
                                        ) : (
                                          "Not yet"
                                        )}
                                      </p>
                                      {/* <p>4.56 {config.Currency}</p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      );
                    })}

                    {/* <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/p1.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_box">
                          <h5>#800</h5>
                          <div className="flx_ctn">
                            <div className="mb-3">
                              <span>Price</span>
                              <h6>15.97 MATIC</h6>
                            </div>
                            <div>
                              <span>Last Sale</span>
                              <p>4.56 MATIC</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </section>
              </div>
              <div
                className="tab-pane fade"
                id="three"
                role="tabpanel"
                aria-labelledby="three-tab"
              >
                <div className="h_option">
                  {/* <div className="flx me-3">
                    <div className="sel">
                      <select>
                        <option>All Categories</option>
                        <option>Trending</option>
                      </select>
                    </div>
                  </div> */}
                  <div className="input-group sr_ch mx-0 mob_hide">
                    <span className="input-group-text">
                      <img src={search} alt="img" className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGroup1"
                      placeholder="Trending creators"
                      onChange={(e) => SetSerachUser(e.target.value)}
                    />
                  </div>
                </div>
                <section className="popular_nft popular_nftuser bg-transparent">
                  <div className="row flex-wrap">
                    {UsersList.length > 0 &&
                      UsersList.map((user, i) => {
                        return (
                          <>
                            {console.log(user, "efefef")}
                            <div className="col-lg-3" key={i}>
                              <div className="box">
                                <Link to={`/user/${user.curraddress}`}>
                                  <div className="img_box">
                                    <img
                                      src={
                                        user.coverimage
                                          ? config.baseUrl +
                                            "/usersProfile/" +
                                            user.coverimage
                                          : require("../assets/images/prf_ban.png")
                                      }
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="blw_ctn">
                                    <img
                                      src={
                                        user.image
                                          ? config.baseUrl +
                                            "/usersProfile/" +
                                            user.image
                                          : require("../assets/images/LogosSampleAvator.png")
                                      }
                                      style={{
                                        boxShadow:
                                          "rgb(153 153 153 / 25%) 0px 0px 10px 0px"
                                      }}
                                      alt="img"
                                      className="img-fluid prf"
                                    />
                                    <h6>
                                      {user.name
                                        ? user.name
                                        : halfAddrShow(user.curraddress)}
                                      {/* <img
                                      src={verified}
                                      alt="img"
                                      className="ms-2 mb-2  img-fluid"
                                    /> */}
                                    </h6>
                                    <p>
                                      {" "}
                                      <span>{user?.follow.length} </span>{" "}
                                      <span> Followers</span>
                                    </p>
                                  </div>
                                </Link>
                                <div
                                  className="blw_ctnbox"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    FollowAction_Call(user?.curraddress, i)
                                  }
                                >
                                  {console.log(
                                    user?.follow.filter(
                                      (e) =>
                                        e.follower == walletConnection.address
                                    ).length > 0
                                      ? `Unfollow,${i}`
                                      : `Follow ,${i}`,
                                    "____________rrr"
                                  )}
                                  <span>
                                    {user?.follow.filter(
                                      (e) =>
                                        e.follower == walletConnection.address
                                    ).length > 0
                                      ? "Unfollow"
                                      : "Follow"}
                                  </span>
                                  <img
                                    src={plus}
                                    alt="img"
                                    className="img-fluid ms-2"
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}

                    {/* <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/userprf_bg.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="blw_ctn">
                          <img
                            src={require("../assets/images/prf.png")}
                            alt="img"
                            className="img-fluid prf"
                          />
                          <h6>
                            Azuki
                            <img
                              src={verified}
                              alt="img"
                              className="ms-2 mb-2  img-fluid"
                            />
                          </h6>
                          <p>
                            {" "}
                            <span>20.4K </span>. <span> Followers</span>
                          </p>
                        </div>
                        <div className="blw_ctnbox">
                          <span>Follow</span>
                          <img
                            src={plus}
                            alt="img"
                            className="img-fluid ms-2"
                          />
                        </div>
                      </div>
                    </div> */}
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
