import React, { useState, useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import plus from "../assets/images/plus.svg";
import polygonicon from "../assets/images/polygon_icon.svg";
import copyIcon from "../assets/images/copy.svg";
import option from "../assets/images/option.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import twitter from "../assets/images/twitter.svg";
import instagram from "../assets/images/instagram.svg";
import upload from "../assets/images/upload.svg";
import swap_btnarw from "../assets/images/swap_btnarw.svg";
import activities_user from "../assets/images/activities_user.svg";
import activities_arw from "../assets/images/activities_arw.svg";
import msg from "../assets/images/msg.svg";
import copy from "copy-to-clipboard";
import config from "../lib/config";
import { useSelector } from "react-redux";
import { halfAddrShow } from "../helper/halfAddr.js";
import { getCategory, getOwnNFT, getOpenSwapNfts } from "../actions/nfts";
import moment from "moment";
import Countdown from "react-countdown";
import axios from "axios";
import { User_Follow_Get_Action, checkUserName, getCollection } from "../actions/users";
import { toastAlert } from "../lib/toastAlert.js";

export default function UserProfile(props) {
    const params=useParams()
    console.log(params,'paramsparamsparamsparams');
  const [copyTxt, setCopyTxt] = useState(false);
  const navigate = useNavigate();
  const walletConnection = useSelector((state) => state.walletConnection);
  const getUsers = useSelector((state) => state.getUsers);
  const [myNft, setMyNft] = useState([]);
  const [ownNft, setOwnNft] = useState([]);
  const [isFollow, SetIsFollow] = useState(false);
  const [FollowingCount, setFollowingCount] = useState(0);
  const [FollowerCount, setFollowerCount] = useState(0);
  const [filterCata, setFiltercate] = useState('all');
  const [Category, setCatagory] = useState([]);
  const [CollectionList, setCollectionList] = useState([]);
  const [newMsg, setNewMsg] = useState([]);
  console.log(getUsers, "getUsers");

  useEffect(()=>{
    checkUsername()
  },[walletConnection.address])


  const checkUsername=async()=>{
    console.log(walletConnection,params.username,'usernameusernameusernameusername',getUsers.curraddress);

      const payload={
        address:localStorage.getItem("nicholousWallAddr"),
        username:params.username
      }
      const result= await checkUserName(payload)
      console.log(result, 'rrrrrrrrrrrrrrrrrrrrr');
      if(result){

        if(!result?.data?.status){
          toastAlert('error',result.data.message)
          navigate('/')
        }
      }
 
  }

  const renderer1 = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <span></span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s to start
        </span>
      );
    }
  };
  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <span>Ended</span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    }
  };

  useEffect(() => {
    getAllMessages();
  }, [walletConnection.address]);

  const getAllMessages = async () => {
    const recieveMessageRoute = `${config.baseUrl}/api/routesUser/getAllMsg`;
    console.log(getUsers, "getUsers");
    const response = await axios.post(recieveMessageRoute, {
      from: "",
      to: walletConnection.address
    });
    console.log(response.data, "GetMessss");
    // setMessages(response?.data);
    setNewMsg(response?.data?.messagesNoti);
  };

  useEffect(() => {
    getOwnNft();
    Check_follow();
    getAllCatagory();
    getAllCollection();
    openSwapNfts();
  }, [getUsers, filterCata, walletConnection.address]);

  const getOwnNft = async () => {
    const payload = {
      address: localStorage.getItem('nicholousWallAddr')
    };
    console.log(payload, "payload    ");
    const myData = await getOwnNFT(payload,filterCata);
    console.log(myData, "myData");
    setMyNft(myData?.data?.dataC);
    setOwnNft(myData?.data?.dataO);
  };
  const Check_follow = async () => {
    if (walletConnection.address) {
      const payload = {
        userAddr: walletConnection.address,
        followaddress: getUsers?.curraddress
      };

      const follow = await User_Follow_Get_Action(payload);
      console.log(follow, "count");
      SetIsFollow(follow?.data?.isFollow);
      setFollowingCount(follow?.data?.following);
      setFollowerCount(follow?.data?.followers);
    }
  };

  const getAllCollection = async () => {
    if (walletConnection.address) {
      const payload = {
        user: getUsers?.curraddress
      };
      const result = await getCollection(payload, filterCata);
      console.log(result, "collections");
      setCollectionList(result.data.data1);
    }
  };

  const getAllCatagory = async () => {
    if (walletConnection.address) {
      console.log("log");
      const result = await getCategory();
      console.log(result, "resultresulty");
      setCatagory(result?.data?.data);
    }
  };

  const openSwapNfts = async () => {
    if (walletConnection.address) {
      const payload = {
        address: getUsers?.curraddress,
        
      };
      const data = await getOpenSwapNfts(payload);
    }
  };

  const catagorychange = (e) => {
    console.log(e.target.value, "catagorychange");
    setFiltercate(e.target.value);
  };
  console.log(localStorage.getItem("nicholousWallAddr"),walletConnection.address,'aaaaaaaaaaaaaaaaaaaa');
  
    return (
        <div>
          <Navbar />
          {
            localStorage.getItem("nicholousWallAddr")?<div className="page_box prf_page">
            <div className="prf_ban">
              <img
                src={
                  getUsers.coverimage
                    ? config.baseUrl + "/usersProfile/" + getUsers.coverimage
                    : require("../assets/images/prf_ban.png")
                }
                alt="img"
                className="img-fluid prf_banimg"
              />
              <div className="container">
                <div className="prf_detail">
                  <div className="prf_ban_user">
                    <img
                      src={
                        getUsers.image
                          ? config.baseUrl + "/usersProfile/" + getUsers.image
                          : require("../assets/images/LogosSampleAvator.png")
                      }
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
    
                  <div className="row">
                    <div className="col-xl-9">
                      <h2 className="h2tag">
                        {getUsers.name
                          ? getUsers.name
                          : halfAddrShow(getUsers.curraddress)}
                      </h2>
                      <div className="btn_flx">
                        {/* <button className="primary_btn">
                          <span>Follow</span>
                          <img src={plus} alt="img" className="img-fluid ms-3" />
                        </button> */}
                        <button
                          type="button"
                          style={{ marginRight: "40px" }}
                          class="primary_btn position-relative"
                          onClick={() => navigate("/chat")}
                        >
                          Messages
                          {newMsg.length > 0 && (
                            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                              {newMsg.length}
                              <span class="visually-hidden">unread messages</span>
                            </span>
                          )}
                        </button>
                        <div className="d-flex align-items-center">
                          <button className="primary_btn addr mb-0">
                            <span>{halfAddrShow(getUsers.curraddress)}</span>
                            <img
                              src={copyIcon}
                              alt="img"
                              className="img-fluid ms-3"
                              onClick={() => {
                                copy(localStorage.getItem("nicholousWallAddr"));
                                setCopyTxt(true);
                                setTimeout(function () {
                                  setCopyTxt(false);
                                }, 800);
                              }}
                            />
                            {copyTxt && (
                              <span
                                style={{
                                  fontSize: "12px",
                                  color: "green",
                                  marginLeft: "2px"
                                }}
                              >
                                Copied
                              </span>
                            )}
                          </button>
                          <ul className="social mb-0 social_mob mb-0">
                            <li>
                              <a href="#" target="_blank">
                                <img src={msg} alt="img" className="img-fluid" />
                              </a>
                            </li>
                          </ul>
                          <div class="dropdown mb-0">
                            {/* <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img src={option} alt="img" className="img-fluid" />
                            </button> */}
                            {/* <ul
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li>
                                <a class="dropdown-item" href="#">
                                  Report page
                                </a>
                              </li>
                            </ul> */}
                          </div>
                        </div>
                      </div>
                      <p className="para pb-3">{getUsers.bio}</p>
                      <ul className="social">
                        <li>
                          <a href="#" target="_blank">
                            <img src={twitter} alt="img" className="img-fluid" />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <img src={instagram} alt="img" className="img-fluid" />
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            <img src={upload} alt="img" className="img-fluid" />
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-xl-3">
                      <div className="box_flx">
                        <div className="box">
                          <p className="para">Followers</p>
                          <h4>{FollowerCount}</h4>
                        </div>
                        <div className="box">
                          <p className="para">Following</p>
                          <h4>{FollowingCount}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="explore">
              <div className="container">
                <div className="overflow">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active ps-0"
                        id="one-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#one"
                        type="button"
                        role="tab"
                        aria-controls="one"
                        aria-selected="true"
                        onClick={() => setFiltercate("all")}
                      >
                        Created
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="two-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#two"
                        type="button"
                        role="tab"
                        aria-controls="two"
                        aria-selected="false"
                        onClick={() => setFiltercate("all")}
                      >
                        Owned
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="three-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#three"
                        type="button"
                        role="tab"
                        aria-controls="three"
                        aria-selected="false"
                      >
                        Collections
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="four-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#four"
                        type="button"
                        role="tab"
                        aria-controls="four"
                        aria-selected="false"
                      >
                        Swap history
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="five-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#five"
                        type="button"
                        role="tab"
                        aria-controls="five"
                        aria-selected="false"
                      >
                        Open swaps
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="six-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#six"
                        type="button"
                        role="tab"
                        aria-controls="six"
                        aria-selected="false"
                      >
                        Activities
                      </button>
                    </li>
                  </ul>
                </div>
    
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="one"
                    role="tabpanel"
                    aria-labelledby="one-tab"
                  >
                    <div className="h_option">
                      <div className="flx">
                        <div className="sel">
                          <select value={filterCata} onChange={catagorychange}>
                            <option value={`all`}>All Categories</option>
                            {Category &&
                              Category?.length > 0 &&
                              Category.map((list) => {
                                return (
                                  <>
                                    <option value={`${list.Category}`}>
                                      {list.Category}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                        <div className="icon">
                          <img
                            src={polygonicon}
                            alt="img"
                            className="img-fluid user"
                          />
                        </div>
                      </div>
                    </div>
                    <section className="popular_nft bg-transparent">
                      <div className="row flex-wrap">
                        {myNft&&myNft.length > 0 &&
                          myNft.map((nft) => {
                            let currDate = new Date();
                            let startdate = new Date(nft.startTime);
                            let enddate = new Date(nft.endTime);
                            let auction = "false";
                            let finish = "";
                            let enddate1 = "";
                            let showlist = "true";
                            let display_item = 0;
                            if (nft.PutOnSaleType == "TimedAuction") {
                              auction = "true";
                              let a = moment(nft.startTime);
                              let b = moment(nft.endTime);
                              let c = moment();
                              a.diff(b); // 86400000
                              let diffInMs = a.diff(c);
                              display_item = a.diff(c);
                              finish = b.diff(c);
                              enddate1 = parseFloat(diffInMs);
                              if (finish > 0) {
                                showlist = "true";
                              } else {
                                var auctionTxt = "Ended";
                                showlist = "false";
                              }
                            }
                            return (
                              <div className="col-lg-3">
                                <div className="box">
                                  {nft.PutOnSaleType == "TimedAuction" &&
                                    showlist == "true" &&
                                    display_item <= 0 && (
                                      <div className="de_countdown">
                                        <Countdown
                                          date={enddate}
                                          autoStart={true}
                                          onStart={() => startdate}
                                          renderer={renderer}
                                        />
                                      </div>
                                    )}
                                  {nft.PutOnSaleType == "TimedAuction" &&
                                    showlist == "true" &&
                                    display_item > 0 && (
                                      <div className="de_countdown">
                                        <Countdown
                                          date={startdate}
                                          autoStart={true}
                                          onStart={() => Date.now()}
                                          renderer={renderer1}
                                        />
                                      </div>
                                    )}
                                  <div className="img_box">
                                    {nft.isCollNft ? (
                                      <img
                                        src={`${config.baseUrl}/${nft.localImage}`}
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    )}
                                    {/* <img
                                      src={require("../assets/images/p1.png")}
                                      alt="img"
                                      className="img-fluid"
                                    /> */}
                                  </div>
                                  <div className="blw_box">
                                    <h5>{nft.nftName}</h5>
                                    <div className="flx_ctn">
                                      <div className="mb-3">
                                        <span>Price</span>
                                        <h6>{nft.price} MATIC</h6>
                                      </div>
                                      <div>
                                        <span>Last Sale</span>
                                        <p>{nft.result?<>{nft.result.amount} MATIC</>:'Not yet'}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="two"
                    role="tabpanel"
                    aria-labelledby="two-tab"
                  >
                    <div className="h_option">
                      <div className="flx">
                        <div className="sel">
                          <select value={filterCata} onChange={catagorychange}>
                            <option value={`all`}>All Categories</option>
                            {Category &&
                              Category.length > 0 &&
                              Category.map((list) => {
                                return (
                                  <>
                                    <option value={`${list.Category}`}>
                                      {list.Category}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                        <div className="icon">
                          <img
                            src={polygonicon}
                            alt="img"
                            className="img-fluid user"
                          />
                        </div>
                      </div>
                    </div>
                    <section className="popular_nft bg-transparent">
                      <div className="row flex-wrap">
                        {ownNft&& ownNft.length > 0 &&
                          ownNft.map((nft) => {
                            let currDate = new Date();
                            let startdate = new Date(nft.startTime);
                            let enddate = new Date(nft.endTime);
                            let auction = "false";
                            let finish = "";
                            let enddate1 = "";
                            let showlist = "true";
                            let display_item = 0;
                            if (nft.PutOnSaleType == "TimedAuction") {
                              auction = "true";
                              let a = moment(nft.startTime);
                              let b = moment(nft.endTime);
                              let c = moment();
                              a.diff(b); // 86400000
                              let diffInMs = a.diff(c);
                              display_item = a.diff(c);
                              finish = b.diff(c);
                              enddate1 = parseFloat(diffInMs);
                              if (finish > 0) {
                                showlist = "true";
                              } else {
                                var auctionTxt = "Ended";
                                showlist = "false";
                              }
                            }
                            return (
                              <div className="col-lg-3">
                                <div className="box">
                                {nft.PutOnSaleType == "TimedAuction" &&
                                    showlist == "true" &&
                                    display_item <= 0 && (
                                      <div className="de_countdown">
                                        <Countdown
                                          date={enddate}
                                          autoStart={true}
                                          onStart={() => startdate}
                                          renderer={renderer}
                                        />
                                      </div>
                                    )}
                                  {nft.PutOnSaleType == "TimedAuction" &&
                                    showlist == "true" &&
                                    display_item > 0 && (
                                      <div className="de_countdown">
                                        <Countdown
                                          date={startdate}
                                          autoStart={true}
                                          onStart={() => Date.now()}
                                          renderer={renderer1}
                                        />
                                      </div>
                                    )}
                                  <div className="img_box">
                                    {nft.isCollNft ? (
                                      <img
                                        src={`${config.baseUrl}/${nft.localImage}`}
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    )}
                                    {/* <img
                                      src={require("../assets/images/p1.png")}
                                      alt="img"
                                      className="img-fluid"
                                    /> */}
                                  </div>
                                  <div className="blw_box">
                                    <h5>{nft.nftName}</h5>
                                    <div className="flx_ctn">
                                      <div className="mb-3">
                                        <span>Price</span>
                                        <h6>{nft.price} MATIC</h6>
                                      </div>
                                      <div>
                                        <span>Last Sale</span>
                                        <p>{nft.result?<>{nft.result.amount} MATIC</>:'Not yet'}</p>
                                        {/* <p>4.56 MATIC</p> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="three"
                    role="tabpanel"
                    aria-labelledby="three-tab"
                  >
                    <div className="h_option">
                      <div className="flx">
                        <div className="sel">
                          <select value={filterCata} onChange={catagorychange}>
                            <option value={`all`}>All Categories</option>
                            {Category &&
                              Category.length > 0 &&
                              Category.map((list) => {
                                return (
                                  <>
                                    <option value={`${list.Category}`}>
                                      {list.Category}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                        <div className="icon">
                          <img
                            src={polygonicon}
                            alt="img"
                            className="img-fluid user"
                          />
                        </div>
                      </div>
                    </div>
                    <section className="recent_swap pt-4 mt-3">
                      <div class="table-responsive">
                        <table class="table table-primary">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>COLLECTION</th>
                              <th>VOLUME</th>
                              <th>% 24CHANGE</th>
                              <th>FLOOR PRICE</th>
                              <th>% CHANGE</th>
                              <th>ITEMS</th>
                              <th>OWNERS</th>
                              <th>SALES</th>
                            </tr>
                          </thead>
                          <tbody>
                          {CollectionList &&
                            CollectionList.length > 0 &&
                            CollectionList.map((list, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>
                                    <div className="flx">
                                      <img
                                        // src={require("../assets/images/prf.png")}
                                        src={`${config.baseUrl}/collections/${list?._doc?.bannerImage}`}
                                        alt="img"
                                        className="img-fluid user_mini"
                                      />
                                      <span>{list._doc.name}</span>
                                    </div>
                                  </td>
                                  <td>{list.volume.toFixed(5)} MATIC</td>
                                  <td className="red">{((list.Totalvolume/list.volume)*100)==Infinity||isNaN((list?.Totalvolume/list?.volume)*100)?'0':((list.Totalvolume/list.volume)*100)}% </td>
                                  {/* <td className="red">-5.4%</td> */}
                                  <td>{list.min ? list.min : 0} MATIC</td>
                                  <td className="red">-12.6%</td>
                                  <td>{list.totalPrice.length}</td>
                                  <td>{list.totalPrice.length}</td>
                                  <td>{list.collectionQuery.length}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="four"
                    role="tabpanel"
                    aria-labelledby="four-tab"
                  >
                    <div className="h_option">
                      <div className="flx">
                        <div className="sel">
                          <select>
                            <option>All Categories</option>
                            <option>Trending</option>
                          </select>
                        </div>
                        <div className="icon">
                          <img
                            src={polygonicon}
                            alt="img"
                            className="img-fluid user"
                          />
                        </div>
                      </div>
                    </div>
                    <section className="recent_swap recent_swap_t pt-4 mt-3">
                      <div class="table-responsive">
                        <table class="table table-primary">
                          <thead>
                            <tr>
                              <th className="ps-5">#</th>
                              <th>PARTY A</th>
                              <th>ITEM A</th>
                              <th>STATUS</th>
                              <th>PARTY B</th>
                              <th>ITEM B</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="ps-5">1</td>
                              <td>
                                <div className="flx">
                                  <img
                                    src={require("../assets/images/usr_prf.png")}
                                    alt="img"
                                    className="img-fluid user"
                                  />
                                  <span>MGalaxyworld</span>
                                </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                              <td>
                                <button class="completed">Completed</button>
                              </td>
                              <td>
                                <div className="flx">
                                  <img
                                    src={require("../assets/images/usr_prf.png")}
                                    alt="img"
                                    className="img-fluid user"
                                  />
                                  <span>MGalaxyworld</span>
                                </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                            </tr>
                            <tr>
                              <td className="ps-5">2</td>
                              <td>
                                <div className="flx">
                                  <img
                                    src={require("../assets/images/usr_prf.png")}
                                    alt="img"
                                    className="img-fluid user"
                                  />
                                  <span>MGalaxyworld</span>
                                </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                              <td>
                                <button class="completed">Completed</button>
                              </td>
                              <td>
                                <div className="flx">
                                  <img
                                    src={require("../assets/images/usr_prf.png")}
                                    alt="img"
                                    className="img-fluid user"
                                  />
                                  <span>MGalaxyworld</span>
                                </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                            </tr>
                            <tr>
                              <td className="ps-5">3</td>
                              <td>
                                <div className="flx">
                                  <img
                                    src={require("../assets/images/usr_prf.png")}
                                    alt="img"
                                    className="img-fluid user"
                                  />
                                  <span>MGalaxyworld</span>
                                </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                              <td>
                                <button class="completed">Completed</button>
                              </td>
                              <td>
                                <div className="flx">
                                  <img
                                    src={require("../assets/images/usr_prf.png")}
                                    alt="img"
                                    className="img-fluid user"
                                  />
                                  <span>MGalaxyworld</span>
                                </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                            </tr>
                            <tr>
                              <td className="ps-5">4</td>
                              <td>
                                <div className="flx">
                                  <img
                                    src={require("../assets/images/usr_prf.png")}
                                    alt="img"
                                    className="img-fluid user"
                                  />
                                  <span>MGalaxyworld</span>
                                </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                              <td>
                                <button class="completed">Completed</button>
                              </td>
                              <td>
                                <div className="flx">
                                  <img
                                    src={require("../assets/images/usr_prf.png")}
                                    alt="img"
                                    className="img-fluid user"
                                  />
                                  <span>MGalaxyworld</span>
                                </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                            </tr>
                            <tr>
                              <td className="ps-5">5</td>
                              <td>
                                <div className="flx">
                                  <img
                                    src={require("../assets/images/usr_prf.png")}
                                    alt="img"
                                    className="img-fluid user"
                                  />
                                  <span>MGalaxyworld</span>
                                </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                              <td>
                                <button class="completed">Completed</button>
                              </td>
                              <td>
                                <div className="flx">
                                  <img
                                    src={require("../assets/images/usr_prf.png")}
                                    alt="img"
                                    className="img-fluid user"
                                  />
                                  <span>MGalaxyworld</span>
                                </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="five"
                    role="tabpanel"
                    aria-labelledby="five-tab"
                  >
                    <section className="popular_nft bg-transparent">
                      <div className="row flex-wrap">
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={require("../assets/images/p1.png")}
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>Gorilla DJ Party</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>15.97 MATIC</h6>
                                </div>
                                <div>
                                  <span>Last Sale</span>
                                  <p>4.56 MATIC</p>
                                </div>
                              </div>
                              <button className="primary_btn w-100">
                                <span>Swap</span>
                                <img
                                  src={swap_btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="six"
                    role="tabpanel"
                    aria-labelledby="six-tab"
                  >
                    <section className="activities">
                      <div className="outbox_flx">
                        <div className="box_flx">
                          <div className="img_box">
                            <img
                              src={activities_user}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                          <div>
                            <p className="para mb-0">Gorilla DJ Party #200</p>
                            <span className="d-block mb-3 ">
                              1 edition minted by{" "}
                              <span className="name"> Djaina</span>
                            </span>
                            <span>6/30/2023, 11:27 PM</span>
                          </div>
                        </div>
                        <img src={activities_arw} alt="img" className="img-fluid" />
                      </div>
                      <div className="outbox_flx">
                        <div className="box_flx">
                          <div className="img_box">
                            <img
                              src={activities_user}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                          <div>
                            <p className="para mb-0">Gorilla DJ Party #200</p>
                            <span className="d-block mb-3 ">
                              1 edition minted by{" "}
                              <span className="name"> Djaina</span>
                            </span>
                            <span>6/30/2023, 11:27 PM</span>
                          </div>
                        </div>
                        <img src={activities_arw} alt="img" className="img-fluid" />
                      </div>
                      <div className="outbox_flx">
                        <div className="box_flx">
                          <div className="img_box">
                            <img
                              src={activities_user}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                          <div>
                            <p className="para mb-0">Gorilla DJ Party #200</p>
                            <span className="d-block mb-3 ">
                              1 edition minted by{" "}
                              <span className="name"> Djaina</span>
                            </span>
                            <span>6/30/2023, 11:27 PM</span>
                          </div>
                        </div>
                        <img src={activities_arw} alt="img" className="img-fluid" />
                      </div>
                      <div className="outbox_flx">
                        <div className="box_flx">
                          <div className="img_box">
                            <img
                              src={activities_user}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                          <div>
                            <p className="para mb-0">Gorilla DJ Party #200</p>
                            <span className="d-block mb-3 ">
                              1 edition minted by{" "}
                              <span className="name"> Djaina</span>
                            </span>
                            <span>6/30/2023, 11:27 PM</span>
                          </div>
                        </div>
                        <img src={activities_arw} alt="img" className="img-fluid" />
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>:<div>Please connect wallet </div>
          }
          
    
          <Footer />
        </div>
      );
}
