import React from "react";
import { Link } from "react-router-dom";
import btnarw_white from "../assets/images/btnarw_white.svg";
import logowhite from "../assets/images/logowhite.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { subscribeUser } from "../actions/users";
import { toastAlert } from "../lib/toastAlert.js";

const EmailValidation = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .email()
    .matches(/^(?!.*@[^,]*,)/)
});

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <div className="row">
            <div className="col-md-6">
              <Link to="/">
                <img
                  src={logowhite}
                  alt="img"
                  className="img-fluid mb-4 flogo"
                />
              </Link>
              <Formik
                initialValues={{
                  email: ""
                }}
                validationSchema={EmailValidation}
                onSubmit={async ({ email }) => {
                  console.log(email);
                  let payload = {
                    email: email
                  };
                  const result = await subscribeUser(payload);
                  console.log(result);
                  if (result.data.status) {
                    toastAlert("success", result.data.message);
                  } else {
                    toastAlert("error", result.data.message);
                  }
                }}
              >
                {({ errors, touched }) => (
                  <div className="maxbox">
                    <Form>
                      <div className="mb-3">
                      <div className="input-group ">
                        <Field
                          type="text"
                          name="email"
                          className="form-control"
                          placeholder="Enter your email..."
                          aria-label="Button"
                          aria-describedby=""
                        />
                        <button className="btn primary_btn" type="submit" id="">
                          <span> Subscribe</span>
                          <img
                            src={btnarw_white}
                            alt="img"
                            className="ms-2 img-fluid"
                          />
                        </button>
                       
                      </div>
                      <span>
                          {errors.email && touched.email ? (
                            <span className="error-msg">{errors.email}</span>
                          ) : null}
                        </span>
                      </div>
                      


                    </Form>

                    <p className="para">
                      Subscribe to our newsletter to stay updated on about our
                      NFTs
                    </p>
                  </div>
                )}
              </Formik>
            </div>
            <div className="col-md-2">
              <h6>Learn</h6>
              <ul>
                <li>
                  <Link to="#">Events</Link>
                </li>
                <li>
                  <Link to="/create">Create</Link>
                </li>
                <li>
                  <Link to="#">Collect</Link>
                </li>
                <li>
                  <Link to="/explore">Explore</Link>
                </li>
                <li>
                  <Link to="#">Blog</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <div className="row flx">
                <div className="col-md-6">
                  <h6>Company</h6>
                  <ul>
                    <li>
                      <Link to="#">Career</Link>
                    </li>
                    <li>
                      <Link to="/help">Help Center</Link>
                    </li>
                    <li>
                      <Link to="#">Partners</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <h6>Contact</h6>
                  <ul>
                    <li>
                      <Link to="#">Twitter</Link>
                    </li>
                    <li>
                      <Link to="#">Instagram</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_bottom">
          <ul>
            <li>©Marketplac3, Inc</li>
            <li>Community guidelines </li>
            <li>
              <Link to='/TermsAndCondition'>Terms and Condition</Link>
            </li>
            <li>
              <Link to={'/Policy'}>Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}
