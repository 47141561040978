import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import btnarw_white from "../assets/images/btnarw_white.svg";
import fixed from "../assets/images/fixed.svg";
import timed from "../assets/images/timed.svg";
import $ from "jquery";
import info from "../assets/images/info.svg";
import config from "../lib/config";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { getServiceFees } from "../actions/nfts";
import Web3 from "web3";
import HashLoader from "react-spinners/HashLoader";
import { toastAlert } from "../lib/toastAlert";
import SingleNFTABI from "../ABI/SingleNFTABI.json";
import {
  ipfsImageHashGet,
  ipfsMetaDataFn,
  TokenAddItemAction,
  getCategory,
  nftNameCheck
} from "../actions/nfts";
import { Numberconvert } from "../helper/numberConvert.js";
import { useSelector } from "react-redux";

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

const NFTSchema = Yup.object().shape({
  nftName: Yup.string()
    .required("Required")
    .test("value", "Required", (value) => value.trim().length != 0)
    .matches(
      /^([a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Enter only normal character"
    ),
  categorySelect: Yup.string().required("Required"),
  NFTImg: Yup.mixed().required("Required"),
  nftPrice: Yup.number()
    .required("Required")
    .typeError("Value must specify a number")
    .moreThan(0, "Value must be greater than 0")
    .test(
      "maxDigitsAfterDecimal",
      "Value must be less than or equal to 8 decimals",
      (nftPrice) => /^\d+(\.\d{1,8})?$/.test(Numberconvert(nftPrice))
    ),
  nftRoyalty: Yup.number()
    .required("Required")
    .min(5, "Value must be greater than or equal to 5")
    .max(40, "Value must be less than or equal to 40")
    .typeError("Value must specify a number (within 5 to 40)")
});

const NFTSchema1 = Yup.object().shape({
  nftName: Yup.string()
    .required("Required")
    .test("value", "Required", (value) => value.trim().length != 0)
    .matches(
      /^([a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Enter only normal character"
    ),
  categorySelect: Yup.string().required("Required"),
  NFTImg: Yup.mixed().required("Required"),
  nftRoyalty: Yup.number()
    .required("Required")
    .min(5, "Value must be greater than or equal to 5")
    .max(50, "Value must be less than or equal to 50")
    .typeError("Value must specify a number (within 5 to 50)"),

  nftMinPrice: Yup.number()
    .required("Required")
    .typeError("Value must specify a number")
    .moreThan(0, "Value must be greater than 0")
    .lessThan(Yup.ref("nftMaxPrice"), "Value must be less than maximum price")
    .test(
      "maxDigitsAfterDecimal",
      "Value must be less than or equal to 8 decimals",
      (nftMinPrice) => /^\d+(\.\d{1,8})?$/.test(Numberconvert(nftMinPrice))
    ),
  nftMaxPrice: Yup.number()
    .required("Required")
    .typeError("Value must specify a number")
    .moreThan(0, "Value must be greater than 0")
    .moreThan(Yup.ref("nftMinPrice"), "Value must be more than minimum price")
    .test(
      "maxDigitsAfterDecimal",
      "Value must be less than or equal to 8 decimals",
      (nftMaxPrice) => /^\d+(\.\d{1,8})?$/.test(Numberconvert(nftMaxPrice))
    )
});

export default function Create_nft() {
  var myDate = new Date();
  var newdat = myDate.setDate(myDate.getDate() + parseInt(1));
  var initialEnd = new Date(newdat);
  const walletConnection = useSelector((state) => state.walletConnection);
  const [loader, setLoader] = useState(false);
  const [serviceFee, setServFee] = useState(0);
  const [saleType, setSaleType] = useState("FixedPrice");
  const [TokenFilePreUrl, setTokenFilePreUrl] = useState("");
  const [TokenFilePreReader, setTokenFilePreReader] = useState("");
  const [nftImage, setNFTImage] = useState("");
  const [validateError1, setValidateError1] = useState("");
  const [Unlockoncepurchased, Set_Unlockoncepurchased] = useState(false);
  const [freeMinting, Set_freeMinting] = useState(false);
  const [swapEnable, Set_swapEnable] = useState(false);
  const [sencitiveCnt, Set_sencitiveCnt] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [ipfaHash, setIpfsHash] = useState("");
  const [ipfsUrl, setIpfsUrl] = useState("");
  const [ipfsMeta, setIpfsMeta] = useState("");

  const [StartDate, Set_StartDate] = useState("Right after listing");
  const [EndDate, Set_EndDate] = useState("1 Day");
  const [Clocktime, set_Clocktime] = useState(new Date());
  const [EndClocktime, set_EndClocktime] = useState(initialEnd);
  const [imgVali, setImgVali] = useState("");
  const [startTimeErr, setStartTimeErr] = useState("");
  const [endTimeErr, setendTimeErr] = useState("");

  useEffect(() => {
    ServiceFee();
    getCategoryList();
  }, []);

  const getCategoryList = async () => {
    try {
      const data = await getCategory();

      setCategoryList(data?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const ServiceFee = async () => {
    const serFee = await getServiceFees();

    setServFee(serFee.data.data);
  };

  const handleSaleTypeChange = (e) => {
    setSaleType(e.target.value);
  };

  // const handleFileChanage = (e, form) => {
  //   console.log(e, "Filesssss");
  //   var validExtensions = [
  //     "jpg",
  //     "png",
  //     "gif",
  //     "webp",
  //     "webm",
  //     "mp4",
  //     "mp3",
  //     "jpeg",
  //     "svg"
  //   ]; //array of valid extensions
  //   if (e.target && e.target.files) {
  //     var reader = new FileReader();
  //     var file = e.target.files[0];
  //     form.setFieldValue("NFTImg", file);
  //     var fileName = file.name;
  //     var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
  //     if ($.inArray(fileNameExt, validExtensions) == -1) {
  //       toastAlert(
  //         "error",
  //         "Only these file types are accepted : " + validExtensions.join(", ")
  //       );
  //       $("input[name='image']").val("");
  //       return false;
  //     } else {
  //       const fileSize = file.size / 1024 / 1024;
  //       if (fileSize > 100) {
  //         toastAlert("error", "File size exceeds 100 MB");
  //         $("input[name='image']").val("");
  //         return false;
  //       } else {
  //         setNFTImage(file);

  //         reader.onloadend = function (e) {
  //           if (reader.result) {
  //             setTokenFilePreReader(reader.result);
  //           }
  //         }.bind(this);
  //         setTokenFilePreUrl(e.target.value);
  //         setValidateError1("");
  //         setTokenFilePreReader(URL.createObjectURL(e.target.files[0]));
  //       }
  //     }
  //   }
  // };

  const CheckedChange = (e) => {
    if (e && e.target && e.target.name) {
      if (e.target.name == "unlockoncepurchased") {
        if (Unlockoncepurchased == false) {
          Set_Unlockoncepurchased(true);
        } else {
          Set_Unlockoncepurchased(false);
        }
      } else if (e.target.name == "freeMinting") {
        if (freeMinting == false) {
          Set_freeMinting(true);
        } else {
          Set_freeMinting(false);
        }
      } else if (e.target.name == "Swap") {
        if (swapEnable == false) {
          Set_swapEnable(true);
        } else {
          Set_swapEnable(false);
        }
      } else {
        if (sencitiveCnt == false) {
          Set_sencitiveCnt(true);
        } else {
          Set_sencitiveCnt(false);
        }
      }
    }
  };

  async function DateChange(from, val) {
    if (from == "StartDateDropDown") {
      if (val == "PickStartDate") {
        ModalAction("calendar_modal", "show");
      } else {
        Set_StartDate("Right after listing");
      }
    } else if (from == "EndDateDropDown") {
      if (val == "PickEndDate") {
        ModalAction("calendar_modal_expire", "show");
      } else {
        Set_EndDate(val);
        var myDate = new Date();
        var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        var newdate = new Date(newdat);
        set_EndClocktime(newdate);
      }
    }
  }

  async function ModalAction(id, type) {
    window.$("#" + id).modal(type);
    if (id == "calendar_modal") {
      if (Clocktime) {
        var dt = new Date(Clocktime);
        dt.setSeconds(0);
        var dt1 = dt.toLocaleString();
        Set_StartDate(dt1);
      }
    } else if (id == "calendar_modal_expire") {
      if (EndClocktime) {
        var dt = new Date(EndClocktime);
        dt.setSeconds(0);
        var dt1 = dt.toLocaleString();
        Set_EndDate(dt1);
      }
    }
  }

  const handlesetClocktime = (value) => {
    set_Clocktime(value);
    let currDate = new Date();
    let sDate = new Date(value);
    // sDate.setSeconds(0);
    if (new Date(currDate) > new Date(sDate)) {
      setStartTimeErr("Start date & Time must be greater than now");
    } else {
      setStartTimeErr("");
    }
  };

  const handleendClocktime = (value) => {
    set_EndClocktime(value);

    var startDate = new Date(Clocktime);
    var eDate = new Date(value);
    startDate.setSeconds(0);
    eDate.setSeconds(0);

    if (Date.parse(startDate) >= Date.parse(eDate)) {
      setendTimeErr("End date & Time must be greater than start date and Time");
    } else {
      setendTimeErr("");
    }
  };

  async function ChecktokenDecimal(amount, decimals) {
    var result = 0;
    var decimalsLength = 18;
    if (decimals && decimals > 0) {
      decimalsLength = decimals;
    }
    try {
      var number = amount.toString();
      var number1 = number.split(".");
      if (number1[1] && number1[1].length && number1[1].length > 0) {
        result = number1[0];
      } else {
        result = amount;
      }
      return result;
    } catch (err) {
      return result;
    }
  }

  return (
    <div>
      <Navbar />
      <Formik
        initialValues={{
          nftName: "",
          nftDisc: "",
          nftRoyalty: "",
          categorySelect: "",
          Collection: "",
          saleType: "",
          nftPrice: "",
          nftMaxPrice: "",
          nftMinPrice: "",
          unlockContent: "",
          NFTImg: ""
        }}
        validationSchema={saleType == "FixedPrice" ? NFTSchema : NFTSchema1}
        onSubmit={async ({
          nftName,
          nftDisc,
          categorySelect,
          Collection,
          nftPrice,
          nftRoyalty,
          nftMaxPrice,
          nftMinPrice,
          unlockContent,
          NFTImg
        }) => {
          const web3 = new Web3(window.ethereum);

          let currAddr = await web3.eth.requestAccounts();

          if (!currAddr) {
            toastAlert("error", "OOPS!..connect Your Wallet");
            return false;
          }
          let chainid = await web3.eth.getChainId();

          if (chainid != config.networkVersion) {
            toastAlert("error", "OOPS!..Connect To Matic Network ");
            return false;
          }
            if (Unlockoncepurchased == true) {
            console.log('nicoles');
            if(unlockContent==''){
              toastAlert(
                "error",
                "Unlock content required"
              );
              return false;
            }
            // return 
          }

          try {
            if (saleType == "TimedAuction") {
              var currDate = new Date();
              var sDate = new Date(Clocktime);

              if (StartDate == "Right after listing") {
                set_Clocktime(currDate);
                sDate = currDate;
              }
              var startDate = new Date(Clocktime);
              var eDate = new Date(EndClocktime);
              startDate.setSeconds(0);
              eDate.setSeconds(0);
              if (
                moment().add(1, "minute") > moment(sDate) &&
                !(StartDate == "Right after listing")
              ) {
                toastAlert(
                  "error",
                  "Start date & Time must be greater than now"
                );
                return false;
              } else if (Date.parse(startDate) >= Date.parse(eDate)) {
                toastAlert(
                  "error",
                  "End date & Time must be greater than start date and Time"
                );
                return false;
              }
            }
            if (swapEnable) {
              setLoader(true);
              const nameCheckpay = {
                nftName,
                flog: 0,
                address:walletConnection.address
              };
              const data = await nftNameCheck(nameCheckpay);
              if (data?.data?.status == false) {
                toastAlert("error", data?.data?.message);
                setLoader(false);
                setTimeout(() => {
                  window.location.reload()
                }, 2000);
                return false;
              }
              if (nftImage == "") {
                // toastAlert("error", "Image is required");
                setImgVali("Required");
                setLoader(false);
                return false;
              }

              await web3.eth.personal
                .sign("MintNFT Signature", currAddr[0])
                .then(async (signature) => {
                  const NFTMintContract = new web3.eth.Contract(
                    SingleNFTABI,
                    config.SingleNFT
                  );

                  var unix = Math.round(+new Date() / 1000);
                  let ran = Math.floor(Math.random() * 100 + 1);
                  let TokenID = unix + ran;

                  await NFTMintContract.methods
                    .setApprovalForAll(config.swapContract, true)
                    .send({ from: currAddr[0] })
                    .then(async (data) => {
                      console.log(data, "Datass");

                      const payload = {
                        Image: nftImage,
                        tokenID: TokenID
                      };
                      console.log(payload, "Pay");
                      var ipfa_Meta;
                      const ipfsImageHash = await ipfsImageHashGet(payload);
                      console.log(ipfsImageHash, "ipfsImageHash");
                      if (ipfsImageHash.data !== undefined) {
                        if (ipfsImageHash.data.ipfsval != "") {
                          setIpfsHash(ipfsImageHash.data.ipfsval);
                          setIpfsUrl(
                            `${config.IPFS_IMG}/${ipfsImageHash.data.ipfsval}`
                          );
                        }

                        let metaPayload = {
                          name: nftName,
                          tokenID: TokenID,
                          image:
                            config.IPFS_IMG + "/" + ipfsImageHash.data.ipfsval,
                          owner: currAddr[0]
                        };
                        let ipfsMetaData = await ipfsMetaDataFn(metaPayload);
                        if (ipfsMetaData.data !== undefined) {
                          if (ipfsMetaData.data.ipfsval != "") {
                            setIpfsMeta(ipfsMetaData.data.ipfsval);
                            ipfa_Meta = ipfsMetaData.data.ipfsval;
                          }
                        }
                      }

                      let transactionHash = "";
                      let DigitPrice = nftPrice * config.decimalValues;
                      let DecimalCheck = await ChecktokenDecimal(DigitPrice);
                      let PriceValue = await Numberconvert(DecimalCheck);
                      console.log(
                        TokenID,
                        nftName,
                        config.IPFS_IMG + "/" + ipfsImageHash.data.ipfsval,
                        ipfa_Meta,
                        saleType == "TimedAuction"
                          ? "0"
                          : PriceValue.toString(),
                        "1",
                        nftRoyalty,
                        "_____dfedfefwcedf"
                      );
                      await NFTMintContract.methods
                        .mint(
                          TokenID,
                          nftName,
                          config.IPFS_IMG + "/" + ipfsImageHash.data.ipfsval,
                          ipfa_Meta,
                          saleType == "TimedAuction"
                            ? "0"
                            : PriceValue.toString(),
                          "1",
                          nftRoyalty
                        )
                        .send({ from: currAddr[0] })
                        .then(async (result) => {
                          console.log(result, "Result");
                          if (result.transactionHash) {
                            transactionHash = result.transactionHash;
                            const nftPayload = {
                              nftName,
                              nftId: TokenID,
                              nftDesc: nftDisc,
                              price: nftPrice,
                              tokenRoyality: nftRoyalty,
                              ipfsimageUrl: `${config.IPFS_IMG}/${ipfsImageHash.data.ipfsval}`,
                              localImage: ipfsImageHash.data.filename,
                              tokenOwner: currAddr[0],
                              Hash: result.transactionHash,
                              tokenCreator: currAddr[0],
                              saleType: saleType == "TimedAuction" ? 1 : 0,
                              PutOnSaleType:
                                saleType == "FixedPrice"
                                  ? "FixedPrice"
                                  : "TimedAuction",
                              freeMinting: freeMinting,
                              sencitiveCnt: sencitiveCnt,
                              categorySelect: categorySelect,
                              CollContract: config.SingleNFT,
                              swapEnable: swapEnable,
                              isCollNft: false
                            };
                            if (saleType == "TimedAuction") {
                              // alert("CheckTime");
                              nftPayload.MinimumPrice = nftMinPrice;
                              nftPayload.MaximumPrice = nftMaxPrice;
                              nftPayload.Clocktime = Clocktime;
                              nftPayload.EndClocktime = EndClocktime;
                              nftPayload.price = 0;
                            }
                            if (Unlockoncepurchased == true) {
                              nftPayload.Unlockoncepurchased =
                                Unlockoncepurchased;
                              nftPayload.unlockContent = unlockContent;
                            }
                            console.log(nftPayload, "nftPayload");

                            const data = await TokenAddItemAction(nftPayload);
                            console.log(data, "desdewfewfcewfced");
                            if (data.data.status) {
                              toastAlert("success", "NFT Created Successfully");
                              setTimeout(() => {
                                window.location.href = "/explore";
                                setLoader(false);
                              }, 2000);
                            }
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                          setLoader(false);
                        });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                })
                .catch((err) => {
                  console.log(err);
                  toastAlert("error", "Sign rejected");
                  setLoader(false);
                });
            } else {
              setLoader(true);
              const nameCheckpay = {
                nftName,
                flog: 0,
                address:walletConnection.address
              };
              const data = await nftNameCheck(nameCheckpay);
              if (data?.data?.status == false) {
                toastAlert("error", data?.data?.message);
                setTimeout(() => {
                  window.location.reload()
                }, 2000);
                setLoader(false);
                return false;
              }
              console.log(typeof nftImage, "nftImage");
              if (nftImage == "") {
                // toastAlert("error", "Image is required");
                setImgVali("Required");
                setLoader(false);
                return false;
              }
              await web3.eth.personal
                .sign("MintNFT Signature", currAddr[0])
                .then(async (signature) => {
                  const NFTMintContract = new web3.eth.Contract(
                    SingleNFTABI,
                    config.SingleNFT
                  );

                  var unix = Math.round(+new Date() / 1000);
                  let ran = Math.floor(Math.random() * 100 + 1);
                  let TokenID = unix + ran;
                  const payload = {
                    Image: nftImage,
                    tokenID: TokenID
                  };
                  console.log(payload, "Pay");
                  var ipfa_Meta;
                  const ipfsImageHash = await ipfsImageHashGet(payload);
                  console.log(ipfsImageHash, "ipfsImageHash");
                  if (ipfsImageHash.data !== undefined) {
                    if (ipfsImageHash.data.ipfsval != "") {
                      setIpfsHash(ipfsImageHash.data.ipfsval);
                      setIpfsUrl(
                        `${config.IPFS_IMG}/${ipfsImageHash.data.ipfsval}`
                      );
                    }

                    let metaPayload = {
                      name: nftName,
                      tokenID: TokenID,
                      image: config.IPFS_IMG + "/" + ipfsImageHash.data.ipfsval,
                      owner: currAddr[0]
                    };
                    let ipfsMetaData = await ipfsMetaDataFn(metaPayload);
                    if (ipfsMetaData.data !== undefined) {
                      if (ipfsMetaData.data.ipfsval != "") {
                        setIpfsMeta(ipfsMetaData.data.ipfsval);
                        ipfa_Meta = ipfsMetaData.data.ipfsval;
                      }
                    }
                  }

                  let transactionHash = "";
                  let DigitPrice = nftPrice * config.decimalValues;
                  let DecimalCheck = await ChecktokenDecimal(DigitPrice);
                  let PriceValue = await Numberconvert(DecimalCheck);
                  console.log(
                    TokenID,
                    nftName,
                    config.IPFS_IMG + "/" + ipfsImageHash.data.ipfsval,
                    ipfa_Meta,
                    saleType == "TimedAuction" ? "0" : PriceValue.toString(),
                    "1",
                    nftRoyalty,
                    "_____dfedfefwcedf"
                  );
                  await NFTMintContract.methods
                    .mint(
                      TokenID,
                      nftName,
                      config.IPFS_IMG + "/" + ipfsImageHash.data.ipfsval,
                      ipfa_Meta,
                      saleType == "TimedAuction" ? "0" : PriceValue.toString(),
                      "1",
                      nftRoyalty
                    )
                    .send({ from: currAddr[0] })
                    .then(async (result) => {
                      console.log(result, "Result");
                      if (result.transactionHash) {
                        transactionHash = result.transactionHash;
                        const nftPayload = {
                          nftName,
                          nftId: TokenID,
                          nftDesc: nftDisc,
                          price: nftPrice,
                          tokenRoyality: nftRoyalty,
                          ipfsimageUrl: `${config.IPFS_IMG}/${ipfsImageHash.data.ipfsval}`,
                          localImage: ipfsImageHash.data.filename,
                          tokenOwner: currAddr[0],
                          Hash: result.transactionHash,
                          tokenCreator: currAddr[0],
                          saleType: saleType == "TimedAuction" ? 1 : 0,
                          PutOnSaleType:
                            saleType == "FixedPrice"
                              ? "FixedPrice"
                              : "TimedAuction",
                          freeMinting: freeMinting,
                          sencitiveCnt: sencitiveCnt,
                          categorySelect: categorySelect,
                          CollContract: config.SingleNFT,
                          swapEnable: swapEnable,
                          isCollNft: false
                        };
                        if (saleType == "TimedAuction") {
                          nftPayload.MinimumPrice = nftMinPrice;
                          nftPayload.MaximumPrice = nftMaxPrice;
                          nftPayload.Clocktime = Clocktime;
                          nftPayload.EndClocktime = EndClocktime;
                          nftPayload.price = 0;
                        }
                        if (Unlockoncepurchased == true) {
                          nftPayload.Unlockoncepurchased = Unlockoncepurchased;
                          nftPayload.unlockContent = unlockContent;
                        }
                        console.log(nftPayload, "nftPayload");

                        const data = await TokenAddItemAction(nftPayload);
                        console.log(data, "desdewfewfcewfced");
                        if (data.data.status) {
                          toastAlert("success", "NFT Created Successfully");
                          setTimeout(() => {
                            window.location.href = "/explore";
                            setLoader(false);
                          }, 2000);
                        }
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      setLoader(false);
                    });
                })
                .catch((err) => {
                  console.log(err);
                  toastAlert("error", "Sign rejected");
                  setLoader(false);
                });
            }
          } catch (err) {
            console.log(err);
          }
        }}
      >
        {({ errors, touched, isSubmitting, values }) => (
          <div className="page_box">
            <div className="create_nft">
              <div className="container">
                <div className="row">
                  <div className="col-md-9 mx-auto">
                    <h2 className="h2tag mb-2">Create Your New NFT </h2>
                    <div className="row">
                      <div className="col-lg-6">
                        <p className="para pb-5">
                          Single . {config.Currency.toLowerCase()}
                        </p>
                        <Form>
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Upload file<span className="star">*</span>
                            </label>
                            <p>Image, Video or Audio.</p>
                            <p>
                              File types supported: PNG, JPEG, GIF, MP4, MP3,
                              SVG, WEBP, WEBM.
                            </p>
                            <p className="mb-3">Maximum file size: 100mb</p>
                            <Field name="NFTImg">
                              {({ field, form }) => (
                                <>
                                  <div className="choose_box">
                                    <input
                                      type="file"
                                      className="form-control"
                                      onChange={(e) => {
                                        console.log(e, "Filesssss");
                                        var validExtensions = [
                                          "jpg",
                                          "png",
                                          "gif",
                                          "webp",
                                          "webm",
                                          "mp4",
                                          "mp3",
                                          "jpeg",
                                          "svg"
                                        ]; //array of valid extensions
                                        if (
                                          e.target &&
                                          e.target.files.length > 0
                                        ) {
                                          var reader = new FileReader();
                                          var file = e.target.files[0];
                                          form.setFieldValue("NFTImg", file);
                                          var fileName = file.name;
                                          var fileNameExt = fileName.substr(
                                            fileName.lastIndexOf(".") + 1
                                          );
                                          if (
                                            $.inArray(
                                              fileNameExt,
                                              validExtensions
                                            ) == -1
                                          ) {
                                            toastAlert(
                                              "error",
                                              "Only these file types are accepted : " +
                                                validExtensions.join(", ")
                                            );
                                            $("input[name='image']").val("");
                                            return false;
                                          } else {
                                            const fileSize =
                                              file.size / 1024 / 1024;
                                            if (fileSize > 100) {
                                              toastAlert(
                                                "error",
                                                "File size exceeds 100 MB"
                                              );
                                              $("input[name='image']").val("");
                                              return false;
                                            } else {
                                              setNFTImage(file);

                                              reader.onloadend = function (e) {
                                                if (reader.result) {
                                                  setTokenFilePreReader(
                                                    reader.result
                                                  );
                                                }
                                              }.bind(this);
                                              setTokenFilePreUrl(
                                                e.target.value
                                              );
                                              setValidateError1("");
                                              setTokenFilePreReader(
                                                URL.createObjectURL(
                                                  e.target.files[0]
                                                )
                                              );
                                            }
                                          }
                                        }
                                      }}
                                      accept=".jpg, .jpeg, .png, .gif, .mp3, .mp4, .svg, .webp, .webm"
                                    />
                                    <span>Choose file</span>
                                  </div>
                                  {console.log(imgVali, "imgVali")}
                                  {errors.NFTImg && touched.NFTImg ? (
                                    <span className="error-msg">
                                      {errors.NFTImg}
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Field>
                          </div>{" "}
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Name<span className="star">*</span>
                            </label>
                            <Field
                              type="text"
                              name="nftName"
                              className="form-control"
                              placeholder="e.g John Smith"
                            />
                            {errors.nftName && touched.nftName ? (
                              <span className="error-msg">
                                {errors.nftName}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-4 pb-1">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Description
                              <span className="small">(optional)</span>
                            </label>
                            <p className="mb-3">
                              The description provided will be included on the
                              nft’s detail page, underneath its image.
                            </p>
                            <div className="form-floating">
                              <Field
                                id="floatingTextarea2"
                                name="nftDisc"
                                render={({ field, form: { isSubmitting } }) => (
                                  <textarea
                                    {...field}
                                    placeholder="Provide a detailed description of your item"
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="create_nftbox mb-4">
                            <div className="flx">
                              <div className="flx_box ms-0">
                                <div className="form-check">
                                  <Field>
                                    {({ form }) => (
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="saleType"
                                        id="flexRadioDefault1"
                                        onChange={(e) => {
                                          form.setFieldValue("nftPrice", "");
                                          setSaleType(e.target.value);
                                        }}
                                        value="FixedPrice"
                                        defaultChecked
                                      />
                                    )}
                                  </Field>

                                  <label
                                    className="form-check-label"
                                    for="flexRadioDefault1"
                                  >
                                    <img
                                      src={fixed}
                                      alt="img"
                                      className="img-fluid mb-3"
                                    />
                                    <span> Fixed price</span>
                                  </label>
                                </div>
                              </div>
                              <div className="flx_box me-0">
                                <div className="form-check">
                                  <Field>
                                    {({ form }) => (
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name="saleType"
                                        id="flexRadioDefault2"
                                        onChange={(e) => {
                                          form.setFieldValue("nftMinPrice", "");
                                          form.setFieldValue("nftMaxPrice");
                                          Set_StartDate("");
                                          set_EndClocktime("");

                                          setSaleType(e.target.value);
                                        }}
                                        value="TimedAuction"
                                      />
                                    )}
                                  </Field>

                                  <label
                                    className="form-check-label"
                                    for="flexRadioDefault2"
                                  >
                                    <img
                                      src={timed}
                                      alt="img"
                                      className="img-fluid mb-3"
                                    />
                                    <span>Time auctioned</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {saleType == "FixedPrice" ? (
                            <>
                              <div className="mb-4">
                                <label
                                  for="exampleInputEmail1"
                                  className="form-label"
                                >
                                  Price<span className="star">*</span>
                                </label>
                                <div className="input-group">
                                  <Field
                                    type="number"
                                    className="form-control"
                                    name="nftPrice"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", ",", " "].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                  />
                                  <span
                                    className="input-group-text"
                                    id="basic-addon2"
                                  >
                                    {config.Currency}
                                  </span>
                                </div>
                                {errors.nftPrice && touched.nftPrice ? (
                                  <span className="error-msg">
                                    {errors.nftPrice}
                                  </span>
                                ) : null}
                              </div>

                              <div className="mb-4">
                                <div className="price_box">
                                  <div className="fl_x">
                                    <span>Your price</span>
                                    <span>
                                      {Numberconvert(values.nftPrice)}{" "}
                                      {config.Currency}
                                    </span>
                                  </div>
                                  <div className="fl_x bdr">
                                    <span>
                                      Transaction fee
                                      <span
                                        class="d-inline-block"
                                        tabindex="0"
                                        data-bs-toggle="tooltip"
                                        title="Transaction fee"
                                      >
                                        <img
                                          src={info}
                                          alt="img"
                                          className="ms-2 img-fluid"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span>
                                    </span>
                                    <span>{serviceFee.singleServiceFee}%</span>
                                  </div>
                                  <div className="fl_x">
                                    <span>You get</span>
                                    <span>
                                      {(
                                        Numberconvert(values.nftPrice) -
                                        (Numberconvert(values.nftPrice) *
                                          serviceFee.singleServiceFee) /
                                          100
                                      ).toFixed(8)}{" "}
                                      {config.Currency}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="mb-4">
                                <label
                                  for="exampleInputEmail1"
                                  className="form-label"
                                >
                                  Minimum Price<span className="star">*</span>
                                </label>
                                <div className="input-group">
                                  <Field
                                    type="number"
                                    className="form-control"
                                    name="nftMinPrice"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", ",", " "].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                  />
                                  <span
                                    className="input-group-text"
                                    id="basic-addon2"
                                  >
                                    {config.TokenCurrency}
                                  </span>
                                </div>
                                {errors.nftMinPrice && touched.nftMinPrice ? (
                                  <span className="error-msg">
                                    {errors.nftMinPrice}
                                  </span>
                                ) : null}
                              </div>

                              <div className="mb-4">
                                <label
                                  for="exampleInputEmail1"
                                  className="form-label"
                                >
                                  Maximum Price<span className="star">*</span>
                                </label>
                                <div className="input-group">
                                  <Field
                                    type="number"
                                    className="form-control"
                                    name="nftMaxPrice"
                                    onKeyDown={(evt) =>
                                      ["e", "E", "+", "-", ",", " "].includes(
                                        evt.key
                                      ) && evt.preventDefault()
                                    }
                                  />
                                  <span
                                    className="input-group-text"
                                    id="basic-addon2"
                                  >
                                    {config.TokenCurrency}
                                  </span>
                                </div>
                                {errors.nftMaxPrice && touched.nftMaxPrice ? (
                                  <span className="error-msg">
                                    {errors.nftMaxPrice}
                                  </span>
                                ) : null}
                              </div>

                              <div className="drobdown_section mb-4">
                                <label className="primary_label">
                                  Starting date
                                </label>
                                <div className="dropdown">
                                  <button
                                    class="btn btn-secondary dropdown-toggle w-100"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <span id="menuBut">{StartDate}</span>
                                  </button>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <div
                                      id="RightAfterListing"
                                      onClick={() => {
                                        DateChange(
                                          "StartDateDropDown",
                                          "RightAfterListing"
                                        );
                                      }}
                                    >
                                      Right after listing
                                    </div>
                                    <div
                                      id="PickStart"
                                      onClick={() => {
                                        DateChange(
                                          "StartDateDropDown",
                                          "PickStartDate"
                                        );
                                      }}
                                    >
                                      Pick specific date
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="drobdown_section mb-4">
                                <label className="primary_label">
                                  Expiration date
                                </label>
                                <div class="dropdown">
                                  <button
                                    class="btn btn-secondary dropdown-toggle filter_btn inp_btn m-0 text-left w-100"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <span id="menuBut">{EndDate}</span>
                                  </button>
                                  <div
                                    class="dropdown-menu filter_menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <div
                                      onClick={() => {
                                        DateChange("EndDateDropDown", "1 Day");
                                      }}
                                    >
                                      1 Day
                                    </div>
                                    <div
                                      onClick={() => {
                                        DateChange("EndDateDropDown", "3 Day");
                                      }}
                                    >
                                      3 Day
                                    </div>
                                    <div
                                      onClick={() => {
                                        DateChange("EndDateDropDown", "5 Day");
                                      }}
                                    >
                                      5 Day
                                    </div>
                                    <div
                                      onClick={() => {
                                        DateChange("EndDateDropDown", "7 Day");
                                      }}
                                    >
                                      7 Day
                                    </div>
                                    <div
                                      onClick={() => {
                                        DateChange(
                                          "EndDateDropDown",
                                          "PickEndDate"
                                        );
                                      }}
                                    >
                                      Pick specific date
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Royalties<span className="star">*</span>
                            </label>
                            <div className="input-group">
                              <Field
                                type="number"
                                className="form-control"
                                placeholder="Suggested 5% (Max 40%)"
                                name="nftRoyalty"
                                onKeyDown={(evt) =>
                                  ["e", "E", "+", "-", ",", " ", "."].includes(
                                    evt.key
                                  ) && evt.preventDefault()
                                }
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                %
                              </span>
                            </div>
                            {errors.nftRoyalty && touched.nftRoyalty ? (
                              <span className="error-msg">
                                {errors.nftRoyalty}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Category<span className="star">*</span>
                            </label>
                            <div className="input-group">
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="10"
                                name="categorySelect"
                                as="select"
                              >
                                <option value="">Select Category</option>
                                {categoryList.map((ele) => {
                                  return (
                                    <option value={ele.Category}>
                                      {ele.Category}
                                    </option>
                                  );
                                })}
                              </Field>
                            </div>
                            {errors.categorySelect && touched.categorySelect ? (
                              <span className="error-msg">
                                {errors.categorySelect}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <div className="form-check form-switch">
                              <p>
                                Make it available for swap
                                <span
                                  class="d-inline-block"
                                  tabindex="0"
                                  data-bs-toggle="tooltip"
                                  title="Make it available for swap"
                                >
                                  <img
                                    src={info}
                                    alt="img"
                                    className="ms-2 img-fluid"
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </p>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="Swap"
                                role="switch"
                                onChange={CheckedChange}
                                id="Swap"
                              />
                            </div>
                          </div>
                          <div className="mb-4">
                            <div className="form-check form-switch">
                              <p>
                                Unlock content after successful purchase
                                <span
                                  class="d-inline-block"
                                  tabindex="0"
                                  data-bs-toggle="tooltip"
                                  title="Unlock content after successful purchase"
                                >
                                  <img
                                    src={info}
                                    alt="img"
                                    className="ms-2 img-fluid"
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </p>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="unlockoncepurchased"
                                name="unlockoncepurchased"
                                onChange={CheckedChange}
                              />
                            </div>
                          </div>
                          {Unlockoncepurchased && (
                            <div className="mb-4">
                              {/* <label
                                for="exampleInputEmail1"
                                className="form-label"
                              >
                                Name<span className="star">*</span>
                              </label> */}
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="Provide a details unlock content"
                                name="unlockContent"
                              />
                              {/* {errors.unlockContent && touched.unlockContent ? (
                                <span className="error-msg">
                                  {errors.unlockContent}
                                </span>
                              ) : null} */}
                            </div>
                          )}
                          {/* <div className="mb-4">
                            <div className="form-check form-switch">
                              <p>
                                Free minting
                                <img
                                  src={info}
                                  alt="img"
                                  className="ms-2 img-fluid"
                                />
                              </p>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="freeMinting"
                                name="freeMinting"
                                onChange={CheckedChange}
                              />
                            </div>
                          </div> */}
                          <div className="mb-4">
                            <div className="form-check form-switch">
                              <p>
                                {" "}
                                <span> Explicit and sensitive content</span>
                                <span
                                  class="d-inline-block"
                                  tabindex="0"
                                  data-bs-toggle="tooltip"
                                  title="Explicit and sensitive content"
                                >
                                  <img
                                    src={info}
                                    alt="img"
                                    className="ms-2 img-fluid"
                                    style={{ cursor: "pointer" }}
                                  />
                                </span>
                              </p>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="sensitiveContent"
                                name="sensitiveContent"
                                onChange={CheckedChange}
                              />
                            </div>
                          </div>
                          {localStorage.getItem("nicholousWallAddr") ? (
                            <button
                              className="primary_btn dark w-100 "
                              type="submit"
                              disabled={isSubmitting}
                            >
                              Create item
                              <img
                                src={btnarw_white}
                                alt="img"
                                className="ms-3 img-fluid"
                              />
                            </button>
                          ) : (
                            <button
                              className="primary_btn dark w-100 "
                              // type="submit"
                              disabled={true}
                            >
                              Please connect wallet
                              <img
                                src={btnarw_white}
                                alt="img"
                                className="ms-3 img-fluid"
                              />
                            </button>
                          )}
                        </Form>
                      </div>
                      <div className="col-lg-6 prv_outbox popular_nft bg-transparent">
                        <p className="para mb-4">Preview</p>
                        {TokenFilePreReader ||
                        values.nftName ||
                        values.nftPrice ||
                        TokenFilePreUrl.split(".").pop() == "mp4" ? (
                          <div className="box">
                            <div className="img_box">
                              {TokenFilePreReader &&
                              TokenFilePreUrl.split(".").pop() == "mp4" ? (
                                <video
                                  src={TokenFilePreReader}
                                  style={{ width: "470px" }}
                                  type="video/mp4"
                                  id="get_file_2"
                                  class="lazy nft__item_preview"
                                  alt=""
                                  autoPlay
                                  playsInLine
                                  loop
                                  muted
                                ></video>
                              ) : TokenFilePreUrl.split(".").pop() == "mp3" ? (
                                <div style={{ textAlign: "center" }}>
                                  <img
                                    style={{
                                      padding: "16px",
                                      width: "256px",
                                      height: "256px"
                                    }}
                                    src={
                                      config.baseUrl + "/" + "images/music.png"
                                    }
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  <audio
                                    src={TokenFilePreReader}
                                    type="audio/mp3"
                                    controls
                                    controlsList="nodownload"
                                    className="audio home"
                                  ></audio>
                                </div>
                              ) : (
                                <img
                                  style={{ padding: "16px" }}
                                  src={TokenFilePreReader}
                                  alt="img"
                                  className="img-fluid"
                                />
                              )}
                            </div>
                            <div className="blw_box">
                              <h5>{values.nftName}</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <span>Price</span>
                                  <h6>
                                    {Numberconvert(values.nftPrice)} MATIC
                                  </h6>
                                </div>
                                {/* <div><span>Highest bid</span><p>4.56 MATIC</p></div> */}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="prv_box">
                            <img
                              src={require("../assets/images/prv.png")}
                              alt="img"
                              className="img-fluid"
                            />
                          </div>
                        )}

                        {/* prev with data  */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="wizard-progress">
              <div className="step complete">
                <div>
                  <div className="node"></div> Choose blockchain
                </div>
              </div>
              <div className="step complete">
                Choose Type
                <div className="node"></div>
              </div>
              <div className="step">
                Create NFT
                <div className="node"></div>
              </div>
            </div>
          </div>
        )}
      </Formik>

      {/* DataModals */}
      <div
        class="modal fade primary_modal"
        id="calendar_modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="calendar_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-module">
              <div class="modal-header text-center">
                <h5 class="modal-title" id="calendar_modalLabel">
                  Start date
                </h5>
                {/* <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="cancelcalender"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div class="modal-body">
                <div className="pb-3">
                  <Datetime
                    minDate={new Date().getTime()}
                    input={false}
                    value={Clocktime}
                    onChange={(value) => handlesetClocktime(value)}
                  />
                </div>
              </div>
              <div class="text-center mb-3">
                <input
                  type="submit"
                  name="Done"
                  value="Done"
                  className="primary_btn"
                  id="doneStartDate"
                  disabled={startTimeErr ? true : false}
                  onClick={() => ModalAction("calendar_modal", "hide")}
                />
                {startTimeErr && (
                  <span className="error-msg">{startTimeErr}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade primary_modal"
        id="calendar_modal_expire"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="calendar_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-module">
              <div class="modal-header text-center">
                <h5 class="modal-title" id="calendar_modalLabel">
                  End date
                </h5>
                {/* <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="cancelcalender"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div class="modal-body">
                <div className="pb-3">
                  <Datetime
                    input={false}
                    value={EndClocktime}
                    minDate={new Date().getTime()}
                    onChange={(value) => handleendClocktime(value)}
                  />
                </div>
              </div>
              <div class="text-center mb-3">
                <input
                  type="submit"
                  name="Done"
                  value="Done"
                  className="primary_btn"
                  id="doneEndDate"
                  disabled={endTimeErr ? true : false}
                  onClick={() => ModalAction("calendar_modal_expire", "hide")}
                />
                {endTimeErr && <span className="error-msg">{endTimeErr}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>

      {loader && (
        <div className="loadBefore">
          <HashLoader
            color={"#9a55ff"}
            loading={loader}
            cssOverride={override}
            size={60}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </div>
  );
}
