import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import verified from "../assets/images/verified.svg";
import plus from "../assets/images/plus.svg";
import polygonicon from "../assets/images/polygon_icon.svg";
import search from "../assets/images/search.svg";
import copy from "../assets/images/copy.svg";
import option from "../assets/images/option.svg";
import twitter from "../assets/images/twitter.svg";
import instagram from "../assets/images/instagram.svg";
import upload from "../assets/images/upload.svg";
import swap_btnarw from "../assets/images/swap_btnarw.svg";
import activities_user from "../assets/images/activities_user.svg";
import activities_arw from "../assets/images/activities_arw.svg";
import msg from "../assets/images/msg.svg";
import { Link } from "react-router-dom";



// Scroll to Top
function ScrollToTopOnMount() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return null;
}

export default function Own_rofile(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  var slides = {
   dots: true,
   arrows:true,
   infinite: true,
   speed: 500,
   slidesToShow: 1,
   slidesToScroll: 1,
  };


  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_box prf_page">
      <div className="prf_ban">
      <img src={require("../assets/images/prf_ban.png")} alt="img" className='img-fluid prf_banimg' /> 
      <div className="container">
        <div className="prf_detail">
          <div className="prf_ban_user">
            <img src={require("../assets/images/ownprf_user.png")} alt="img" className='img-fluid' /> 
          </div>

          <div className="row">
          <div className="col-xl-9">
            <h2 className="h2tag">Naomi</h2>
            <div className="btn_flx nowrap"> 
                <Link to="/profile-setting" className="primary_btn d-block my-2 me-2">Edit profile</Link>
                <div className="d-flex align-items-center" >
                <button className="primary_btn addr  mb-0">
                  <span>Oxcfgcxs_gd34jd</span>
                  <img src={copy} alt="img" className='img-fluid ms-3' /> 
                </button> 
                <div class="dropdown">
                 <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                   <img src={option} alt="img" className='img-fluid' /> 
                 </button>
                 <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a class="dropdown-item" href="#">Report page</a></li> 
                  </ul>
               </div>
               </div>
            </div>
            <p className="para pb-3" >Awarded artist, curator, guest at The Fabricant & Picasso Museum,</p>
            <ul className="social pb-3">
               <li><a href="#" target="_blank"><img src={twitter} alt="img" className='img-fluid' /></a></li>
               <li><a href="#" target="_blank"><img src={instagram} alt="img" className='img-fluid' /></a></li>
               <li><a href="#" target="_blank"><img src={upload} alt="img" className='img-fluid' /></a></li>
            </ul>
          </div>
          <div className="col-xl-3">
          <div className="box_flx">
            <div className="box">
               <p className="para">Followers</p>
               <h4>1.5K</h4>
            </div>
            <div className="box">
               <p className="para">Following</p>
               <h4>230</h4>
            </div>
            </div>
          </div>
          </div>
        </div>
       </div>
      </div>
       <div className="explore">
        <div className="container">
          
        <div className="overflow" >
           <ul className="nav nav-tabs" id="myTab" role="tablist">
             <li className="nav-item" role="presentation">
               <button className="nav-link active ps-0" id="one-tab" data-bs-toggle="tab" data-bs-target="#one" type="button" role="tab" aria-controls="one" aria-selected="true">Created</button>
             </li>
             <li className="nav-item" role="presentation">
               <button className="nav-link" id="two-tab" data-bs-toggle="tab" data-bs-target="#two" type="button" role="tab" aria-controls="two" aria-selected="false">Owned</button>
             </li> 
             <li className="nav-item" role="presentation">
             <button className="nav-link" id="three-tab" data-bs-toggle="tab" data-bs-target="#three" type="button" role="tab" aria-controls="three" aria-selected="false">Collections</button> 
              </li>
              <li className="nav-item" role="presentation">
             <button className="nav-link" id="four-tab" data-bs-toggle="tab" data-bs-target="#four" type="button" role="tab" aria-controls="four" aria-selected="false">Swap history</button> 
              </li>
              <li className="nav-item" role="presentation">
             <button className="nav-link" id="five-tab" data-bs-toggle="tab" data-bs-target="#five" type="button" role="tab" aria-controls="five" aria-selected="false">Open swaps</button> 
              </li>
              <li className="nav-item" role="presentation">
             <button className="nav-link" id="six-tab" data-bs-toggle="tab" data-bs-target="#six" type="button" role="tab" aria-controls="six" aria-selected="false">Activities</button> 
              </li>
           </ul>
           </div>
           
 
           <div className="tab-content">
             <div className="tab-pane fade show active" id="one" role="tabpanel" aria-labelledby="one-tab">  
             <div className="h_option">
                  <div className="flx" >
                  <div className="sel" >
                   <select>
                    <option>All Categories</option>
                     <option>Popular</option> 
                   </select>
                  </div>
                  <div className="icon">
                    <img src={polygonicon} alt="img" className='img-fluid user' /> 
                  </div>
                  </div>
               </div>
             <section className="popular_nft bg-transparent"> 
             <div className="row flex-wrap">
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
             </div> 
              </section>
              </div>
             <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab"> 
             <div className="h_option">
                  <div className="flx" >
                  <div className="sel" >
                   <select>
                    <option>All Categories</option>
                     <option>Popular</option> 
                   </select>
                  </div>
                  <div className="icon">
                    <img src={polygonicon} alt="img" className='img-fluid user' /> 
                  </div>
                  </div>
               </div>
             <section className="popular_nft bg-transparent"> 
             <div className="row flex-wrap">
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>#800</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       </div>
                   </div>
                </div>
             </div> 
              </section>
              </div>
             <div className="tab-pane fade" id="three" role="tabpanel" aria-labelledby="three-tab"> 
             <div className="h_option">
                  <div className="flx">
                  <div className="sel" >
                   <select>
                    <option>All Categories</option>
                     <option>Trending</option> 
                   </select>
                  </div>
                  <div className="icon">
                    <img src={polygonicon} alt="img" className='img-fluid user' /> 
                  </div>
                  </div> 
               </div>
             <section className="recent_swap pt-4 mt-3" > 
                 <div class="table-responsive">
                     <table class="table table-primary">
                        <thead>
                           <tr>
                              <th>#</th>
                              <th>COLLECTION</th>
                              <th>VOLUME</th>
                              <th>% CHANGE</th>
                              <th>FLOOR PRICE</th>
                              <th>% CHANGE</th>
                              <th>ITEMS</th>
                              <th>OWNERS</th>
                              <th>SALES</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td>1</td>
                              <td>
                                 <div className="flx">
                                   <img src={require("../assets/images/prf.png")} alt="img" className='img-fluid user_mini' /> 
                                    <span>Gorilla DJ Party</span>
                                 </div>
                              </td>
                              <td>4,239 MATIC</td>
                              <td className="red">-5.4%</td>
                              <td>7.33 MATIC</td> 
                              <td  className="red">-12.6%</td> 
                              <td>10K</td> 
                              <td>4.6K</td> 
                              <td>31</td> 
                           </tr>
                           <tr> 
                              <td>2</td>
                              <td>
                                 <div className="flx">
                                   <img src={require("../assets/images/prf.png")} alt="img" className='img-fluid user_mini' /> 
                                    <span>Gorilla DJ Party</span>
                                 </div>
                              </td>
                              <td>4,239 MATIC</td>
                              <td className="green">-5.4%</td>
                              <td>7.33 MATIC</td> 
                              <td  className="green">-12.6%</td> 
                              <td>10K</td> 
                              <td>4.6K</td> 
                              <td>31</td> 
                           </tr>
                           <tr>
                              <td>3</td>
                              <td>
                                 <div className="flx">
                                   <img src={require("../assets/images/prf.png")} alt="img" className='img-fluid user_mini' /> 
                                    <span>Gorilla DJ Party</span>
                                 </div>
                              </td>
                              <td>4,239 MATIC</td>
                              <td className="red">-5.4%</td>
                              <td>7.33 MATIC</td> 
                              <td  className="red">-12.6%</td> 
                              <td>10K</td> 
                              <td>4.6K</td> 
                              <td>31</td> 
                           </tr>
                           <tr>
                              <td>4</td>
                              <td>
                                 <div className="flx">
                                   <img src={require("../assets/images/prf.png")} alt="img" className='img-fluid user_mini' /> 
                                    <span>Gorilla DJ Party</span>
                                 </div>
                              </td>
                              <td>4,239 MATIC</td>
                              <td className="green">-5.4%</td>
                              <td>7.33 MATIC</td> 
                              <td  className="green">-12.6%</td> 
                              <td>10K</td> 
                              <td>4.6K</td> 
                              <td>31</td> 
                           </tr>
                           <tr>
                              <td>5</td>
                              <td>
                                 <div className="flx">
                                   <img src={require("../assets/images/prf.png")} alt="img" className='img-fluid user_mini' /> 
                                    <span>Gorilla DJ Party</span>
                                 </div>
                              </td>
                              <td>4,239 MATIC</td>
                              <td className="red">-5.4%</td>
                              <td>7.33 MATIC</td> 
                              <td  className="red">-12.6%</td> 
                              <td>10K</td> 
                              <td>4.6K</td> 
                              <td>31</td> 
                           </tr> 
                        </tbody>
                     </table>
                  </div>
             </section>
             </div>
             <div className="tab-pane fade" id="four" role="tabpanel" aria-labelledby="four-tab"> 
             <div className="h_option">
                  <div className="flx">
                  <div className="sel" >
                   <select>
                    <option>All Categories</option>
                     <option>Trending</option> 
                   </select>
                  </div>
                  <div className="icon">
                    <img src={polygonicon} alt="img" className='img-fluid user' /> 
                  </div>
                  </div> 
               </div>
             <section className="recent_swap recent_swap_t pt-4 mt-3" > 
                 <div class="table-responsive">
                     <table class="table table-primary">
                        <thead>
                           <tr>
                              <th  className="ps-5">#</th>
                              <th>PARTY A</th>
                              <th>ITEM A</th>
                              <th>STATUS</th> 
                              <th>PARTY B</th>
                              <th>ITEM B</th>
                           </tr>
                        </thead>
                        <tbody>
                           <tr>
                              <td className="ps-5" >1</td>
                              <td>
                                 <div className="flx">
                                    <img src={require("../assets/images/usr_prf.png")} alt="img" className='img-fluid user' /> 
                                     <span>MGalaxyworld</span>
                                 </div>
                              </td>
                              <td >The Glowing Lady - #800</td>
                              <td><button class="completed">Completed</button></td> 
                              <td  className="pe-5">
                                 <div className="flx">
                                 <img src={require("../assets/images/usr_prf.png")} alt="img" className='img-fluid user' /> 
                                 <span>MGalaxyworld</span>
                                 </div>
                              </td> 
                              <td>The Glowing Lady - #800</td> 
                           </tr>
                           <tr>
                              <td className="ps-5" >2</td>
                              <td>
                                 <div className="flx">
                                    <img src={require("../assets/images/usr_prf.png")} alt="img" className='img-fluid user' /> 
                                     <span>MGalaxyworld</span>
                                 </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                              <td><button class="completed">Completed</button></td> 
                              <td>
                                 <div className="flx">
                                 <img src={require("../assets/images/usr_prf.png")} alt="img" className='img-fluid user' /> 
                                 <span>MGalaxyworld</span>
                                 </div>
                              </td> 
                              <td>The Glowing Lady - #800</td> 
                           </tr>
                           <tr>
                              <td className="ps-5" >3</td>
                              <td>
                                 <div className="flx">
                                    <img src={require("../assets/images/usr_prf.png")} alt="img" className='img-fluid user' /> 
                                     <span>MGalaxyworld</span>
                                 </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                              <td><button class="completed">Completed</button></td> 
                              <td>
                                 <div className="flx">
                                 <img src={require("../assets/images/usr_prf.png")} alt="img" className='img-fluid user' /> 
                                 <span>MGalaxyworld</span>
                                 </div>
                              </td> 
                              <td>The Glowing Lady - #800</td> 
                           </tr>
                           <tr>
                              <td className="ps-5" >4</td>
                              <td>
                                 <div className="flx">
                                    <img src={require("../assets/images/usr_prf.png")} alt="img" className='img-fluid user' /> 
                                     <span>MGalaxyworld</span>
                                 </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                              <td><button class="completed">Completed</button></td> 
                              <td>
                                 <div className="flx">
                                 <img src={require("../assets/images/usr_prf.png")} alt="img" className='img-fluid user' /> 
                                 <span>MGalaxyworld</span>
                                 </div>
                              </td> 
                              <td>The Glowing Lady - #800</td> 
                           </tr>
                           <tr>
                              <td className="ps-5" >5</td>
                              <td>
                                 <div className="flx">
                                    <img src={require("../assets/images/usr_prf.png")} alt="img" className='img-fluid user' /> 
                                     <span>MGalaxyworld</span>
                                 </div>
                              </td>
                              <td>The Glowing Lady - #800</td>
                              <td><button class="completed">Completed</button></td> 
                              <td>
                                 <div className="flx">
                                 <img src={require("../assets/images/usr_prf.png")} alt="img" className='img-fluid user' /> 
                                 <span>MGalaxyworld</span>
                                 </div>
                              </td> 
                              <td>The Glowing Lady - #800</td> 
                           </tr>
                        </tbody>
                     </table>
                  </div>
             </section>
             </div> 
             <div className="tab-pane fade" id="five" role="tabpanel" aria-labelledby="five-tab"> 
              
             <section className="popular_nft bg-transparent"> 
             <div className="row flex-wrap">
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>
                <div className="col-lg-3">
                   <div className="box">
                       <div className="img_box">
                          <img src={require("../assets/images/p1.png")} alt="img" className='img-fluid' /> 
                       </div>
                       <div className="blw_box" >
                       <h5>Gorilla  DJ  Party</h5>
                       <div className="flx_ctn" >
                          <div className="mb-3" ><span>Price</span><h6>15.97 MATIC</h6></div>
                          <div><span>Last Sale</span><p>4.56 MATIC</p></div>
                       </div>
                       <button className="primary_btn w-100">
                          <span>Swap</span>
                          <img src={swap_btnarw} alt="img" className='img-fluid ms-3' /> 
                       </button>
                       </div>
                   </div>
                </div>  
             </div> 
              </section>
              </div>
              <div className="tab-pane fade" id="six" role="tabpanel" aria-labelledby="six-tab"> 
              <section className="activities"> 
              <div className="outbox_flx">
               <div className="box_flx">
                  <div className="img_box">
                   <img src={activities_user} alt="img" className='img-fluid' /> 
                  </div>
                  <div>
                     <p className="para mb-0" >Gorilla DJ Party #200</p>  
                     <span className="d-block mb-3 " >1 edition minted by <span className="name" > Djaina</span></span>
                     <span>6/30/2023, 11:27 PM</span>
                  </div>
               </div>
               <img src={activities_arw} alt="img" className='img-fluid' /> 
               </div>
               <div className="outbox_flx">
               <div className="box_flx">
                  <div className="img_box">
                   <img src={activities_user} alt="img" className='img-fluid' /> 
                  </div>
                  <div>
                     <p className="para mb-0" >Gorilla DJ Party #200</p>  
                     <span className="d-block mb-3 " >1 edition minted by <span className="name" > Djaina</span></span>
                     <span>6/30/2023, 11:27 PM</span>
                  </div>
               </div>
               <img src={activities_arw} alt="img" className='img-fluid' /> 
               </div>
               <div className="outbox_flx">
               <div className="box_flx">
                  <div className="img_box">
                   <img src={activities_user} alt="img" className='img-fluid' /> 
                  </div>
                  <div>
                     <p className="para mb-0" >Gorilla DJ Party #200</p>  
                     <span className="d-block mb-3 " >1 edition minted by <span className="name" > Djaina</span></span>
                     <span>6/30/2023, 11:27 PM</span>
                  </div>
               </div>
               <img src={activities_arw} alt="img" className='img-fluid' /> 
               </div>
               <div className="outbox_flx">
               <div className="box_flx">
                  <div className="img_box">
                   <img src={activities_user} alt="img" className='img-fluid' /> 
                  </div>
                  <div>
                     <p className="para mb-0" >Gorilla DJ Party #200</p>  
                     <span className="d-block mb-3 " >1 edition minted by <span className="name" > Djaina</span></span>
                     <span>6/30/2023, 11:27 PM</span>
                  </div>
               </div>
               <img src={activities_arw} alt="img" className='img-fluid' /> 
               </div>
              </section>
              </div>
           </div>
        </div>
       </div>
      </div> 
 

       <Footer />
    </div>
  );
}
