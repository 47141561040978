import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import polygonicon from "../assets/images/polygon_icon.svg";
import option from "../assets/images/option.svg";
import twitter from "../assets/images/twitter.svg";
import instagram from "../assets/images/instagram.svg";
import upload from "../assets/images/upload.svg";
import activities_user from "../assets/images/activities_user.svg";
import facebook from "../assets/images/facebook.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import activities_arw from "../assets/images/activities_arw.svg";
import { toastAlert } from "../lib/toastAlert.js";
import {
  getCollectionNFTs,
  getCollectionByAddr,
  getNftHis2,
  checkcollectionreport
} from "../actions/nfts";
import $ from "jquery";
import { SetReportpage } from "../actions/users";
import moment from "moment";
import Countdown from "react-countdown";
import config from "../lib/config";
import { halfAddrShow } from "../helper/halfAddr.js";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import search from "../assets/images/search.svg";
import { Numberconvert } from "../helper/numberConvert.js";
import { useSelector } from "react-redux";
// import walletConnection from "../reducers/walletConnection.js";
// Scroll to Top
function ScrollToTopOnMount() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return null;
}

const ReportPageValidate = Yup.object().shape({
  reporttitle: Yup.string()
    .required("Required")
    .test("value", "Required", (value) => value.trim().length != 0)
    .matches(
      /^([a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only normal character"
    ),
  reportcontent: Yup.string()
    .test("value", "Required", (value) => value.trim().length != 0)
    .required("Required")
  // .matches(
  //   /^([a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
  //   "Insert only normal character"
  // )
});

export default function Collection(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const { conAddr } = useParams();
  const [collDetails, setCollDetails] = useState([]);
  const [collNFTs, setCollNFTs] = useState([]);
  const [detailsColl, setDetailsColl] = useState([]);
  const [NFTHis, setNFTHis] = useState([]);
  const [Search, setSearch] = useState("");
  const getUsers = useSelector((state) => state.getUsers);
  const Reportmodelref = useRef(null);
  const [disabledbtn, setdisabledbtn] = useState(false);
  const navigate = useNavigate();
  const renderer1 = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <span></span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s to start
        </span>
      );
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <span>Ended</span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    }
  };

  useEffect(() => {
    getCollectionNFT();
    getCollDetails();
    getNFTActivities();
  }, [Search]);

  const getCollDetails = async () => {
    const payload = {
      conAddr
    };
    console.log(payload, "payload");
    const colldetails = await getCollectionByAddr(payload);
    console.log(colldetails, "colldetails");
    if (colldetails?.data?.status) {
      setCollDetails(colldetails?.data?.data);
      setDetailsColl(colldetails?.data?.data1);
    } else {
      toastAlert("error", "Collection Banned by Admin");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  };
  const getCollectionNFT = async () => {
    const payload = {
      conAddr,
      search: Search
    };
    const collNfts = await getCollectionNFTs(payload);
    console.log(collNfts, "collNfts");
    setCollNFTs(collNfts?.data?.data);
  };

  const getNFTActivities = async () => {
    const payload = {
      conAddr
    };
    const history = await getNftHis2(payload);
    console.log(history, "history2");
    setNFTHis(history?.data?.data);
  };
  const checkReportCollection = async () => {
    const payload = {
      conAddr,
      address: getUsers.curraddress
    };
    const result = await checkcollectionreport(payload);
    console.log(result, "resultresultresultresult11111111111111111111");
    if (result.status) {
      toastAlert("error", result.message);
    } else {
      // Reportmodelref.current.focus()
      window.$("#" + "Reportconfirm").modal("show");
    }
  };
  console.log(getUsers, "getUsersgetUsersgetUsers");

  return (
    <div>
      <ScrollToTopOnMount />
      <div className="whitenav">
        <Navbar />
      </div>
      <div className="page_box prf_page">
        <div className="prf_ban prf_ban_collect">
          <img
            src="https://nicholasnft-frontend.pages.dev/static/media/prf_ban.087e627bdbe72fbd583a.png"
            style={{ objectFit: "cover" }}
            alt="img"
            className="img-fluid prf_banimg"
          />
          <div className="container">
            <div className="prf_detail">
              <div className="prf_ban_user">
                <img
                  src={
                    config.baseUrl +
                    "/collections/" +
                    collDetails?.[0]?.bannerImage
                  }
                  alt="img"
                  className="img-fluid"
                />
              </div>

              <div className="row">
                <div className="col-xl-5">
                  <h2 className="h2tag mb-1">{collDetails?.[0]?.name}</h2>
                  <p className="para mb-4">
                    By{" "}
                    {collDetails?.[0]?.CollUser?.[0]?.username
                      ? collDetails?.[0]?.CollUser?.[0]?.username
                      : halfAddrShow(
                          collDetails?.[0]?.CollUser?.[0]?.curraddress
                        )}
                  </p>
                  <p className="para">{collDetails?.[0]?.desc}</p>
                  <div className="btn_flx mt-3 flex-row">
                    <ul className="social mb-0">
                      <li>
                        <a
                          class="dropdown-item"
                          href={`https://www.facebook.com/sharer/sharer.php?u=${config.frontUrl}/collection/${conAddr}`}
                          target="_blank"
                        >
                          <img src={facebook} alt="img" className="img-fluid" />
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href={`https://twitter.com/share?url=${config.frontUrl}/collection/${conAddr}`}
                          target="_blank"
                        >
                          <img src={twitter} alt="img" className="img-fluid" />
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href={`http://pinterest.com/pin/create/button/?url=${config.frontUrl}/collection/${conAddr}`}
                          target="_blank"
                        >
                          <img
                            src={instagram}
                            alt="img"
                            className="img-fluid"
                          />
                        </a>
                      </li>
                      {/* <li>
                        <a href="#" target="_blank">
                          <img src={upload} alt="img" className="img-fluid" />
                        </a>
                      </li> */}
                    </ul>

                    {walletConnection.address == collDetails?.[0]?.owneraddr ? (
                      <></>
                    ) : (
                      <>
                        <div class="dropdown">
                          <button
                            class="btn btn-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <img src={option} alt="img" className="img-fluid" />
                          </button>
                          <ul
                            class="dropdown-menu"
                            aria-labelledby="dropdownMenuButton1"
                          >
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => checkReportCollection()}
                                // data-bs-toggle="modal"
                                // data-bs-target="#Reportconfirm"
                              >
                                Report Collection
                              </a>
                            </li>
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="col-xl-7">
                  <div className="box_flx">
                    <div className="box">
                      <p className="para">Floor price</p>
                      <h4>
                        {detailsColl[0]?.floorPrice[0]?.price
                          ? detailsColl[0]?.floorPrice[0]?.price
                          : 0}{" "}
                        MATIC
                      </h4>
                    </div>
                    <div className="box">
                      <p className="para">Total Volume</p>
                      <h4>
                        {detailsColl[0]?.totalVol[0]?.volume
                          ? detailsColl[0]?.totalVol[0]?.volume.toFixed(3)
                          : 0}{" "}
                        MATIC
                      </h4>
                    </div>
                    <div className="box">
                      <p className="para">Items</p>
                      <h4>{detailsColl[0]?.itemCnt.length}</h4>
                    </div>
                    <div className="box">
                      <p className="para">Owners</p>
                      <h4>{detailsColl[0]?.itemCnt.length}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="explore">
          <div className="container">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active ps-0"
                  id="one-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#one"
                  type="button"
                  role="tab"
                  aria-controls="one"
                  aria-selected="true"
                >
                  Items
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="two-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#two"
                  type="button"
                  role="tab"
                  aria-controls="two"
                  aria-selected="false"
                >
                  Activity
                </button>
              </li>
            </ul>

            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="one"
                role="tabpanel"
                aria-labelledby="one-tab"
              >
                <div className="h_option">
                  <div className="flx">
                    {/* <div className="sel">
                      <select>
                        <option>All Categories</option>
                        <option>Popular</option>
                      </select>
                    </div>
                    <div className="icon">
                      <img
                        src={polygonicon}
                        alt="img"
                        className="img-fluid user"
                      />
                    </div> */}
                    {/* mob_hide */}
                    <div className="input-group sr_ch m-0">
                      <span className="input-group-text">
                        <img src={search} alt="img" className="img-fluid" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        id="floatingInputGroup1"
                        placeholder="Search by collection name"
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <section className="popular_nft bg-transparent">
                  <div className="row flex-wrap">
                    {collNFTs.length > 0 &&
                      collNFTs.map((nft) => {
                        let currDate = new Date();
                        let startdate = new Date(nft.startTime);
                        let enddate = new Date(nft.endTime);
                        let auction = "false";
                        let finish = "";
                        let enddate1 = "";
                        let showlist = "true";
                        let display_item = 0;
                        if (nft.PutOnSaleType == "TimedAuction") {
                          auction = "true";
                          let a = moment(nft.startTime);
                          let b = moment(nft.endTime);
                          let c = moment();
                          a.diff(b); // 86400000
                          let diffInMs = a.diff(c);
                          display_item = a.diff(c);
                          finish = b.diff(c);
                          enddate1 = parseFloat(diffInMs);
                          if (finish > 0) {
                            showlist = "true";
                          } else {
                            var auctionTxt = "Ended";
                            showlist = "false";
                          }
                        }
                        return (
                          <div className="col-lg-3">
                            <Link
                              to={`/nft-detail/${nft.contractAddress}/${nft.nftId}`}
                            >
                              <div className="box">
                                {nft.PutOnSaleType == "TimedAuction" &&
                                  showlist == "true" &&
                                  display_item <= 0 && (
                                    <div className="de_countdown">
                                      <Countdown
                                        date={enddate}
                                        autoStart={true}
                                        onStart={() => startdate}
                                        renderer={renderer}
                                      />
                                    </div>
                                  )}
                                {nft.PutOnSaleType == "TimedAuction" &&
                                  showlist == "true" &&
                                  display_item > 0 && (
                                    <div className="de_countdown">
                                      <Countdown
                                        date={startdate}
                                        autoStart={true}
                                        onStart={() => Date.now()}
                                        renderer={renderer1}
                                      />
                                    </div>
                                  )}
                                <div className="img_box">
                                  {nft.localImage.split(".").pop() == "mp4" ? (
                                    <>
                                      {nft.isCollNft ? (
                                        <video
                                          src={`${config.baseUrl}/${nft.localImage}`}
                                          style={{ width: "100%" }}
                                          type="video/mp4"
                                          alt="Collections"
                                          className="lazy nft__item_preview nft__item_preview_video"
                                          controls
                                          controlsList="nodownload"
                                        />
                                      ) : (
                                        <video
                                          src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                          style={{ width: "100%" }}
                                          type="video/mp4"
                                          alt="Collections"
                                          className={
                                            nft.sensitiveContent
                                              ? "lazy nft__item_preview nft__item_preview_video blue_img"
                                              : "lazy nft__item_preview nft__item_preview_video"
                                          }
                                          // className="lazy nft__item_preview nft__item_preview_video"
                                          controls
                                          controlsList="nodownload"
                                        />
                                      )}
                                    </>
                                  ) : nft.localImage.split(".").pop() ==
                                    "mp3" ? (
                                    <>
                                      {nft.isCollNft ? (
                                        <div style={{ textAlign: "center" }}>
                                          <img
                                            style={{
                                              padding: "16px",
                                              width: "256px",
                                              height: "256px"
                                            }}
                                            src={
                                              config.baseUrl +
                                              "/" +
                                              "images/music.png"
                                            }
                                            alt="img"
                                            className="img-fluid"
                                          />
                                          <audio
                                            src={`${config.baseUrl}/${nft.localImage}`}
                                            type="audio/mp3"
                                            controls
                                            controlsList="nodownload"
                                            className="audio home"
                                          ></audio>
                                        </div>
                                      ) : (
                                        <div style={{ textAlign: "center" }}>
                                          <img
                                            style={{
                                              padding: "16px",
                                              width: "256px",
                                              height: "256px"
                                            }}
                                            src={
                                              config.baseUrl +
                                              "/" +
                                              "images/music.png"
                                            }
                                            alt="img"
                                            className="img-fluid"
                                          />
                                          <audio
                                            src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                            type="audio/mp3"
                                            controls
                                            controlsList="nodownload"
                                            className="audio home"
                                          ></audio>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {nft.isCollNft ? (
                                        <img
                                          src={`${config.baseUrl}/${nft.localImage}`}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                          alt="img"
                                          // className="img-fluid"
                                          className={
                                            nft.sensitiveContent
                                              ? "img-fluid blue_img"
                                              : "img-fluid"
                                          }
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                                <div className="blw_box">
                                  <h5>{nft.nftName}</h5>
                                  <div className="flx_ctn">
                                    <div className="mb-3">
                                      <span>Price</span>
                                      <h6>{Numberconvert(nft.price)} MATIC</h6>
                                    </div>
                                    <div>
                                      <span>Last Sale</span>
                                      <p>
                                        {nft.result
                                          ? Numberconvert(nft.result.amount)
                                          : "Not yet"}
                                      </p>
                                      {/* <p>4.56 MATIC</p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </section>
              </div>
              <div
                className="tab-pane fade"
                id="two"
                role="tabpanel"
                aria-labelledby="two-tab"
              >
                <section className="activities">
                  {NFTHis.length > 0 ? (
                    NFTHis.map((history) => {
                      return (
                        <div className="outbox_flx">
                          <div className="box_flx">
                            <div className="img_box">
                              {history.image.split(".").pop() == "mp4" ? (
                                <video
                                  src={history.ipfsimage}
                                  style={{ width: "103px" }}
                                  type="video/mp4"
                                  alt="Collections"
                                  className="lazy nft__item_preview nft__item_preview_video"
                                  controls
                                  controlsList="nodownload"
                                />
                              ) : history.image.split(".").pop() == "mp3" ? (
                                <audio
                                  src={history.ipfsimage}
                                  style={{ width: "103px" }}
                                  type="audio/mp3"
                                  controls
                                  controlsList="nodownload"
                                  className="audio home"
                                ></audio>
                              ) : (
                                <img
                                  src={history.ipfsimage}
                                  alt="img"
                                  className="img-fluid"
                                />
                              )}
                            </div>
                            <div>
                              <p className="para mb-0">
                                {history?.actiontype == 0
                                  ? "Mint NFT"
                                  : history?.actiontype == 1
                                  ? "Listing"
                                  : history?.actiontype == 2
                                  ? "Price Change"
                                  : history?.actiontype == 3
                                  ? "Place Bid"
                                  : history?.actiontype == 4
                                  ? "Edit Bid"
                                  : history?.actiontype == 5
                                  ? "Cancel Bid"
                                  : history?.actiontype == 6
                                  ? "Transfer NFT"
                                  : history?.actiontype == 7
                                  ? "Burn NFT"
                                  : history?.actiontype == 8
                                  ? "Accept Bid"
                                  : history?.actiontype == 9
                                  ? "Purchase NFT"
                                  : history?.actiontype == 10
                                  ? "Cancel Sale"
                                  : history?.actiontype == 11
                                  ? "Bid Expired"
                                  : history?.actiontype == 12
                                  ? "Listing (Timed Auction)"
                                  : history?.actiontype == 13
                                  ? "Cancel (Timed Auction)"
                                  : ""}
                              </p>
                              {history?.actiontype == 0 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT minted by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 1 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT listing{" "}
                                  {Numberconvert(history?.amount)}{" "}
                                  {config.Currency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 2 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT price changed{" "}
                                  {Numberconvert(history?.amount)}{" "}
                                  {config.Currency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 3 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT bid placed{" "}
                                  {Numberconvert(history?.amount)}{" "}
                                  {config.TokenCurrency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 4 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT bid edited{" "}
                                  {Numberconvert(history?.amount)}{" "}
                                  {config.TokenCurrency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 5 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT bid cancelled{" "}
                                  {Numberconvert(history?.amount)}{" "}
                                  {config.TokenCurrency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 6 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT transfer by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)} to{" "}
                                    {halfAddrShow(history?.touseraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 7 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT burned by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 8 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT bid accept{" "}
                                  {Numberconvert(history?.amount)}{" "}
                                  {config.TokenCurrency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 9 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT purchased{" "}
                                  {Numberconvert(history?.amount)}{" "}
                                  {config.Currency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 10 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT sale cancelled{" "}
                                  {Numberconvert(history?.amount)}{" "}
                                  {config.Currency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 11 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT bid expired{" "}
                                  {Numberconvert(history?.amount)}{" "}
                                  {config.TokenCurrency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 12 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT listing (Timed
                                  Auction) {Numberconvert(history?.amount)}{" "}
                                  {config.TokenCurrency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : history?.actiontype == 13 ? (
                                <span className="d-block mb-3 mt-1">
                                  1 {history?.nftName} NFT cancel Timed Auction{" "}
                                  {Numberconvert(history?.amount)}{" "}
                                  {config.TokenCurrency} by{" "}
                                  <span className="name">
                                    {" "}
                                    {halfAddrShow(history?.useraddress)}
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                              {/* <span className="d-block mb-3 ">
                                1 {history?.nftName} minted by{" "}
                                <span className="name">
                                  {" "}
                                  {halfAddrShow(history?.useraddress)}
                                </span>
                              </span> */}
                              <span>
                                {moment(history.createdAt).format("lll")}
                              </span>
                            </div>
                          </div>
                          {history?.trasHash ? (
                            <Link
                              to={config.Scan + "/" + history?.trasHash}
                              target="_blank"
                            >
                              <img
                                src={activities_arw}
                                alt="img"
                                className="img-fluid"
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div>No Activities yet</div>
                  )}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade modal-md placebid_modal"
        id="Reportconfirm"
        tabIndex={-1}
        aria-labelledby="swapconfirmLabel"
        aria-hidden="true"
        ref={Reportmodelref}
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <h3>Report Page Model</h3>
            <div class="modal-body tabmodal">
              <Formik
                initialValues={{
                  reporttitle: "",
                  reportcontent: ""
                }}
                validationSchema={ReportPageValidate}
                onSubmit={async ({ reporttitle, reportcontent }) => {
                  console.log(reporttitle, reportcontent, "Formik");
                  setdisabledbtn(true);
                  const payload = {
                    collectionAddress: conAddr,
                    reporttitle: reporttitle,
                    reportcontent: reportcontent,
                    currentuser: localStorage.getItem("nicholousWallAddr"),
                    reporttype: "Collection"
                  };
                  console.log(payload);
                  const result = await SetReportpage(payload);
                  if (result.status) {
                    toastAlert("success", result.message);
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  }
                }}
              >
                {({ errors, touched, resetForm }) => (
                  <Form>
                    <div className="row mb-5">
                      <div className="col-md-12 mx-auto">
                        <label for="exampleInputEmail1" className="form-label">
                          Title
                        </label>
                        <Field
                          name="reporttitle"
                          type="text"
                          placeholder="Report title"
                          style={{ width: "100%", padding: "10px" }}
                        />
                        <br />
                        {errors.reporttitle && touched.reporttitle ? (
                          <span className="error-msg">
                            {errors.reporttitle}
                          </span>
                        ) : null}
                      </div>
                      <div
                        className="col-md-12 mx-auto"
                        style={{ marginTop: "10px" }}
                      >
                        <label for="exampleInputEmail1" className="form-label">
                          Content
                        </label>
                        <Field
                          name="reportcontent"
                          type="text"
                          component="textarea"
                          rows="10"
                          style={{ width: "100%" }}
                        />
                        <br />
                        {errors.reportcontent && touched.reportcontent ? (
                          <span className="error-msg">
                            {errors.reportcontent}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="primary_btn dark w-100 mb-3 mt-2"
                      disabled={disabledbtn}
                    >
                      Submit
                    </button>
                    <button
                      type="resey"
                      className="primary_btn dark w-100 mb-3 mt-2"
                      onClick={resetForm}
                      data-bs-toggle="modal"
                      data-bs-target="#Reportconfirm"
                    >
                      Cancel
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
