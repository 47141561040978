import React, { useEffect, useState } from "react";
import logout from "../assets/images/logout.svg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDisconnect } from "wagmi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function Sidebar(props) {
  const navigate = useNavigate();
  const [isprofile, setIsprofile] = useState(false);
  const [notification, setnotification] = useState(false);
  const [account, setaccount] = useState(false);
  const [wallet, setwallet] = useState(false);
  const location = useLocation();
  const walletConnection = useSelector((state) => state.walletConnection);
  const { disconnect } = useDisconnect();

  // if (
  //   !walletConnection ||
  //   walletConnection.connect == "" ||
  //   walletConnection.address == ""
  // ) {
  //   navigate("/");
  // }

  const currentPath = location.pathname;

  useEffect(() => {
    if (currentPath === "/profile-setting") {
      setIsprofile(true);
    } else if (currentPath === "/notification") {
      setnotification(true);
    } else if (currentPath === "/account") {
      setaccount(true);
    } else if (currentPath === "/wallet") {
      setwallet(true);
    }
  });

  async function disconnectMetamask() {
    localStorage.clear();
    disconnect();
  }

  return (
    <>
      <div className="sidebar">
        <h6>Settings</h6>
        <ul>
          <li>
            <Link
              to="/profile-setting"
              className={`${isprofile ? "active" : ""}`}
            >
              Profile
            </Link>
          </li>
          <li>
            <Link
              to="/notification"
              className={`${notification ? "active" : ""}`}
            >
              Notifications
            </Link>
          </li>
          <li>
            <Link to="/account" className={`${account ? "active" : ""}`}>
              Account
            </Link>
          </li>
          <li>
            <Link to="/wallet" className={`${wallet ? "active" : ""}`}>
              Wallet
            </Link>
          </li>
        </ul>
        <div className="logout" onClick={disconnectMetamask}>
          <span>Log out</span>
          <img src={logout} alt="img" className="img-fluid ms-1" />
        </div>
      </div>
    </>
  );
}
