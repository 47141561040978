import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logoblack.svg";
import search from "../assets/images/search.svg";
import eth from "../assets/images/eth.svg";
import metamask from "../assets/images/metamask.svg";
import axios from "axios";
import notification from "../assets/images/notification.svg";
import btnarw from "../assets/images/btnarw.svg";
import btnarw_white from "../assets/images/btnarw_white.svg";
import copyIcon from "../assets/images/copy.svg";
import wallet from "../assets/images/wallet.svg";
import TokenABI from "../ABI/TokenABI.json";
import { io } from "socket.io-client";
// import but
import nav_toggler from "../assets/images/nav_toggler.svg";
import copy from "copy-to-clipboard";
import $ from "jquery";
import { halfAddrShow } from "../helper/halfAddr";
import Web3 from "web3";
import config from "../lib/config";
import { connection } from "../helper/connection";
import { toastAlert } from "../lib/toastAlert";
import {
  getProfileData,
  addNewUser,
  HeaderSearch,
  readNotification
} from "../actions/users";
import { getTransaction } from "../actions/nfts";
import {
  useWalletClient,
  useDisconnect,
  useConnect,
  useNetwork,
  useAccount,
  useSwitchNetwork
} from "wagmi";

import { useDispatch, useSelector } from "react-redux";
import { setWallet, getUsers } from "../reducers/Actions";

import { walletClientToSigner } from "../pages/ethersConnect.js";
import { Numberconvert } from "../helper/numberConvert";

const socket = io(config.baseUrl);

export default function Navbar(props) {
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [copyTxt, setCopyTxt] = useState(false);
  const [Account, setAccount] = useState("");
  const [Balance, setBalance] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [currency, setCurrency] = useState("USD");
  const [conversionRate, setConversionRate] = useState(1);

  const [Search, setSearch] = useState("");
  const [userSearch, setUserSearch] = useState([]);
  const [nftSearch, setNftSearch] = useState([]);
  const [collSearch, setCollSearch] = useState([]);
  const [show, setShowdb] = useState(false);

  const [TrasHis, setTransHis] = useState([]);
  const [unreadCount, setUnreadCount] = useState([]);

  const walletConnection = useSelector((state) => state.walletConnection);
  const getUsersData = useSelector((state) => state.getUsers);

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        console.log(accounts, "accountsef");
        window.location.reload();
      });
    }
  }, []);

  useEffect(() => {
    fetchConversionRate();
  }, [currency]);

  const fetchConversionRate = async () => {
    try {
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=${currency}`
      );
      console.log(response, "response");
      setConversionRate(response.data["matic-network"][currency.toLowerCase()]);
    } catch (error) {
      console.error("Error fetching conversion rate:", error);
    }
  };

  const showinput = () => {
    setIsActive((current) => !current);
  };

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }

  const network = useSwitchNetwork({
    onMutate(args) {
      console.log("Mutate", args);
    },
    onError(error) {
      console.log("Error", error);
    }
  });

  useAccount({
    onConnect: ({ address, connector, isConnecting, isReconnected }) => {
      console.log("Connected", {
        address,
        connector,
        isConnecting,
        isReconnected
      });
    },
    onDisconnect: () => {
      console.log("Disconnected");
      var web3 = new Web3(config.rpcUrl);
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: ""
        })
      );
      setTimeout(function () {
        window.location.reload(false);
      }, 1500);
    }
  });

  const { disconnect } = useDisconnect();
  const { chain, chains } = useNetwork();
  let chainId = config.NetworkId;
  const { data: walletClient } = useWalletClient({ chainId });

  useEffect(() => {
    setConnection();
  }, [walletClient, chain?.network]);

  async function setConnection() {
    if (walletClient && chain && chain.id == config.NetworkId) {
      var { signer, transport } = walletClientToSigner(walletClient);

      var web3 = new Web3(transport);
      if (chain?.id == config.NetworkId) {
        const payload = {
          currAddr: walletClient.account.address
        };
        const addUser = await addNewUser(payload);
        console.log(addUser, "addUseraddUseraddUser");
        if (addUser.data.status) {
          toastAlert("error", "you are Banned by Admin");
          dispatch(
            setWallet({
              network: "",
              web3: "",
              address: "",
              provider: "",
              connect: "",
              isChange: ""
            })
          );
          return false;
          // setTimeout(() => {
          //   // window.location.reload()
          // }, 1000);
        } else {
          dispatch(
            setWallet({
              network: config.NetworkId,
              web3: web3,
              address: walletClient.account.address,
              provider: transport,
              connect: "yes",
              isChange: "false"
            })
          );
          console.log(addUser, "addUseraddUseraddUser");
          const getAddr = localStorage.getItem("wagmi.store");
          const parseAddrData = JSON.parse(getAddr);
          const parseData = parseAddrData?.state?.data?.account;
          localStorage.setItem("nicholousWallAddr", parseData);
          localStorage.setItem("chat-current-user", parseData);
        }
      }
    } else {
      var web3 = new Web3("https://rpc-mumbai.maticvigil.com");
      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: "",
          provider: null,
          connect: "",
          isChange: ""
        })
      );
    }
  }
  useEffect(() => {
    getConnect();
    getProfile();
    getTrasactions();
  }, [walletConnection.address]);
  const getConnect = async () => {
    if (
      walletConnection &&
      walletConnection.web3 &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != "" &&
      walletConnection.network != ""
    ) {
      let provider = walletConnection.provider;
      const web3 = new Web3(
        provider &&
        provider != null &&
        provider !== undefined &&
        provider !== ""
          ? provider
          : window.ethereum
      );
      try {
        await web3.eth.getAccounts(async (err, result) => {
          console.log(result, "result");
          setAccount(result[0].toLowerCase());
          let balVal = await web3.eth.getBalance(result[0].toLowerCase());
          console.log(web3.utils.fromWei(balVal), "balVal");
          let balance = Number(web3.utils.fromWei(balVal));
          let balance1 = balance.toFixed(4);
          setBalance(balance1);

          const ContractToken = new web3.eth.Contract(
            TokenABI,
            config.tokenContract
          );

          let tokenBal = await ContractToken.methods
            .balanceOf(walletConnection.address)
            .call();
          let wMaticBal = tokenBal / config.decimalValues;
          setTokenBalance(wMaticBal);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getProfile = async () => {
    if (walletConnection.address) {
      const payload = {
        address: walletConnection.address
      };
      const data = await getProfileData(payload);
      console.log(data, "data");
      dispatch(getUsers(data?.data?.data));
    }
  };

  async function disconnectMetamask() {
    localStorage.clear();

    disconnect();

    // setTimeout(function () {
    //   window.location.reload(false);
    // }, 1000);
  }

  const handleChange1 = async (e) => {
    setSearch(e.target.value);
    if (e.target.value !== "") {
      setShowdb(true);
      const result = await HeaderSearch(e.target.value);
      console.log(result, "search");
      // if (result.data.users.length > 0 || result.data.nfts.length > 0 || result.data.collection.lenght > 0) {
      setUserSearch(result.data.users);
      setNftSearch(result.data.nfts);
      setCollSearch(result.data.collection);
      // } else {
      //   setUserSearch([])
      //   setNftSearch([])
      //   setCollSearch([])
      // }
    } else {
      setUserSearch([]);
      setNftSearch([]);
      setCollSearch([]);
      setShowdb(false);
    }
  };

  useEffect(() => {
    try {
      socket.on("notification", (data) => {
        console.log(data, "wsdfghjkl");
        const noto = data.filter(
          (el) =>
            (el.actiontype == 1 ||
              el.actiontype == 2 ||
              el.actiontype == 3 ||
              el.actiontype == 4 ||
              el.actiontype == 5) &&
            el.useraddress == walletConnection.address
        );
        setTransHis(noto);
        setUnreadCount(noto.filter((el) => el.readNoti == false));
      });
    } catch (err) {
      console.log(err, "Errororor");
    }
  }, [walletConnection.address]);

  const getTrasactions = async () => {
    if (walletConnection.address) {
      const payload = {
        address: walletConnection.address
      };
      const history = await getTransaction(payload);
      console.log(history, "history");
      const dataaa = history?.data?.data.filter(
        (el) =>
          (el.actiontype == 1 ||
            el.actiontype == 2 ||
            el.actiontype == 3 ||
            el.actiontype == 4 ||
            el.actiontype == 5) &&
          el.useraddress == walletConnection.address
      );
      console.log(dataaa, "dataaadataaa");
      setTransHis(dataaa);
      setUnreadCount(dataaa.filter((el) => el.readNoti == false));
    }
  };

  const handleRead = async (hist) => {
    const payload = {
      id: hist._id
    };
    await readNotification(payload);
  };

  useEffect(() => {
    emitSocket();
  }, [walletConnection.address, getUsersData?._id]);

  const emitSocket = () => {
    if (walletConnection.address && getUsersData?._id) {
      console.log("AddUser__User");
      // socket.on('connect', () => {
      //   setSocketValue((prev) => ({
      //     ...prev,
      //     socketId: socket.id
      //   }));
      // });
      socket.emit("add-user", getUsersData?._id, socket.id);
    }
  };

  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-xl main_navbar navbar-sticky">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img src={logo} className="img-fluid brand_logo" alt="logo" />
          </NavLink>

          <div className="d-flex">
            <div>
              <div
                className={`input-group search search_mob  me-3 ${
                  isActive ? "show" : ""
                }`}
              >
                <span className="input-group-text" onClick={showinput}>
                  <img src={search} alt="img" className="img-fluid" />
                </span>
                <input
                  type="text"
                  className={
                    show
                      ? "form-control dropdown-toggle show"
                      : "form-control dropdown-toggle"
                  }
                  id="floatingInputGroup1"
                  placeholder="Search NFT, Collections & Users"
                  data-bs-toggle={show ? "dropdown" : ""}
                  aria-expanded={show ? "true" : "false"}
                  onChange={handleChange1}
                />
                {
                  <div>
                    <ul
                      style={{
                        height: "auto",
                        maxHeight: "450px",
                        overflow: "scroll"
                      }}
                      class={
                        show ? "dropdown-menu show elutha" : "dropdown-menu"
                      }
                    >
                      {userSearch &&
                        userSearch.length > 0 &&
                        userSearch.map((list, i) => {
                          return (
                            <li key={i}>
                              <a
                                class="dropdown-item"
                                href={`/user/${list.curraddress}`}
                              >
                                <img
                                  src={
                                    list?.image
                                      ? `${config.baseUrl}/usersProfile/${list?.image}`
                                      : require("../assets/images/LogosSampleAvator.png")
                                  }
                                  alt="img"
                                  className="img-fluid usr"
                                  style={{ width: "35px", height: "35px" }}
                                />{" "}
                                -{" "}
                                {list.name == "" ? list.curraddress : list.name}
                              </a>
                            </li>
                          );
                        })}
                      {nftSearch &&
                        nftSearch.length > 0 &&
                        nftSearch.map((list, i) => {
                          return (
                            <li key={i}>
                              <a
                                class="dropdown-item"
                                href={`/nft-detail/${list.contractAddress}/${list.nftId}`}
                              >
                                {list?.localImage?.split(".").pop() == "mp4" ? (
                                  <>
                                    {list.isCollNft ? (
                                      <video
                                        src={
                                          config.IPFS_IMG +
                                          "/" +
                                          list?.ipfsimageUrl
                                        }
                                        style={{
                                          width: "35px",
                                          height: "35px",
                                          marginRight: "10px"
                                        }}
                                        type="video/mp4"
                                        alt="Collections"
                                        className="lazy nft__item_preview nft__item_preview_video"
                                        controls
                                        controlsList="nodownload"
                                      />
                                    ) : (
                                      <video
                                        src={list?.ipfsimageUrl}
                                        style={{
                                          width: "35px",
                                          height: "35px",
                                          marginRight: "10px"
                                        }}
                                        type="video/mp4"
                                        alt="Collections"
                                        className="lazy nft__item_preview nft__item_preview_video"
                                        controls
                                        controlsList="nodownload"
                                      />
                                    )}
                                  </>
                                ) : list?.localImage.split(".").pop() ==
                                  "mp3" ? (
                                  <>
                                    {list.isCollNft ? (
                                      <audio
                                        src={
                                          config.IPFS_IMG +
                                          "/" +
                                          list?.ipfsimageUrl
                                        }
                                        style={{
                                          width: "35px",
                                          marginRight: "10px"
                                        }}
                                        type="audio/mp3"
                                        controls
                                        controlsList="nodownload"
                                        className="audio home"
                                      ></audio>
                                    ) : (
                                      <audio
                                        src={list?.ipfsimageUrl}
                                        style={{
                                          width: "35px",
                                          marginRight: "10px"
                                        }}
                                        type="audio/mp3"
                                        controls
                                        controlsList="nodownload"
                                        className="audio home"
                                      ></audio>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {list.isCollNft ? (
                                      <img
                                        src={
                                          config.IPFS_IMG +
                                          "/" +
                                          list?.ipfsimageUrl
                                        }
                                        alt="img"
                                        style={{
                                          width: "35px",
                                          height: "35px"
                                        }}
                                        className="img-fluid usr"
                                      />
                                    ) : (
                                      <img
                                        src={list?.ipfsimageUrl}
                                        alt="img"
                                        style={{
                                          width: "35px",
                                          height: "35px"
                                        }}
                                        className="img-fluid usr"
                                      />
                                    )}
                                  </>
                                )}
                                - {list?.nftName}
                              </a>
                            </li>
                          );
                        })}
                      {collSearch &&
                        collSearch.length > 0 &&
                        collSearch.map((list, i) => {
                          return (
                            <li key={i}>
                              <a
                                class="dropdown-item"
                                href={`/collection/${list.conAddr}`}
                              >
                                <img
                                  src={
                                    list.bannerImage
                                      ? `${config.baseUrl}/collections/${list.bannerImage}`
                                      : require("../assets/images/LogosSampleAvator.png")
                                  }
                                  alt="img"
                                  style={{ width: "35px", height: "35px" }}
                                  className="img-fluid usr"
                                />{" "}
                                - {list.name}
                              </a>
                            </li>
                          );
                        })}
                      {userSearch &&
                      userSearch.length == 0 &&
                      userSearch &&
                      userSearch.length == 0 &&
                      collSearch &&
                      collSearch.length == 0
                        ? "No data found"
                        : ""}

                      {/* <li>
                        <Link class="dropdown-item" href="#">
                          Djaina just bought Gorilla DJ Party - #321 at 13.65 ETH
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                }
              </div>
            </div>
            {/* after log in notification drop down for mobile view  */}
            <div className="nav_left">
              <div class="dropdown notification me-3 mob_view">
                <button
                  class="btn dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {unreadCount.length > 0 && (
                    <span
                      className="badge bg-primary"
                      style={{
                        position: "absolute",
                        marginLeft: "18px"
                      }}
                    >
                      {unreadCount.length}
                    </span>
                  )}
                  <img src={notification} alt="img" className="img-fluid" />
                </button>
                <ul class="dropdown-menu">
                  <h5>Notifications</h5>
                  {TrasHis.length > 0 &&
                    TrasHis.map((noti, i) => {
                      return (
                        <li key={i}>
                          {noti?.actiontype == 1 ? (
                            <a
                              class={`dropdown-item ${
                                noti?.readNoti ? "" : "text-bold11"
                              }`}
                              onClick={() => handleRead(noti)}
                              href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                            >
                              {halfAddrShow(noti?.useraddress)} just bought{" "}
                              {noti?.nftName} at {Numberconvert(noti?.amount)}{" "}
                              {config.Currency}
                            </a>
                          ) : noti?.actiontype == 3 &&
                            noti?.bids == "sender" ? (
                            <a
                              class={`dropdown-item ${
                                noti?.readNoti ? "" : "text-bold11"
                              }`}
                              onClick={() => handleRead(noti)}
                              href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                            >
                              {halfAddrShow(noti?.useraddress)} just accept your
                              bid {noti?.nftName} at{" "}
                              {Numberconvert(noti?.amount)}{" "}
                              {config.TokenCurrency}
                            </a>
                          ) : noti?.actiontype == 3 &&
                            noti?.bids == "reciver" ? (
                            <a
                              class={`dropdown-item ${
                                noti?.readNoti ? "" : "text-bold11"
                              }`}
                              onClick={() => handleRead(noti)}
                              href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                            >
                              You just accept{" "}
                              {halfAddrShow(noti?.touseraddress)} bid{" "}
                              {noti?.nftName} at {Numberconvert(noti?.amount)}{" "}
                              {config.TokenCurrency}
                            </a>
                          ) : noti?.actiontype == 2 ? (
                            <a
                              class={`dropdown-item ${
                                noti?.readNoti ? "" : "text-bold11"
                              }`}
                              onClick={() => handleRead(noti)}
                              href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                            >
                              {halfAddrShow(noti?.useraddress)} just listing{" "}
                              {noti?.nftName} at {Numberconvert(noti?.amount)}{" "}
                              {config.Currency}
                            </a>
                          ) : noti?.actiontype == 5 &&
                            noti?.transfer == "sender" ? (
                            <a
                              class={`dropdown-item ${
                                noti?.readNoti ? "" : "text-bold11"
                              }`}
                              onClick={() => handleRead(noti)}
                              href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                            >
                              You just transfer {noti?.nftName} to{" "}
                              {halfAddrShow(noti?.touseraddress)}{" "}
                            </a>
                          ) : noti?.actiontype == 5 &&
                            noti?.transfer == "reciver" ? (
                            <a
                              class={`dropdown-item ${
                                noti?.readNoti ? "" : "text-bold11"
                              }`}
                              onClick={() => handleRead(noti)}
                              href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                            >
                              You received {noti?.nftName} from{" "}
                              {halfAddrShow(noti?.touseraddress)}{" "}
                            </a>
                          ) : noti?.actiontype == 4 ? (
                            <a
                              class={`dropdown-item ${
                                noti?.readNoti ? "" : "text-bold11"
                              }`}
                              onClick={() => handleRead(noti)}
                              href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                            >
                              Your {noti?.nftName} NFT{" "}
                              {Numberconvert(noti?.amount)}{" "}
                              {config.TokenCurrency} bid expired{" "}
                            </a>
                          ) : (
                            ""
                          )}
                        </li>
                      );
                    })}

                  {/* <button className="primary_btn w-100 mt-5">
                    <span> See all</span>
                    <img src={btnarw} alt="img" className="ms-3 img-fluid" />
                  </button> */}
                </ul>
              </div>
            </div>
            <button
              className="navbar-toggler ms-3"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <img src={nav_toggler} alt="img" className="img-fluid" />
            </button>
          </div>

          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <div className="offcanvas-body">
              <div className="ms-auto navbar_common">
                <div className="navbar_right pc_view">
                  <ul>
                    <li>
                      <Link to="/explore">Explore </Link>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <Link to="/choose-blockchain">Create </Link>
                    </li>
                    {/* <li>
                      <Link>Community</Link>
                    </li> */}
                  </ul>
                  <NavLink className="navbar-brand" to="/">
                    <img
                      src={logo}
                      className="img-fluid desktoplogo"
                      alt="logo"
                    />
                  </NavLink>
                </div>

                <div className="nav_left">
                  <div className="input-group search search_pc me-4">
                    <span className="input-group-text">
                      <img src={search} alt="img" className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className={
                        show
                          ? "form-control dropdown-toggle show"
                          : "form-control dropdown-toggle"
                      }
                      id="floatingInputGroup1"
                      placeholder="Search NFT, Collections & Users"
                      data-bs-toggle={show ? "dropdown" : ""}
                      aria-expanded={show ? "true" : "false"}
                      onChange={handleChange1}
                    />
                    {
                      <div>
                        <ul
                          style={{
                            height: "auto",
                            maxHeight: "450px",
                            overflow: "scroll"
                          }}
                          class={
                            show ? "dropdown-menu show elutha" : "dropdown-menu"
                          }
                        >
                          {collSearch &&
                            collSearch.length > 0 &&
                            collSearch.map((list, i) => {
                              return (
                                <li key={i}>
                                  <a
                                    class="dropdown-item"
                                    href={`/collection/${list.conAddr}`}
                                  >
                                    <img
                                      src={
                                        list.bannerImage
                                          ? `${config.baseUrl}/collections/${list.bannerImage}`
                                          : require("../assets/images/LogosSampleAvator.png")
                                      }
                                      alt="img"
                                      style={{ width: "35px", height: "35px" }}
                                      className="img-fluid usr"
                                    />{" "}
                                    - {list.name}
                                  </a>
                                </li>
                              );
                            })}
                          {userSearch &&
                            userSearch.length > 0 &&
                            userSearch.map((list, i) => {
                              return (
                                <li key={i}>
                                  <a
                                    class="dropdown-item"
                                    href={`/user/${list.curraddress}`}
                                  >
                                    <img
                                      src={
                                        list?.image
                                          ? `${config.baseUrl}/usersProfile/${list?.image}`
                                          : require("../assets/images/LogosSampleAvator.png")
                                      }
                                      alt="img"
                                      className="img-fluid usr"
                                      style={{ width: "35px", height: "35px" }}
                                    />{" "}
                                    -{" "}
                                    {list.name == ""
                                      ? list.curraddress
                                      : list.name}
                                  </a>
                                </li>
                              );
                            })}
                          {nftSearch &&
                            nftSearch.length > 0 &&
                            nftSearch.map((list, i) => {
                              return (
                                <li key={i}>
                                  <a
                                    class="dropdown-item"
                                    href={`/nft-detail/${list.contractAddress}/${list.nftId}`}
                                  >
                                    {list?.localImage?.split(".").pop() ==
                                    "mp4" ? (
                                      <>
                                        {list.isCollNft ? (
                                          <video
                                            src={
                                              config.IPFS_IMG +
                                              "/" +
                                              list?.ipfsimageUrl
                                            }
                                            style={{
                                              width: "35px",
                                              height: "35px",
                                              marginRight: "10px"
                                            }}
                                            type="video/mp4"
                                            alt="Collections"
                                            className="lazy nft__item_preview nft__item_preview_video"
                                            controls
                                            controlsList="nodownload"
                                          />
                                        ) : (
                                          <video
                                            src={list?.ipfsimageUrl}
                                            style={{
                                              width: "35px",
                                              height: "35px",
                                              marginRight: "10px"
                                            }}
                                            type="video/mp4"
                                            alt="Collections"
                                            className="lazy nft__item_preview nft__item_preview_video"
                                            controls
                                            controlsList="nodownload"
                                          />
                                        )}
                                      </>
                                    ) : list?.localImage.split(".").pop() ==
                                      "mp3" ? (
                                      <>
                                        {list.isCollNft ? (
                                          <audio
                                            src={
                                              config.IPFS_IMG +
                                              "/" +
                                              list?.ipfsimageUrl
                                            }
                                            style={{
                                              width: "35px",
                                              marginRight: "10px"
                                            }}
                                            type="audio/mp3"
                                            controls
                                            controlsList="nodownload"
                                            className="audio home"
                                          ></audio>
                                        ) : (
                                          <audio
                                            src={list?.ipfsimageUrl}
                                            style={{
                                              width: "35px",
                                              marginRight: "10px"
                                            }}
                                            type="audio/mp3"
                                            controls
                                            controlsList="nodownload"
                                            className="audio home"
                                          ></audio>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {list.isCollNft ? (
                                          <img
                                            src={
                                              config.IPFS_IMG +
                                              "/" +
                                              list?.ipfsimageUrl
                                            }
                                            alt="img"
                                            style={{
                                              width: "35px",
                                              height: "35px"
                                            }}
                                            className="img-fluid usr"
                                          />
                                        ) : (
                                          <img
                                            src={list?.ipfsimageUrl}
                                            alt="img"
                                            style={{
                                              width: "35px",
                                              height: "35px"
                                            }}
                                            className="img-fluid usr"
                                          />
                                        )}
                                      </>
                                    )}
                                    - {list?.nftName}
                                  </a>
                                </li>
                              );
                            })}
                          {userSearch &&
                          userSearch.length == 0 &&
                          userSearch &&
                          userSearch.length == 0 &&
                          collSearch &&
                          collSearch.length == 0
                            ? "No data found"
                            : ""}

                          {/* <li>
                        <Link class="dropdown-item" href="#">
                          Djaina just bought Gorilla DJ Party - #321 at 13.65 ETH
                        </Link>
                      </li> */}
                        </ul>
                      </div>
                    }
                  </div>
                  {walletConnection &&
                  walletConnection.connect == "yes" &&
                  walletConnection.address &&
                  walletConnection.address != "" ? (
                    <>
                      {" "}
                      {/* after log in wallet btn */}
                      <div className="wallet_addr me-3">
                        <span>
                          <img src={wallet} alt="img" className="img-fluid" />
                        </span>
                        <a>
                          {halfAddrShow(
                            localStorage.getItem("nicholousWallAddr")
                          )}
                        </a>
                        <span
                          className="copy"
                          onClick={() => {
                            copy(localStorage.getItem("nicholousWallAddr"));
                            toastAlert("success", "Copied");
                            setCopyTxt(true);
                            setTimeout(function () {
                              setCopyTxt(false);
                            }, 800);
                          }}
                        >
                          {copyTxt ? (
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                              style={{ color: "green" }}
                            ></i>
                          ) : (
                            <img
                              src={copyIcon}
                              alt="img"
                              className="img-fluid"
                            />
                          )}
                        </span>
                      </div>
                      {/* after log in notification drop down  */}
                      <div class="dropdown notification me-3 pc_view">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {unreadCount.length > 0 && (
                            <span
                              className="badge bg-primary"
                              style={{
                                position: "absolute",
                                marginLeft: "18px"
                              }}
                            >
                              {unreadCount.length}
                            </span>
                          )}

                          <img
                            src={notification}
                            alt="img"
                            className="img-fluid"
                          />
                        </button>
                        <ul class="dropdown-menu">
                          <h5>Notifications</h5>
                          {TrasHis.length > 0 &&
                            TrasHis.map((noti, i) => {
                              return (
                                <li key={i}>
                                  {noti?.actiontype == 1 ? (
                                    <a
                                      class={`dropdown-item ${
                                        noti?.readNoti ? "" : "text-bold11"
                                      }`}
                                      onClick={() => handleRead(noti)}
                                      href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                                    >
                                      {halfAddrShow(noti?.useraddress)} just
                                      bought {noti?.nftName} at{" "}
                                      {Numberconvert(noti?.amount)}{" "}
                                      {config.Currency}
                                    </a>
                                  ) : noti?.actiontype == 3 &&
                                    noti?.bids == "reciver" ? (
                                    <a
                                      class={`dropdown-item ${
                                        noti?.readNoti ? "" : "text-bold11"
                                      }`}
                                      onClick={() => handleRead(noti)}
                                      href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                                    >
                                      {halfAddrShow(noti?.touseraddress)} just
                                      accept your bid {noti?.nftName} at{" "}
                                      {Numberconvert(noti?.amount)}{" "}
                                      {config.TokenCurrency}
                                    </a>
                                  ) : noti?.actiontype == 3 &&
                                    noti?.bids == "sender" ? (
                                    <a
                                      class={`dropdown-item ${
                                        noti?.readNoti ? "" : "text-bold11"
                                      }`}
                                      onClick={() => handleRead(noti)}
                                      href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                                    >
                                      You just accept{" "}
                                      {halfAddrShow(noti?.touseraddress)} bid{" "}
                                      {noti?.nftName} at{" "}
                                      {Numberconvert(noti?.amount)}{" "}
                                      {config.TokenCurrency}
                                    </a>
                                  ) : noti?.actiontype == 2 ? (
                                    <a
                                      class={`dropdown-item ${
                                        noti?.readNoti ? "" : "text-bold11"
                                      }`}
                                      onClick={() => handleRead(noti)}
                                      href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                                    >
                                      {halfAddrShow(noti?.useraddress)} just
                                      listing {noti?.nftName} at{" "}
                                      {Numberconvert(noti?.amount)}{" "}
                                      {config.Currency}
                                    </a>
                                  ) : noti?.actiontype == 5 &&
                                    noti?.transfer == "sender" ? (
                                    <a
                                      class={`dropdown-item ${
                                        noti?.readNoti ? "" : "text-bold11"
                                      }`}
                                      onClick={() => handleRead(noti)}
                                      href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                                    >
                                      You just transfer {noti?.nftName} to{" "}
                                      {halfAddrShow(noti?.touseraddress)}{" "}
                                    </a>
                                  ) : noti?.actiontype == 5 &&
                                    noti?.transfer == "reciver" ? (
                                    <a
                                      class={`dropdown-item ${
                                        noti?.readNoti ? "" : "text-bold11"
                                      }`}
                                      onClick={() => handleRead(noti)}
                                      href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                                    >
                                      You received {noti?.nftName} from{" "}
                                      {halfAddrShow(noti?.touseraddress)}{" "}
                                    </a>
                                  ) : noti?.actiontype == 4 ? (
                                    <a
                                      class={`dropdown-item ${
                                        noti?.readNoti ? "" : "text-bold11"
                                      }`}
                                      onClick={() => handleRead(noti)}
                                      href={`${config.frontUrl}/nft-detail/${noti?.collectionAddr}/${noti?.NftId}`}
                                    >
                                      Your {noti?.nftName} NFT{" "}
                                      {Numberconvert(noti?.amount)}{" "}
                                      {config.TokenCurrency} bid expired{" "}
                                    </a>
                                  ) : (
                                    ""
                                  )}
                                </li>
                              );
                            })}

                          {/* <button className="primary_btn w-100 mt-5">
                            <span> See all</span>
                            <img
                              src={btnarw}
                              alt="img"
                              className="ms-3 img-fluid"
                            />
                          </button> */}
                        </ul>
                      </div>
                      {/* after log in profile drop down  */}
                      <div class="dropdown pc_view">
                        <button
                          class="btn dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={
                              getUsersData?.image
                                ? `${config.baseUrl}/usersProfile/${getUsersData?.image}`
                                : require("../assets/images/LogosSampleAvator.png")
                            }
                            alt="img"
                            className="img-fluid usr"
                          />
                        </button>
                        <ul class="dropdown-menu">
                          <div className="box">
                            <div className="fle_x bdr">
                              <div className="d-flex">
                                <img
                                  src={eth}
                                  alt="img"
                                  className="img-fluid me-2"
                                />
                                <p>
                                  {Number(Balance).toFixed(4)} {config.Currency}
                                </p>
                              </div>
                              <p className="dollar">
                                ${(Balance * conversionRate).toFixed(2)}
                              </p>
                            </div>
                            <div className="fle_x">
                              <div className="d-flex">
                                <img
                                  src={metamask}
                                  alt="img"
                                  className="img-fluid me-2"
                                />
                                <span>
                                  {halfAddrShow(
                                    localStorage.getItem("nicholousWallAddr")
                                  )}
                                </span>
                              </div>
                              <span className="connected">Connected</span>
                            </div>
                          </div>
                          <div className="box">
                            <div className="fle_x">
                              <div className="d-flex">
                                <img
                                  src={eth}
                                  alt="img"
                                  className="img-fluid me-2"
                                />
                                <p>
                                  {tokenBalance.toFixed(4)}{" "}
                                  {config.TokenCurrency}
                                </p>
                              </div>
                              {/* <p className="dollar">
                                ${(tokenBalance * conversionRate).toFixed(2)}
                              </p> */}
                            </div>
                          </div>
                          <div className="blw_box">
                            <li data-bs-dismiss="offcanvas" aria-label="Close">
                              <Link class="dropdown-item" to="/profile-setting">
                                Profile
                              </Link>
                            </li>
                            <li data-bs-dismiss="offcanvas" aria-label="Close">
                              <Link class="dropdown-item" to={`/profile`}>
                                {" "}
                                My NFTs
                              </Link>
                            </li>
                            <li data-bs-dismiss="offcanvas" aria-label="Close">
                              <Link
                                class="dropdown-item"
                                to="/choose-blockchain"
                              >
                                {" "}
                                Create
                              </Link>
                            </li>
                            {/* <li data-bs-dismiss="offcanvas" aria-label="Close">
                              <Link class="dropdown-item" to="#">
                                {" "}
                                Learn
                              </Link>
                            </li> */}
                            <li data-bs-dismiss="offcanvas" aria-label="Close">
                              <Link class="dropdown-item" to="/profile-setting">
                                {" "}
                                Settings
                              </Link>
                            </li>
                            <li data-bs-dismiss="offcanvas" aria-label="Close">
                              <Link class="dropdown-item" to="/help">
                                {" "}
                                Help center
                              </Link>
                            </li>
                            <li
                              data-bs-dismiss="offcanvas"
                              aria-label="Close"
                              onClick={disconnectMetamask}
                            >
                              <Link class="dropdown-item border-0" to="#">
                                {" "}
                                Log out
                              </Link>
                            </li>
                          </div>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Link to="/connect-wallet" className="primary_btn dark">
                        <span>Connect Wallet</span>
                        <img
                          src={btnarw_white}
                          alt="img"
                          className="ms-3 img-fluid"
                        />
                      </Link>
                    </>
                  )}

                  <div className="profile_mob mob_view">
                    <div>
                      <div className="box">
                        {/* <div className="fle_x bdr">
                          <div className="d-flex">
                            <p>0x*********30</p>
                          </div>
                          <img src={copy} alt="img" className="img-fluid cpy" />
                        </div> */}
                        <div className="fle_x bdr">
                          <div className="d-flex">
                            <img
                              src={eth}
                              alt="img"
                              className="img-fluid me-2"
                            />
                            <p>
                              {Number(Balance).toFixed(4)} {config.Currency}
                            </p>
                          </div>
                          <p className="dollar">
                            ${(Balance * conversionRate).toFixed(2)}
                          </p>
                        </div>
                        <div className="fle_x">
                          <div className="d-flex">
                            <img
                              src={metamask}
                              alt="img"
                              className="img-fluid me-2"
                            />
                            <span>
                              {halfAddrShow(
                                localStorage.getItem("nicholousWallAddr")
                              )}
                            </span>
                          </div>
                          <span className="connected">connected</span>
                        </div>
                      </div>
                      <div className="blw_box">
                        <div className="box">
                          <div className="fle_x">
                            <div className="d-flex">
                              <img
                                src={eth}
                                alt="img"
                                className="img-fluid me-2"
                              />
                              <p>
                                {tokenBalance.toFixed(4)} {config.TokenCurrency}
                              </p>
                            </div>
                            {/* <p className="dollar">
                                ${(tokenBalance * conversionRate).toFixed(2)}
                              </p> */}
                          </div>
                        </div>
                        <li data-bs-dismiss="offcanvas" aria-label="Close">
                          <Link class="dropdown-item" to="/profile-setting">
                            Profile
                          </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close">
                          <Link class="dropdown-item" to="/explore">
                            Explore{" "}
                          </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close">
                          <Link class="dropdown-item" to="/choose-blockchain">
                            {" "}
                            Create
                          </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close">
                          <Link class="dropdown-item">Community</Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close">
                          <Link class="dropdown-item" to="/profile">
                            {" "}
                            My NFTs
                          </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close">
                          <Link class="dropdown-item" to="#">
                            {" "}
                            Learn
                          </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close">
                          <Link class="dropdown-item" to="/profile-setting">
                            {" "}
                            Settings
                          </Link>
                        </li>
                        <li data-bs-dismiss="offcanvas" aria-label="Close">
                          <Link class="dropdown-item" to="/help">
                            {" "}
                            Help center
                          </Link>
                        </li>
                        <li
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                          onClick={disconnectMetamask}
                        >
                          <Link class="dropdown-item border-0" to="#">
                            {" "}
                            Log out
                          </Link>
                        </li>
                      </div>
                    </div>
                    {/* <div
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                      className="logout"
                    >
                      <Link class="dropdown-item border-0" to="#">
                        {" "}
                        Log out
                      </Link>
                    </div> */}
                  </div>

                  {/* 
                <Link to="/connect-wallet" className="primary_btn dark">
                  <span>Connect Wallet</span>
                  <img src={btnarw_white} alt="img" className='ms-3 img-fluid' /> 
                </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
