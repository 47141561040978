import axios from "axios";
import config from "../lib/config";

export const getServiceFees = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/getServiceFees`
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const ipfsImageHashGet = async (data) => {
  var formData = new FormData();
  if (data.Image) {
    formData.append("ImageNFT", data.Image);
    formData.append("tokenID", data.tokenID);
  }
  console.log(formData, "hgbfswfkejwlekr");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/ipfsImageHashGet`,
      data: formData
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};
export const ipfsMetaDataFn = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/ipfsMetaDataFn`,
      data
    });
    console.log(respData, "hgbfswfkejwlekr");
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};

export const nftNameCheck = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/nftNameCheck`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const swapSignInit = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/swapSignInit`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const getSwapsByCurrUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getSwapsByCurrUser`,
      data
    });
    return { data: respData?.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData?.data
    };
  }
};

export const swapOpenAction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/swapOpenAction`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const nftSwapList = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/nftSwapList`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const TokenAddItemAction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/CreateNFT`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const getPopNFT = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/getPopNFT`
    });
    return {
      data: respData.data
    };
  } catch (err) {
    console.log(err,'webhejdbjdfdfcewfr_____');
    return {
      error: err?.respData.data
    };
  }
};

export const getTrandCollection = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/getTrandCollection`
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};

export const getTrandUsers = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getTrandUsers`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};

export const getOwnNFT = async (payload, data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getOwnNFT`,
      data: payload,
      params: { filter: data }
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};

export const getOpenSwapNfts = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getOpenSwapNfts`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};

export const swapCompleteAction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/swapCompleteAction`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};

export const getSwapHist = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getSwapHist`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};

export const getNftById = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getNftById`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};

export const getMoreColl = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getMoreColl`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};

export const getCollectionByAddr = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getCollectionByAddr`,
      data
    });
    console.log(respData);
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const getCategory = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/getCategory`
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData.data
    };
  }
};

export const createCollection = async (data) => {
  try {
    console.log(data, "fffff");
    var formData = new FormData();

    for (let i = 0; i < data.image.length; i++) {
      formData.append("image", data.image[i]);
    }
    formData.append("collName", data.collName);
    formData.append("collDisc", data.collDisc);
    formData.append("collPrice", data.collPrice);
    formData.append("ownerAddr", data.ownerAddr);
    formData.append("collRoyalty", data.collRoyalty);
    formData.append("Collcate", data.Collcate);

    // for (let i = 0; i < data.bulkFiles.length; i++) {
    //   formData.append("bulkFiles", data.bulkFiles[i]);
    // }

    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/collCreate`,
      data: formData
    });
    return {
      data: resp.data
    };
  } catch (err) {
    console.log(err, "errrrrrrre");
    return {
      error: err.resp
    };
  }
};
export const uploadIPFSDir = async (data) => {
  try {
    console.log(data, "fffff");
    var formData = new FormData();
    if (data.Image) {
      formData.append("bannerImg", data.Image);
      formData.append("folder", data.folder);
      formData.append("collName", data.collName);
      formData.append("collSymb", data.collSymb);
      formData.append("collDisc", data.collDisc);
      formData.append("collPrice", data.collPrice);
      formData.append("userAddr", data.userAddr);
      formData.append("newContract", data.newContract);
      formData.append("collRoyalty", data.collRoyalty);
      formData.append("collNft", data.collNft);
      formData.append("files", data.files);
      formData.append("ownerAddr", data.ownerAddr);
      formData.append("Shorturl", data.url);
      formData.append("swapEnable", data.swapEnable);
      formData.append("Collcate", data.Collcate);
    }

    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/uploadIPFSDir`,
      data: formData
    });
    return {
      data: resp
    };
  } catch (err) {
    return {
      error: err.resp
    };
  }
};

export const get_allNfts = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/get_allNfts`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const getBiddingsList = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getBiddingsList`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const getNftHis = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getNftHis`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const getTransaction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getTransaction`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const getTransactionByUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getTransactionByUser`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const getNftHis2 = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getNftHis2`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const getCollectionNFTs = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getCollectionNFTs`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const get_allColletions = async (payload) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/get_allColletions`,
      data: payload
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const get_allUsers = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.baseUrl}/api/routesUser/get_allUsers`
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err?.respData?.data
    };
  }
};

export const PurchaseNFT = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/purchaseNFT`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const bidAcceptAction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/bidAcceptAction`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const TrasferNFT = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/TrasferNFT`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const PutOnSale = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/PutOnSale`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};


//checknftowneraddress

export const checknftowner = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/checknftowner`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

//checknftforputonsale

export const checkforputonsale = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/checkforputonsale`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

//checkforplaceBid


export const checkforplaceBid = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/checkforplaceBid`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};


export const checkforcancelBid = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/checkforplaceBid`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};
//

export const checkForSwapInit = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/checkForSwapInit`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const PutOnAuction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/PutOnAuction`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const PlaceABid = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/PlaceABid`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const BurnNft = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/BurnNft`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const CancelOrder = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/CancelOrder`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const CancelOwnAuction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/CancelOwnAuction`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const CancelOwnBid = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/CancelOwnBid`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const getCompleteSwap = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getCompleteSwap`,
      data
    });
    return { data: respData.data };
  } catch (err) {
    console.log(err);
    return {
      error: err?.respData.data
    };
  }
};

export const GetAllEvent = async () => {
  try {
    const result = await axios.get(`${config.baseUrl}/api/routesUser/allevent`);
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};




export const checkUserreport = async (payload) => {
  try {
    const result = await axios({
      url:`${config.baseUrl}/api/routesUser/checkUserreport`,
      method:'post',
      data:payload      
    });
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};


export const checkcollectionreport = async (payload) => {
  try {
    const result = await axios({
      url:`${config.baseUrl}/api/routesUser/checkcollectionreport`,
      method:'post',
      data:payload      
    });
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const checkNFTreport = async (payload) => {
  try {
    const result = await axios({
      url:`${config.baseUrl}/api/routesUser/checkNFTreport`,
      method:'post',
      data:payload      
    });
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};