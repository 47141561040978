import React, { useState, useEffect } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Navbar from "../components/Navbar.js";
import search from "../assets/images/search.svg";
import { Link, useParams, useNavigate } from "react-router-dom";
import verified from "../assets/images/verified.svg";
import plus from "../assets/images/plus.svg";
import polygonicon from "../assets/images/polygon_icon.svg";
import copy from "../assets/images/copy.svg";
import option from "../assets/images/option.svg";
import twitter from "../assets/images/twitter.svg";
import Countdown from "react-countdown";
import HashLoader from "react-spinners/HashLoader";
// import instagram from "../assets/images/instagram.svg";
import msg from "../assets/images/msg.svg";
import upload from "../assets/images/upload.svg";
import Telagram from "../assets/images/telegram.svg";
import Facebook from "../assets/images/facebook.svg";
// import twitter from "../assets/images/twitter.svg"
import instagram from "../assets/images/instagram.svg";
import refresh from "../assets/images/refresh.svg";
import btnarw_white from "../assets/images/btnarw_white.svg";
import btnarw from "../assets/images/btnarw.svg";
import eth from "../assets/images/eth.svg";
import full_screen from "../assets/images/full_screen.svg";
import view from "../assets/images/view.svg";
import Footer from "../components/Footer.js";
import info from "../assets/images/info.svg";
import fixed from "../assets/images/fixed.svg";
import timed from "../assets/images/timed.svg";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { JsonRpcProvider, Wallet, getSigner } from "ethers";
import moment from "moment";
import $ from "jquery";
import { bignumber, multiply, divide, add } from "mathjs";

import {
  getNftById,
  PurchaseNFT,
  TrasferNFT,
  PutOnSale,
  BurnNft,
  CancelOrder,
  getMoreColl,
  PlaceABid,
  getServiceFees,
  getBiddingsList,
  getNftHis,
  bidAcceptAction,
  PutOnAuction,
  CancelOwnAuction,
  CancelOwnBid,
  nftSwapList,
  swapSignInit,
  getSwapsByCurrUser,
  swapOpenAction,
  checkNFTreport,
  checkcollectionreport,
  checknftowner,
  checkforputonsale,
  checkforplaceBid,
  checkForSwapInit,
  checkforcancelBid
} from "../actions/nfts";
import { SetReportpage } from "../actions/users";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { halfAddrShow } from "../helper/halfAddr.js";
import config from "../lib/config";
import { useSelector } from "react-redux";
import Web3 from "web3";
import SingleNFTABI from "../ABI/SingleNFTABI.json";
import TokenABI from "../ABI/TokenABI.json";
import CollABI from "../ABI/CollectionABI.json";
import SwapABI from "../ABI/SwapABI.json";
import { toastAlert } from "../lib/toastAlert.js";
import { Numberconvert } from "../helper/numberConvert.js";

const override = {
  // display: "block",
  // margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999"
};

const ReportPageValidate = Yup.object().shape({
  reporttitle: Yup.string()
    .required("Required")
    .test("value", "Required", (value) => value.trim().length != 0)
    .matches(
      /^([a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Insert only normal character"
    ),
  reportcontent: Yup.string()
    .required("Required")
    .test("value", "Required", (value) => value.trim().length != 0)
  // .matches(
  //   /^([a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
  //   "Insert only normal character"
  // )
});

const TrasferSchema = Yup.object().shape({
  toAddr: Yup.string().required("Required")
});

const BidSchema = Yup.object().shape({
  nftBidPrice: Yup.number()
    .required("Required")
    .typeError("Value must specify a number")
    .moreThan(0, "Value must be greater than 0")
    .test(
      "maxDigitsAfterDecimal",
      "number field must have 8 digits after decimal or less",
      (nftBidPrice) => /^\d*(\.\d{1,8})?$/.test(Numberconvert(nftBidPrice))
    )
});

const BidSchema1 = Yup.object().shape({
  nftBidPrice: Yup.number()
    .required("Required")
    .typeError("Value must specify a number")
    .moreThan(0, "Value must be greater than 0")
    .test(
      "maxDigitsAfterDecimal",
      "number field must have 8 digits after decimal or less",

      (nftBidPrice) => /^\d*(\.\d{1,8})?$/.test(Numberconvert(nftBidPrice))
    )
});

const NFTSchema = Yup.object().shape({
  nftPrice: Yup.number()
    .required("Required")
    .typeError("Value must specify a number")
    .moreThan(0, "Value must be greater than 0")
    .test(
      "maxDigitsAfterDecimal",
      "number field must have 6 digits after decimal or less",
      (nftPrice) => /^\d+(\.\d{1,8})?$/.test(nftPrice)
    )
});
const NFTSchema1 = Yup.object().shape({
  nftMinPrice: Yup.number()
    .required("Required")
    .typeError("Value must specify a number")
    .moreThan(0, "Value must be greater than 0")
    .lessThan(Yup.ref("nftMaxPrice"), "Value must be less than maximum price")
    .test(
      "maxDigitsAfterDecimal",
      "number field must have 6 digits after decimal or less",
      (nftMinPrice) => /^\d+(\.\d{1,8})?$/.test(nftMinPrice)
    ),
  nftMaxPrice: Yup.number()
    .required("Required")
    .typeError("Value must specify a number")
    .moreThan(0, "Value must be greater than 0")
    .moreThan(Yup.ref("nftMinPrice"), "Value must be more than minimum price")
    .test(
      "maxDigitsAfterDecimal",
      "number field must have 6 digits after decimal or less",
      (nftMaxPrice) => /^\d+(\.\d{1,8})?$/.test(nftMaxPrice)
    )
});

export default function Nftdetail(props) {
  const navigate = useNavigate();
  var myDate = new Date();
  var newdat = myDate.setDate(myDate.getDate() + parseInt(1));
  var initialEnd = new Date(newdat);
  const { nftId, contractAddress } = useParams();
  const getUsers = useSelector((state) => state.getUsers);
  const walletConnection = useSelector((state) => state.walletConnection);
  let [loading, setLoading] = useState(false);
  const [saleType, setSaleType] = useState("FixedPrice");

  const [StartDate, Set_StartDate] = useState("Right after listing");
  const [EndDate, Set_EndDate] = useState("1 Day");
  const [Clocktime, set_Clocktime] = useState(new Date());
  const [EndClocktime, set_EndClocktime] = useState(initialEnd);

  const [startTimeErr, setStartTimeErr] = useState("");
  const [endTimeErr, setendTimeErr] = useState("");

  const [selectedValue, setSelectedValue] = useState(
    "Choose your bid expiration date"
  );
  const [ExpiredDate, set_ExpiredDate] = useState();

  const [getNft, setGetNft] = useState();
  const [currency, setCurrency] = useState("USD");
  const [conversionRate, setConversionRate] = useState(1);
  const [moreNfts, setMoreNFts] = useState([]);
  const [serviceFee, setServFee] = useState(0);
  const [myBalance, setMyBalance] = useState();
  const [myTokBalance, setMyTokBalance] = useState();
  const [TimedBid, SetTimedBid] = useState(false);
  const [OpenSwap, SetOpenSwap] = useState(false);
  const [CloseSwap, SetCloseSwap] = useState(false);
  const [biddingData, setBiddingData] = useState([]);
  const [NFTHis, setNFTHis] = useState([]);
  const [bidAccepter, setBidAccepter] = useState();
  const [swapStatus, setSwapStatus] = useState();
  const [swapList, setSwapList] = useState();
  const [swapFilt, setSwapFilt] = useState("");
  const [swapUser, setSwapUser] = useState();
  const [btnDisCli, setBtnDisa] = useState(false);
  const [checkSwapUser, setcheckSwapUser] = useState(false);
  const [disabledbtn, setdisabledbtn] = useState(false);

  const [iscompled, setiscompled] = useState(false);
  const [timdputonsale, setTimedPutonSale] = useState(false);
  const [CountdownState, setCountdownState] = useState(false);

  // const renderer1 = ({
  //   days,
  //   Month,
  //   Year,
  //   hours,
  //   minutes,
  //   seconds,
  //   completed
  // }) => {
  //   { console.log(days, hours, completed, minutes, 'completedcompletedcompleted'); }
  //   // setiscompled(completed)z
  //   completed == true ? setiscompled(true) : setiscompled(false)
  //   console.log(completed, 'completed');
  //   // if (iscompled == false) {
  //   //   if (completed == true) {
  //   //     setiscompled(true)
  //   //   }
  //   // }

  //   if (completed) {
  //     return (
  //       <span>
  //         {days}d {hours}h {minutes}m {seconds}s Left
  //       </span>
  //     );
  //   } else {
  //     return (
  //       <span>
  //         {days}d {hours}h {minutes}m {seconds}s to Start
  //       </span>
  //     );
  //   }
  // };

  const renderer1 = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    {
      console.log(
        days,
        hours,
        completed,
        minutes,
        "completedcompletedcompleted"
      );
    }
    // setiscompled(completed)z
    completed == true ? setiscompled(true) : setiscompled(false);
    console.log(completed, "completed");
    // if (iscompled == false) {
    //   if (completed == true) {
    //     setiscompled(true)
    //   }
    // }

    if (completed) {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s Left
        </span>
      );
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s to Start
        </span>
      );
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    completed == true ? setTimedPutonSale(false) : setTimedPutonSale(true);

    if (completed) {
      return <span>Auction Ended</span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s Left
        </span>
      );
    }
  };

  const renderer3 = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <span>Bid Expired</span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s Left
        </span>
      );
    }
  };

  useEffect(() => {
    getSingleNft();
    getMoreCollList();
    ServiceFee();
  }, []);

  useEffect(() => {
    getConnect();
    getBiddings();
    getNFTActivities();
    // getSwapList();
    getSwapStates();
  }, [walletConnection.address]);

  useEffect(() => {
    fetchConversionRate();
  }, []);

  useEffect(() => {
    getSwapList();
  }, [walletConnection.address, swapFilt]);

  const fetchConversionRate = async () => {
    try {
      const response = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=${currency}`
      );
      console.log(
        response.data["matic-network"][currency.toLowerCase()],
        "response"
      );
      setConversionRate(response.data["matic-network"][currency.toLowerCase()]);
    } catch (error) {
      console.error("Error fetching conversion rate:", error);
    }
  };

  const getConnect = async () => {
    if (
      walletConnection &&
      walletConnection.web3 &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != "" &&
      walletConnection.network != ""
    ) {
      let provider = walletConnection.provider;
      const web3 = new Web3(
        provider &&
        provider != null &&
        provider !== undefined &&
        provider !== ""
          ? provider
          : window.ethereum
      );
      try {
        await web3.eth.getAccounts(async (err, result) => {
          console.log(result, "result");
          let balVal = await web3.eth.getBalance(result[0].toLowerCase());
          console.log(web3.utils.fromWei(balVal), "balVal");
          let balance = Number(web3.utils.fromWei(balVal));
          let balance1 = balance.toFixed(4);
          setMyBalance(balance1);
          const ContractToken = new web3.eth.Contract(
            TokenABI,
            config.tokenContract
          );

          console.log(
            ContractToken,
            "Tokennnnnnnnnn",
            walletConnection.address
          );

          let resultTok = await ContractToken.methods
            .balanceOf(walletConnection.address)
            .call();
          let tokeBalance = Number(web3.utils.fromWei(resultTok));
          console.log(tokeBalance, "resultTok");
          setMyTokBalance(tokeBalance);
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const ServiceFee = async () => {
    const serFee = await getServiceFees();
    console.log(serFee, "serFee");
    setServFee(serFee?.data?.data);
  };

  const getMoreCollList = async () => {
    const payload = {
      nftId,
      contractAddress
    };
    const moreColl = await getMoreColl(payload);
    console.log(moreColl, "moreColl");
    setMoreNFts(moreColl?.data?.data);
  };

  const getSingleNft = async () => {
    const payload = {
      nftId,
      contractAddress
    };
    console.log(payload, "payload");
    const singleNft = await getNftById(payload);

    console.log(singleNft, "singleNft");

    if (singleNft?.data?.status) {
      if (singleNft?.data?.data?.[0]?.burnNft == 1) {
        navigate("/");
      } else {
        setGetNft(singleNft?.data?.data);
      }
    } else {
      toastAlert("error", "NFT Banned by Admin");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  };

  const getBiddings = async () => {
    if (walletConnection.address) {
      const payload = {
        nftId,
        contractAddress,
        currAddr: walletConnection.address
      };
      const bids = await getBiddingsList(payload);
      console.log(bids, "Bids");
      setBiddingData(bids?.data?.data);
    }
  };

  const checkuserForswap = async () => {
    const payload = {
      address: walletConnection.address,
      nftId: getNft?.[0].nftId,
      contractAddress: getNft?.[0]?.contractAddress,
      reciveraddress: swapUser.curraddress
    };

    console.log(swapUser, "swapUserswapUser");

    const result = await checknftowner(payload);

    if (!result.data.status) {
      setLoading(false);

      toastAlert("error", result.data.message);

      setTimeout(() => {
        // window.location.reload()
      }, 2000);
      return false;
    } else {
      navigate("/swap", {
        state: swapUser,
        state1: "123"
      });
    }
  };
  const getNFTActivities = async () => {
    const payload = {
      nftId,
      contractAddress
    };
    const history = await getNftHis(payload);
    console.log(history, "history");
    setNFTHis(history?.data?.data);
  };

  const handleDropdownSelect = (eventKey) => {
    console.log(eventKey, "eventKey");
    var myDate = new Date();
    var newdat = myDate.setDate(myDate.getDate() + parseInt(eventKey));
    var newdate = new Date(newdat);
    set_ExpiredDate(newdate);
    console.log(newdate, "newdatenewdate");
    setSelectedValue(eventKey);
  };

  const handle = useFullScreenHandle();
  console.log(walletConnection, "wdwdwd");

  const handleBuyNow = async () => {
    try {
      if (walletConnection.address) {
        setLoading(true);

        const userpayload = {
          contractAddress: getNft?.[0].contractAddress,
          nftId: getNft?.[0].nftId,
          address: walletConnection.address
        };
        const result = await checkforputonsale(userpayload);

        console.log(result, "datada");
        if (!result.data.status) {
          setLoading(false);

          toastAlert(
            "error",
            "Your not able to proceed this action please refresh your page"
          );

          setTimeout(() => {
            // window.location.reload()
          }, 2000);
          return false;
        }

        const web3 = new Web3(walletConnection.provider);

        let balVal = await web3.eth.getBalance(walletConnection.address);
        console.log(web3.utils.fromWei(balVal), "balVal");
        let balance = Number(web3.utils.fromWei(balVal));
        console.log(balance, "balance");

        const ContractNFT = new web3.eth.Contract(
          SingleNFTABI,
          getNft[0]?.contractAddress
        );

        console.log(ContractNFT, "ContractNFT");

        let serFee = await ContractNFT.methods.getServiceFee().call();
        let Service = serFee / 1e18;
        let feeval = (getNft[0]?.price * Service) / 100;
        var Prival = getNft[0]?.price * 1e18;
        Prival = Numberconvert(Prival);
        // Prival = ChecktokenDecimal(Prival, 1e18);
        var mulWei = (feeval + getNft[0]?.price) * 1e18;
        mulWei = ChecktokenDecimal(mulWei).then(async (val) => {
          var price = bignumber(getNft[0]?.price);
          price = multiply(price, 10 ** 18);

          serFee = bignumber(serFee);
          var servicefeecal = multiply(price, serFee);
          var per = bignumber(10 ** 20);
          var servicefeeper = divide(servicefeecal, per);

          var feewithPrice = add(price, servicefeeper);
          feewithPrice = feewithPrice.toString();
          console.log(balance, Number(feewithPrice) / 1e18, "BNfrnjewf");
          if (balance < Number(feewithPrice) / 1e18) {
            toastAlert("error", "Insufficient balance");
            setLoading(false);
            return false;
          }
          await ContractNFT.methods
            .saleToken(getNft?.[0]?.tokenOwner, nftId, price.toString())
            .send({ from: walletConnection.address, value: feewithPrice })
            .then(async (result) => {
              console.log(result, "result");
              const payload = {
                nftOwner: getNft?.[0]?.tokenOwner,
                userAddr: walletConnection.address,
                nftId: nftId,
                contractAddr: contractAddress,
                price: getNft?.[0]?.price,
                nftName: getNft?.[0]?.nftName,
                transactionHash: result.transactionHash,
                ipfsImage: getNft?.[0]?.ipfsimageUrl,
                image: getNft?.[0]?.localImage
              };
              const Resp = await PurchaseNFT(payload);
              console.log(Resp, "Resp");
              if (Resp.data.status == true) {
                // setLoading(false);
                toastAlert("success", Resp.data.messages);
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
            })
            .catch((err) => {
              setLoading(false);
              toastAlert("error", "Transaction rejected by user");
              console.log(err);
            });
        });
      } else {
        toastAlert("error", "Please connect wallet");
      }
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  const CancelSale = async () => {
    try {
      if (walletConnection.address) {
        setLoading(true);

        const userpayload = {
          address: walletConnection.address,
          nftId: getNft?.[0].nftId,
          contractAddress: getNft?.[0]?.contractAddress
        };
        const result = await checknftowner(userpayload);

        if (!result.data.status) {
          setLoading(false);
          toastAlert(
            "error",
            "Your not able to proceed this action please refresh your page"
          );
          setTimeout(() => {
            // window.location.reload()
          }, 2000);
          return false;
        }

        const web3 = new Web3(walletConnection.provider);
        const ContractNFT = new web3.eth.Contract(
          SingleNFTABI,
          getNft[0]?.contractAddress
        );

        console.log(ContractNFT, "ContractNFT");
        await ContractNFT.methods
          .cancelOrder(nftId)
          .send({ from: walletConnection.address })
          .then(async (result) => {
            const payload = {
              nftOwner: getNft?.[0]?.tokenOwner,
              userAddr: walletConnection.address,
              nftId: nftId,
              contractAddr: contractAddress,
              price: getNft?.[0]?.price,
              nftName: getNft?.[0]?.nftName,
              transactionHash: result.transactionHash,
              ipfsImage: getNft?.[0]?.ipfsimageUrl,
              image: getNft?.[0]?.localImage
            };
            const Resp = await CancelOrder(payload);
            console.log(Resp, "Resp");
            if (Resp.data.status == true) {
              setLoading(false);
              toastAlert("success", Resp.data.messages);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          })
          .catch((err) => {
            setLoading(false);
            toastAlert("error", "Transaction rejected by user");
            console.log(err);
          });
      } else {
        toastAlert("error", "Please connect wallet");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CancelAuction = async () => {
    try {
      if (walletConnection.address) {
        setLoading(true);

        const userpayload = {
          address: walletConnection.address,
          nftId: getNft?.[0].nftId,
          contractAddress: getNft?.[0]?.contractAddress
        };
        const result = await checknftowner(userpayload);

        if (!result.data.status) {
          setLoading(false);
          toastAlert(
            "error",
            "Your not able to proceed this action please refresh your page"
          );
          setTimeout(() => {
            // window.location.reload()
          }, 2000);
          return false;
        }

        const payload = {
          nftOwner: getNft?.[0]?.tokenOwner,
          userAddr: walletConnection.address,
          nftId: nftId,
          contractAddr: contractAddress,
          price: getNft?.[0]?.price,
          nftName: getNft?.[0]?.nftName,
          ipfsImage: getNft?.[0]?.ipfsimageUrl,
          image: getNft?.[0]?.localImage
        };
        const Resp = await CancelOwnAuction(payload);
        console.log(Resp, "Resp");
        if (Resp.data.status == true) {
          setLoading(false);
          toastAlert("success", Resp.data.messages);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        toastAlert("error", "Please connect wallet");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CancelBid = async () => {
    try {
      if (walletConnection.address) {
        setLoading(true);

        const userpayload = {
          contractAddress: getNft?.[0].contractAddress,
          nftId: getNft?.[0].nftId,
          address: walletConnection.address,
          tokenOwner: getNft?.[0].tokenOwner
        };
        const result = await checkforcancelBid(userpayload);

        console.log(result, "datada");
        if (!result.data.status) {
          setLoading(false);

          toastAlert(
            "error",
            "Your not able to proceed this action please refresh your page"
          );

          setTimeout(() => {
            // window.location.reload()
          }, 2000);
          return false;
        }

        const payload = {
          nftOwner: getNft?.[0]?.tokenOwner,
          userAddr: walletConnection.address,
          nftId: nftId,
          contractAddr: contractAddress,
          price: getNft?.[0]?.price,
          nftName: getNft?.[0]?.nftName,
          ipfsImage: getNft?.[0]?.ipfsimageUrl,
          image: getNft?.[0]?.localImage
        };
        const Resp = await CancelOwnBid(payload);
        console.log(Resp, "Resp");
        if (Resp.data.status == true) {
          setLoading(false);
          toastAlert("success", Resp.data.messages);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        toastAlert("error", "Please connect wallet");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const BurnNFT = async () => {
    try {
      if (walletConnection.address) {
        setLoading(true);

        const userpayload = {
          address: walletConnection.address,
          nftId: getNft?.[0].nftId,
          contractAddress: getNft?.[0]?.contractAddress
        };
        const result = await checknftowner(userpayload);

        if (!result.data.status) {
          setLoading(false);
          toastAlert(
            "error",
            "Your not able to proceed this action please refresh your page"
          );
          setTimeout(() => {
            // window.location.reload()
          }, 2000);
          return false;
        }

        const web3 = new Web3(walletConnection.provider);
        const ContractNFT = new web3.eth.Contract(
          SingleNFTABI,
          getNft[0]?.contractAddress
        );

        console.log(ContractNFT, "ContractNFT");

        await ContractNFT.methods
          .burn(nftId)
          .send({ from: walletConnection.address })
          .then(async (result) => {
            const payload = {
              nftOwner: getNft?.[0]?.tokenOwner,
              userAddr: walletConnection.address,
              nftId: nftId,
              contractAddr: contractAddress,
              price: getNft?.[0]?.price,
              nftName: getNft?.[0]?.nftName,
              transactionHash: result.transactionHash,
              ipfsImage: getNft?.[0]?.ipfsimageUrl,
              image: getNft?.[0]?.localImage
            };
            const Resp = await BurnNft(payload);
            console.log(Resp, "Resp");
            if (Resp.data.status == true) {
              // setLoading(false);
              toastAlert("success", Resp.data.messages);
              setTimeout(() => {
                window.location.href = "/";
              }, 2000);
            }
          })
          .catch((err) => {
            setLoading(false);
            toastAlert("error", "Transaction rejected by user");
            console.log(err);
          });
      } else {
        toastAlert("error", "Please connect wallet");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSwapOpen = async (flog) => {
    if (walletConnection.address) {
      setLoading(true);
      var payload = {
        nftId,
        contractAddress,
        flog
      };

      if (flog == 1) {
        const web3 = new Web3(walletConnection.provider);
        let NFTMintContract;

        const payload1 = {
          address: walletConnection.address,
          nftId: getNft?.[0].nftId,
          contractAddress: getNft?.[0]?.contractAddress
        };

        const result = await checknftowner(payload1);

        console.log(result, "req.body");

        if (!result.data.status) {
          setLoading(false);

          toastAlert(
            "error",
            "Your not able to proceed this action please refresh your page"
          );

          setTimeout(() => {
            // window.location.reload()
          }, 2000);
          return false;
        }

        if (getNft?.[0]?.isCollNft) {
          NFTMintContract = new web3.eth.Contract(
            CollABI,
            getNft?.[0]?.contractAddress
          );
        } else {
          NFTMintContract = new web3.eth.Contract(
            SingleNFTABI,
            getNft?.[0]?.contractAddress
          );
        }

        await NFTMintContract.methods
          .setApprovalForAll(config.swapContract, true)
          .send({ from: walletConnection.address })
          .then(async (resulr) => {
            const data = await swapOpenAction(payload);
            if (data?.data?.status) {
              toastAlert("success", data?.data?.message);
              // setLoading(false);
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
            toastAlert("error", "Sign rejected");
          });
      } else {
        const data = await swapOpenAction(payload);
        console.log(data, "efcewfcewf");
        if (data?.data?.status) {
          toastAlert("success", data?.data?.message);
          // setLoading(false);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      }
    } else {
      setLoading(false);
      toastAlert("error", "Please connect polygan network");
    }
  };

  const handleModalAccBid = async (bids) => {
    if (walletConnection.address) {
      console.log(
        bids,
        "bidsCheck",
        new Date().getTime(),
        new Date(bids?.ExpiredDate).getTime(),
        new Date().getTime() > new Date(bids?.ExpiredDate).getTime()
      );
      setBidAccepter(bids);
      if (bids?.TimedBid) {
        if (new Date().getTime() > new Date(bids?.ExpiredDate).getTime()) {
          toastAlert("error", "Bid expired");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          return false;
        }
      }
      window.$("#" + "acceptbid").modal("show");
    } else {
      toastAlert("error", "Please connect wallet");
    }
  };

  const handleBidAccept = async (bidData) => {
    if (walletConnection.address) {
      setLoading(true);

      const userpayload = {
        address: walletConnection.address,
        nftId: getNft?.[0].nftId,
        contractAddress: getNft?.[0]?.contractAddress
      };
      const result = await checknftowner(userpayload);

      if (!result.data.status) {
        setLoading(false);
        toastAlert(
          "error",
          "Your not able to proceed this action please refresh your page"
        );
        setTimeout(() => {
          // window.location.reload()
        }, 2000);
        return false;
      }

      const web3 = new Web3(walletConnection.provider);
      const ContractToken = new web3.eth.Contract(
        TokenABI,
        config.tokenContract
      );

      let decimal = await ContractToken.methods.decimals().call();
      let tokenBal = await ContractToken.methods
        .balanceOf(bidData.bidderAddress)
        .call();
      let wMaticBal = tokenBal / config.decimalValues;
      console.log(wMaticBal, "ContractToken", typeof wMaticBal);

      const ContractNFT = new web3.eth.Contract(
        SingleNFTABI,
        getNft[0]?.contractAddress
      );

      console.log(ContractNFT, "ContractNFT");
      let serFee = await ContractNFT.methods.getServiceFee().call();

      let Service = serFee / 1e18;
      let feeval = (Number(bidData.amount) * Service) / 100;
      console.log(feeval, "dfs");
      // var Prival = Number(bidData.amount) * 1e18;
      // Prival = Numberconvert(Prival);
      // var mulWei = (feeval + Number(bidData.amount)) * 1e18;
      // let PayVal = mulWei / 1e18;

      var Prival1 = bignumber(bidData.amount);
      Prival1 = multiply(Prival1, 10 ** 18);

      serFee = bignumber(serFee);
      var servicefeecal = multiply(Prival1, serFee);
      var per = bignumber(10 ** 20);
      var servicefeeper = divide(servicefeecal, per);
      var PrivalWithFee = add(Prival1, servicefeeper);
      var PayVal = divide(PrivalWithFee, 10 ** 18);

      if (wMaticBal >= PayVal) {
        await ContractNFT.methods
          .acceptBId(bidData.bidderAddress, Prival1.toString(), nftId)
          .send({ from: walletConnection.address })
          .then(async (result) => {
            console.log(result);
            console.log(result, "result");
            const payload = {
              nftOwner: getNft?.[0]?.tokenOwner,
              bidderAddress: bidData.bidderAddress,
              userAddr: walletConnection.address,
              nftId: nftId,
              contractAddr: contractAddress,
              price: bidData.amount,
              nftName: getNft?.[0]?.nftName,
              transactionHash: result.transactionHash,
              ipfsImage: getNft?.[0]?.ipfsimageUrl,
              image: getNft?.[0]?.localImage
            };
            console.log(payload, "sdfsdfsdf");
            const Resp = await bidAcceptAction(payload);
            console.log(Resp, "RespBidAccept");
            if (Resp.data.status == true) {
              // setLoading(false);
              toastAlert("success", Resp.data.messages);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        toastAlert("error", "Bidder doesnt have Enough token balance");
        setLoading(false);
      }
    } else {
      toastAlert("error", "Please connect wallet");
    }
  };

  async function ChecktokenDecimal(amount, decimals) {
    var result = 0;
    var decimalsLength = 18;
    if (decimals && decimals > 0) {
      decimalsLength = decimals;
    }
    try {
      var number = amount.toString();
      var number1 = number.split(".");
      if (number1[1] && number1[1].length && number1[1].length > 0) {
        result = number1[0];
      } else {
        result = amount;
      }
      return result;
    } catch (err) {
      return result;
    }
  }

  const handleSaleTypeChange = (e) => {
    setSaleType(e.target.value);
  };

  async function DateChange(from, val) {
    let CurrDate = new Date();
    // console.log(CurrDate.setMinutes(CurrDate.getMinutes() + 1), "CurrDate");
    // set_Clocktime(CurrDate.setSeconds(CurrDate.getSeconds() + 50));
    if (from == "StartDateDropDown") {
      if (val == "PickStartDate") {
        ModalAction("calendar_modal", "show");
      } else {
        Set_StartDate("Right after listing");
      }
    } else if (from == "EndDateDropDown") {
      if (val == "PickEndDate") {
        ModalAction("calendar_modal_expire", "show");
      } else {
        Set_EndDate(val);
        var myDate = new Date();
        var newdat = myDate.setDate(myDate.getDate() + parseInt(val));
        var newdate = new Date(newdat);
        set_EndClocktime(newdate);
      }
    }
  }
  async function ModalAction(id, type) {
    window.$("#" + id).modal(type);
    if (id == "calendar_modal") {
      if (Clocktime) {
        var dt = new Date(Clocktime);
        dt.setSeconds(0);
        var dt1 = dt.toLocaleString();
        Set_StartDate(dt1);
      }
    } else if (id == "calendar_modal_expire") {
      if (EndClocktime) {
        var dt = new Date(EndClocktime);
        dt.setSeconds(0);
        var dt1 = dt.toLocaleString();
        Set_EndDate(dt1);
      }
    }
  }
  const handlesetClocktime = (value) => {
    console.log(value, "Value");
    let currDate1 = new Date(value);
    set_Clocktime(value);
    let currDate = new Date();
    let sDate = new Date(value);
    // sDate.setSeconds(0);
    console.log(
      currDate.getTime() >= sDate.getTime(),
      new Date().toString().substring(0, 15),
      new Date().toLocaleString().toString().substring(9, 15),
      "currDate.getTime() >= sDate.getTime()"
    );
    if (currDate.getTime() >= sDate.getTime()) {
      setStartTimeErr("Start date & Time must be greater than now");
    } else {
      setStartTimeErr("");
    }
  };

  const handleendClocktime = (value) => {
    set_EndClocktime(value);
    console.log(Clocktime, "Clocktime");
    var startDate = new Date(Clocktime);
    var eDate = new Date(value);
    startDate.setSeconds(0);
    eDate.setSeconds(0);

    if (startDate.getTime() >= eDate.getTime()) {
      setendTimeErr("End date & Time must be greater than start date and Time");
    } else {
      setendTimeErr("");
    }
  };

  const openSwapCheck = (e) => {
    if (e && e.target && e.target.name) {
      if (e.target.name == "openSwap") {
        if (OpenSwap == false) {
          SetOpenSwap(true);
        } else {
          SetOpenSwap(false);
        }
      } else if (e.target.name == "closeSwap") {
        if (CloseSwap == false) {
          SetCloseSwap(true);
        } else {
          SetCloseSwap(false);
        }
      }
    }
  };
  const CheckedChange = (e) => {
    if (e && e.target && e.target.name) {
      if (e.target.name == "TimedBid") {
        if (TimedBid == false) {
          SetTimedBid(true);
        } else {
          SetTimedBid(false);
        }
      }
    }
  };

  const validateTimed = (value) => {
    let error;
    if (value > getNft?.[0]?.maximumBid) {
      error = "Value must be less than maximum bid";
    } else if (value < getNft?.[0]?.minimumBid) {
      error = "Value must be greater than minimum bid";
    }

    return error;
  };

  var currDate = new Date();
  var startdate = new Date(getNft?.[0]?.startTime);
  var enddate = new Date(getNft?.[0]?.endTime);
  var auction = "false";
  var finish = "";
  var enddate1 = "";
  var showlist = "true";
  var display_item = 0;
  var myqty = "";
  let mytr = "";

  if (getNft?.[0]?.PutOnSaleType == "TimedAuction") {
    auction = "true";

    console.log(getNft?.[0]?.startTime, getNft?.[0]?.endTime, "time");
    var a = moment(getNft?.[0]?.startTime);
    var b = moment(getNft?.[0]?.endTime);
    console.log(a, b, "a,b,b,bb");

    var c = moment();
    a.diff(b); // 86400000
    var diffInMs = a.diff(c);
    finish = b.diff(c);
    enddate1 = parseFloat(diffInMs);
    display_item = a.diff(c);

    // if(currDate<startdate){
    //   var auctionTxt = "Start";
    // }else
    if (finish > 0) {
      showlist = "true";
    } else {
      var auctionTxt = "Ended";
      showlist = "false";
    }
  }
  console.log(finish, display_item, "display_item");

  const SwapInit = async () => {
    if (walletConnection.address) {
      setBtnDisa(true);

      const payload = {
        address: walletConnection.address,
        nftId: getNft?.[0].nftId,
        contractAddress: getNft?.[0]?.contractAddress,
        tokenOwner: getNft?.[0].tokenOwner
      };

      const result = await checkForSwapInit(payload);

      if (!result.data.status) {
        setLoading(false);
        setBtnDisa(false);
        toastAlert("error", result.data.message);

        setTimeout(() => {
          // window.location.reload()
        }, 2000);
        return false;
      }

      const web3 = new Web3(walletConnection.provider);
      // const contract = new web3.eth.Contract(SwapABI, config.swapContract);
      let signer = walletConnection.address.toLowerCase();
      const messageHash = web3.utils.soliditySha3(Number(nftId));
      console.log(signer, messageHash, "saranRaj");
      await web3.eth.personal
        .sign(messageHash, signer)
        .then(async (signature) => {
          console.log(signature, "resultresult");
          const payload = {
            currAddr: walletConnection.address,
            sign: signature,
            nftId: nftId,
            condAddr: contractAddress,
            nftName: getNft?.[0]?.nftName
          };
          const signed = await swapSignInit(payload);
          if (signed?.data?.status) {
            toastAlert("success", signed?.data?.message);
            setTimeout(() => {
              window.location.reload();
              setBtnDisa(false);
            }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
          setBtnDisa(false);
        });
    } else {
      toastAlert("error", "Please connect wallet");
    }
  };

  const getSwapStates = async () => {
    if (walletConnection.address) {
      const payload = {
        address: walletConnection.address,
        nftId,
        contractAddress
      };
      const data = await getSwapsByCurrUser(payload);
      console.log(data, "SwapDtdt");
      setSwapStatus(data?.data?.data);
    }
  };

  const getSwapList = async () => {
    if (walletConnection.address) {
      const payload = {
        nftId,
        contractAddress
      };
      const data = await nftSwapList(payload);
      console.log(data, "SwapLIst");
      const fildata1 = data?.data?.data1.filter((el) => el.status == 0);
      const fildata = fildata1.filter(
        (el) => el.fromUser != walletConnection.address
      );
      const filteredItems = fildata.filter(
        (item) =>
          JSON.stringify(item).toLowerCase().indexOf(swapFilt.toLowerCase()) !==
          -1
      );
      setSwapList(filteredItems);
    }
  };

  const handleChg = async (data) => {
    console.log(data, "datawqedw");
    setSwapUser(data);
  };

  const checkReportNFT = async () => {
    const payload = {
      nftId,
      contractAddress,
      address: getUsers.curraddress
    };
    const result = await checkNFTreport(payload);
    console.log(result, "resultresultresultresult11111111111111111111");
    if (result.status) {
      toastAlert("error", result.message);
    } else {
      // Reportmodelref.current.focus()
      window.$("#" + "Reportconfirm").modal("show");
    }
  };

  const swapFunction = async () => {
    try {
      const userpayload = {
        address: walletConnection.address,
        nftId: getNft?.[0].nftId,
        contractAddress: getNft?.[0]?.contractAddress
      };
      const result = await checknftowner(userpayload);
      console.log(result, "reult");
      if (!result.data.status) {
        setLoading(false);
        toastAlert(
          "error",
          "Your not able to proceed this action please refresh your page"
        );
        setTimeout(() => {
          // window.location.reload()
        }, 2000);
        return false;
      }
      $("#swap").modal("show");
    } catch (error) {
      console.log(error);
    }
  };
  console.log(display_item, showlist, "display_item");

  return (
    <div>
      <Navbar />
      <div className="page_box">
        <div className="nft">
          <div className="container">
            <div className="nft_box">
              <div className="nft_img">
                <FullScreen handle={handle}>
                  {getNft?.[0]?.localImage.split(".").pop() == "mp4" ? (
                    <>
                      {getNft?.[0]?.isCollNft ? (
                        <video
                          src={`${config.baseUrl}/${getNft?.[0]?.localImage}`}
                          style={{ width: "100%" }}
                          type="video/mp4"
                          alt="Collections"
                          className="lazy nft__item_preview nft__item_preview_video"
                          controls
                          controlsList="nodownload"
                        />
                      ) : (
                        <video
                          src={`${config.baseUrl}/tokens/${getNft?.[0]?.localImage}`}
                          style={{ width: "100%" }}
                          type="video/mp4"
                          alt="Collections"
                          // className="lazy nft__item_preview nft__item_preview_video"
                          className={
                            getNft?.[0]?.sensitiveContent
                              ? "lazy nft__item_preview nft__item_preview_video blue_img"
                              : "lazy nft__item_preview nft__item_preview_video"
                          }
                          controls
                          controlsList="nodownload"
                        />
                      )}
                    </>
                  ) : getNft?.[0]?.localImage.split(".").pop() == "mp3" ? (
                    <>
                      {getNft?.[0]?.isCollNft ? (
                        <div style={{ textAlign: "center" }}>
                          <img
                            style={{
                              padding: "16px",
                              width: "256px",
                              height: "256px"
                            }}
                            src={config.baseUrl + "/" + "images/music.png"}
                            alt="img"
                            className="img-fluid"
                          />
                          <audio
                            src={`${config.baseUrl}/${getNft?.[0]?.localImage}`}
                            type="audio/mp3"
                            controls
                            controlsList="nodownload"
                            className="audio home"
                            // className={getNft?.[0]?.sensitiveContent?"audio home blue_img":"audio home"}
                          ></audio>
                        </div>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          <img
                            style={{
                              padding: "16px",
                              width: "256px",
                              height: "256px"
                            }}
                            src={config.baseUrl + "/" + "images/music.png"}
                            alt="img"
                            className="img-fluid"
                          />
                          <audio
                            src={`${config.baseUrl}/tokens/${getNft?.[0]?.localImage}`}
                            type="audio/mp3"
                            controls
                            controlsList="nodownload"
                            className="audio home"
                            // className={getNft?.[0]?.sensitiveContent?"audio home blue_img":"audio home"}
                          ></audio>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {getNft?.[0]?.isCollNft ? (
                        <img
                          src={`${config.baseUrl}/${getNft?.[0]?.localImage}`}
                          alt="img"
                          className="img-fluid"
                        />
                      ) : (
                        <img
                          src={`${config.baseUrl}/tokens/${getNft?.[0]?.localImage}`}
                          alt="img"
                          // className="img-fluid"
                          className={
                            getNft?.[0]?.sensitiveContent
                              ? "img-fluid blue_img"
                              : "img-fluid"
                          }
                        />
                      )}
                    </>
                  )}

                  <button onClick={handle.exit} className="primary_btn dark">
                    Exit
                  </button>
                </FullScreen>
                <img
                  src={full_screen}
                  alt="img"
                  className="img-fluid fullscrn"
                  onClick={handle.enter}
                />
              </div>

              <h2 className="h2tag">{getNft?.[0]?.nftName}</h2>
              <div className="nft-detail">
                <p className="para">
                  By{" "}
                  <a>
                    {" "}
                    {getNft?.[0]?.nftUser?.username
                      ? getNft?.[0]?.nftUser?.username
                      : halfAddrShow(getNft?.[0]?.nftUser?.curraddress)}
                  </a>
                </p>
                <img
                  src={
                    getNft?.[0]?.nftUser?.image
                      ? config.baseUrl +
                        "/usersProfile/" +
                        getNft?.[0]?.nftUser?.image
                      : require("../assets/images/LogosSampleAvator.png")
                  }
                  style={{ borderRadius: "50px" }}
                  alt="img"
                  className="img-fluid ms-3"
                />
              </div>
              {/* Swap Item btn  */}
              {getNft?.[0]?.swapEnable ? (
                <>
                  {" "}
                  {getNft?.[0]?.tokenOwner == walletConnection.address ? (
                    getNft?.[0]?.swapEnable ? (
                      <>
                        <button
                          className="primary_btn mt-5"
                          data-bs-toggle="modal"
                          data-bs-target="#swapconfirm"
                        >
                          <span>Swap Item</span>
                          <img
                            src={btnarw}
                            alt="img"
                            className="img-fluid ms-3"
                          />
                        </button>
                        <div className="create_nft p-0">
                          <div className="mb-4 mt-4">
                            <div className="form-check form-switch d-inline-flex">
                              <p className="pe-3">Close for swap</p>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                id="closeSwap"
                                name="closeSwap"
                                onChange={openSwapCheck}
                              />
                            </div>
                          </div>
                          {CloseSwap && (
                            <button
                              onClick={() => handleSwapOpen(2)}
                              className="primary_btn dark"
                            >
                              Swap Close
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="create_nft p-0">
                        <div className="mb-4 mt-4">
                          <div className="form-check form-switch d-inline-flex">
                            <p className="pe-3">Open for swap</p>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="openSwap"
                              name="openSwap"
                              onChange={openSwapCheck}
                            />
                          </div>
                          <button>Submit</button>
                        </div>
                      </div>
                    )
                  ) : (
                    <>
                      {swapStatus?.fromUser == walletConnection.address &&
                      swapStatus.initiative ? (
                        <button
                          className="primary_btn mt-5"
                          // onClick={SwapInit}
                          // data-bs-toggle="modal"
                          // data-bs-target="#swapconfirm"
                        >
                          <span>Swap Pending</span>
                          {/* <img src={btnarw} alt="img" className="img-fluid ms-3" /> */}
                        </button>
                      ) : (
                        <>
                          <button
                            className="primary_btn mt-5 dark"
                            onClick={SwapInit}
                            disabled={btnDisCli}
                            // data-bs-toggle="modal"
                            // data-bs-target="#swapconfirm"
                          >
                            <span>Swap Initiative</span>
                            <img
                              src={btnarw_white}
                              alt="img"
                              className="img-fluid ms-3"
                            />
                          </button>
                        </>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {getNft?.[0]?.tokenOwner == walletConnection.address && (
                    <div className="create_nft p-0">
                      <div className="mb-4 mt-4">
                        <div
                          className="form-check form-switch d-inline-flex"
                          style={{ marginRight: "10px" }}
                        >
                          <p className="pe-3">Open for swap</p>
                          <input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            id="openSwap"
                            name="openSwap"
                            onChange={openSwapCheck}
                          />
                        </div>
                      </div>
                      {OpenSwap && (
                        <button
                          onClick={() => handleSwapOpen(1)}
                          className="primary_btn"
                        >
                          Swap Open
                        </button>
                      )}
                    </div>
                  )}
                </>
              )}

              {/* Open for swap btn  */}
            </div>

            <div className="row">
              <div className="col-lg-7">
                <div className="explore">
                  <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active ps-0"
                        id="one-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#one"
                        type="button"
                        role="tab"
                        aria-controls="one"
                        aria-selected="true"
                      >
                        Description
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="two-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#two"
                        type="button"
                        role="tab"
                        aria-controls="two"
                        aria-selected="false"
                      >
                        Bids
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="three-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#three"
                        type="button"
                        role="tab"
                        aria-controls="three"
                        aria-selected="false"
                      >
                        Activity
                      </button>
                    </li>

                    {getNft &&
                    getNft?.[0]?.tokenOwner == walletConnection.address &&
                    getNft?.[0]?.Unlockoncepurchased == true ? (
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link "
                          id="four-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#four"
                          type="button"
                          role="tab"
                          aria-controls="four"
                          aria-selected="true"
                        >
                          Unlock Content
                        </button>
                      </li>
                    ) : (
                      ""
                    )}
                  </ul>

                  <div className="tab-content">
                    <div
                      className="tab-pane fade show active"
                      id="one"
                      role="tabpanel"
                      aria-labelledby="one-tab"
                    >
                      <div className="prf_ban">
                        <p className="des mt-4">{getNft?.[0]?.nftDesc}</p>
                        <div className="details">
                          <h5>Details</h5>
                          <div className="flx">
                            <p>
                              <img
                                src={eth}
                                alt="img"
                                className="img-fluid me-3"
                              />
                              MATIC (ERC-721)
                            </p>
                            <span className="arw"></span>

                            <Link
                              to={
                                config.Scan + "/" + getNft?.[0]?.mintHashValue
                              }
                              target="_blank"
                              style={{ color: "#1d1d1d" }}
                            >
                              <p>
                                View on matic scan
                                <img
                                  src={view}
                                  alt="img"
                                  className="img-fluid ms-2"
                                />
                              </p>
                            </Link>
                          </div>
                        </div>

                        <div className="details">
                          <h5>Category</h5>
                          <div className="flx">
                            <p>{getNft?.[0]?.categoryid}</p>
                          </div>
                        </div>
                        <div className="prf_detail">
                          <div className="btn_flx flex-row">
                            <button
                              onClick={() => window.location.reload(true)}
                              className="primary_btn mb-0"
                            >
                              <span>Refresh metadata</span>
                              <img
                                src={refresh}
                                alt="img"
                                className="img-fluid ms-3"
                              />
                            </button>
                            <div className="d-flex  align-items-center">
                              <div className="dropdown  mb-0">
                                <button
                                  className="btn btn-secondary dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton2"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <img
                                    src={upload}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                </button>
                                <ul
                                  class="dropdown-menu"
                                  aria-labelledby="dropdownMenuButton2"
                                >
                                  <div style={{ display: "flex" }}>
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        href={`https://www.facebook.com/sharer/sharer.php?u=${config.frontUrl}/nft-detail/${contractAddress}/${nftId}`}
                                        target="_blank"
                                      >
                                        <img
                                          src={Facebook}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        href={`https://twitter.com/share?url=${config.frontUrl}/nft-detail/${contractAddress}/${nftId}`}
                                        target="_blank"
                                      >
                                        <img
                                          src={twitter}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        class="dropdown-item"
                                        href={`http://pinterest.com/pin/create/button/?url=${config.frontUrl}/nft-detail/${contractAddress}/${nftId}`}
                                        target="_blank"
                                      >
                                        <img
                                          src={instagram}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </a>
                                    </li>
                                    {/* <li>
                                    <a
                                      class="dropdown-item"
                                      href="#" 
                                    >
                                      <img
                                      src={upload}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                      Face Book
                                    </a>
                                  </li> */}
                                  </div>
                                </ul>
                              </div>
                              {/* <ul className="social mb-0  mb-0">
                                <li>
                                  <a href="#" target="_blank">
                                    <img
                                      src={upload}
                                      alt="img"
                                      className="img-fluid"
                                    />
                                  </a>
                                </li>
                              </ul> */}
                              {walletConnection.address ==
                              getNft?.[0]?.tokenOwner ? (
                                <> </>
                              ) : (
                                <>
                                  <div class="dropdown mb-0">
                                    <button
                                      class="btn btn-secondary dropdown-toggle"
                                      type="button"
                                      id="dropdownMenuButton1"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <img
                                        src={option}
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </button>
                                    <ul
                                      class="dropdown-menu"
                                      aria-labelledby="dropdownMenuButton1"
                                    >
                                      <li>
                                        <a
                                          class="dropdown-item"
                                          href="#"
                                          onClick={() => checkReportNFT()}
                                          // data-bs-toggle="modal"
                                          // data-bs-target="#Reportconfirm"
                                        >
                                          Report NFT
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="two"
                      role="tabpanel"
                      aria-labelledby="two-tab"
                    >
                      {/* norecords */}
                      {/* <p className="norecords mt-4" >  No bids yet</p> */}
                      {biddingData && biddingData.length > 0 ? (
                        <>
                          {biddingData.map((bid) => {
                            let displayTime = 0;
                            let finish = "";
                            if (bid.TimedBid) {
                              var a = moment(bid.createdAt);
                              var b = moment(bid.ExpiredDate);
                              console.log(a, b, "ccccccccccc");
                              var c = moment();
                              a.diff(b);

                              finish = b.diff(c);

                              displayTime = a.diff(c);
                              console.log(finish, "finish", displayTime);
                            }
                            return (
                              <>
                                <div className="div_flx mt-4">
                                  <div className="d-flex">
                                    <img
                                      src={require("../assets/images/nft_activity_user.png")}
                                      alt="img"
                                      className="img-fluid me-3"
                                    />
                                    <div className="fl_x">
                                      <div>
                                        <h6>
                                          {halfAddrShow(bid.bidderAddress)}{" "}
                                          Placed a bid{" "}
                                          <span>
                                            {Numberconvert(bid?.amount)}{" "}
                                            {config.TokenCurrency}
                                            {/* ($54,430) */}
                                          </span>
                                        </h6>
                                        <p>
                                          {moment(bid?.createdAt).format("lll")}
                                          {bid.TimedBid &&
                                            (bid?.bidderAddress ==
                                              walletConnection.address ||
                                              getNft?.[0]?.tokenOwner ==
                                                walletConnection.address) && (
                                              <span className="bidTimeCount">
                                                <Countdown
                                                  date={b}
                                                  autoStart={true}
                                                  onStart={() => a}
                                                  renderer={renderer3}
                                                />
                                              </span>
                                            )}
                                        </p>
                                      </div>
                                      {bid.bidderAddress !=
                                        walletConnection.address &&
                                        getNft?.[0]?.tokenOwner ==
                                          walletConnection.address && (
                                          <div>
                                            <button
                                              className="primary_btn"
                                              onClick={() =>
                                                handleModalAccBid(bid)
                                              }
                                            >
                                              <span> Accept bid </span>
                                              <img
                                                src={btnarw}
                                                alt="img"
                                                className="img-fluid ms-3"
                                              />
                                            </button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                {/* )} */}
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <p className="norecords mt-4"> No bids yet</p>
                      )}
                    </div>
                    <div
                      className="tab-pane fade"
                      id="three"
                      role="tabpanel"
                      aria-labelledby="three-tab"
                    >
                      {NFTHis && NFTHis.length > 0 ? (
                        <>
                          {NFTHis.map((history) => {
                            return (
                              <div className="div_flx mt-4">
                                <div className="d-flex">
                                  {history.image.split(".").pop() == "mp4" ? (
                                    <video
                                      src={history.ipfsimage}
                                      style={{
                                        width: "103px",
                                        marginRight: "10px"
                                      }}
                                      type="video/mp4"
                                      alt="Collections"
                                      className="lazy nft__item_preview nft__item_preview_video"
                                      controls
                                      controlsList="nodownload"
                                    />
                                  ) : history.image.split(".").pop() ==
                                    "mp3" ? (
                                    <audio
                                      src={history.ipfsimage}
                                      style={{
                                        width: "123px",
                                        marginRight: "10px"
                                      }}
                                      type="audio/mp3"
                                      controls
                                      controlsList="nodownload"
                                      className="audio home"
                                    ></audio>
                                  ) : (
                                    <img
                                      src={history.ipfsimage}
                                      // style={{
                                      //   width: "103px",
                                      //   marginRight: "10px"
                                      // }}
                                      alt="img"
                                      className="img-fluid me-3"
                                    />
                                  )}
                                  <div className="fl_x">
                                    <div>
                                      <h5>
                                        {history?.actiontype == 6
                                          ? halfAddrShow(history.useraddress) +
                                            " to " +
                                            halfAddrShow(history.touseraddress)
                                          : history?.actiontype == 8
                                          ? halfAddrShow(history.useraddress) +
                                            " to " +
                                            halfAddrShow(history.touseraddress)
                                          : history?.actiontype == 9
                                          ? halfAddrShow(history.useraddress) +
                                            " to " +
                                            halfAddrShow(history.touseraddress)
                                          : halfAddrShow(history.useraddress)}
                                        <span
                                          style={{
                                            fontWeight: "600",
                                            fontSize: "10px"
                                          }}
                                          className="purchased"
                                        >
                                          {history?.actiontype == 0
                                            ? "Mint NFT"
                                            : history?.actiontype == 1
                                            ? "Listing"
                                            : history?.actiontype == 2
                                            ? "Price Change"
                                            : history?.actiontype == 3
                                            ? "Place Bid"
                                            : history?.actiontype == 4
                                            ? "Edit Bid"
                                            : history?.actiontype == 5
                                            ? "Cancel Bid"
                                            : history?.actiontype == 6
                                            ? "Transfer NFT"
                                            : history?.actiontype == 7
                                            ? "Burn NFT"
                                            : history?.actiontype == 8
                                            ? "Accept Bid"
                                            : history?.actiontype == 9
                                            ? "Purchase NFT"
                                            : history?.actiontype == 10
                                            ? "Cancel Sale"
                                            : history?.actiontype == 11
                                            ? "Bid Expired"
                                            : history?.actiontype == 12
                                            ? "Listing (Timed Auction)"
                                            : history?.actiontype == 13
                                            ? "Cancel (Timed Auction)"
                                            : ""}
                                        </span>
                                      </h5>
                                      <p>
                                        {" "}
                                        {moment(history.createdAt).format(
                                          "lll"
                                        )}
                                      </p>
                                    </div>
                                    <div>
                                      {history.amount && (
                                        <h5>
                                          {Numberconvert(history.amount)}{" "}
                                          {history?.actiontype == 3 ||
                                          history?.actiontype == 4 ||
                                          history?.actiontype == 5 ||
                                          history?.actiontype == 8 ||
                                          history?.actiontype == 11 ||
                                          history?.actiontype == 12 ||
                                          history?.actiontype == 13
                                            ? config.TokenCurrency
                                            : config.Currency}
                                        </h5>
                                      )}

                                      {/* <p>$61,320</p> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <p className="norecords mt-4"> No Activities yet</p>
                      )}
                    </div>
                    {getNft?.[0] &&
                    getNft?.[0]?.tokenOwner == walletConnection.address &&
                    getNft?.[0]?.Unlockoncepurchased == true ? (
                      <div
                        className="tab-pane fade"
                        id="four"
                        role="tabpanel"
                        aria-labelledby="four-tab"
                      >
                        {getNft?.[0] && getNft?.[0]?.unlockContent != "" ? (
                          <div>
                            <div className="prf_ban">
                              <p className="des mt-4">
                                {getNft?.[0]?.unlockContent}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p className="norecords mt-4">
                            {" "}
                            No UnlockContent yet
                          </p>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {localStorage.getItem("nicholousWallAddr") ? (
                <div className="col-lg-5">
                  <div className="price_box">
                    <div className="h_flx">
                      <p>Price</p>
                      {getNft?.[0]?.price > 0 ? (
                        <h6>
                          {Numberconvert(getNft?.[0]?.price)} {config.Currency}
                          <span>
                            (${(conversionRate * getNft?.[0]?.price).toFixed(3)}
                            )
                          </span>
                        </h6>
                      ) : (
                        <h6>Not for sale</h6>
                      )}
                    </div>
                    <div className="b_flx">
                      <div>
                        <span>Highest bid</span>
                        <p>
                          {getNft?.[0]?.highestBid ? (
                            <>
                              {Numberconvert(
                                getNft?.[0]?.highestBid?.maxAmount
                              )}{" "}
                              {config.Currency}{" "}
                            </>
                          ) : (
                            "Not yet"
                          )}{" "}
                        </p>
                      </div>
                      {getNft?.[0]?.price > 0 && (
                        <div>
                          <span>Price</span>
                          <p>
                            {Numberconvert(getNft?.[0]?.price)}{" "}
                            {config.Currency}
                          </p>
                        </div>
                      )}
                    </div>

                    {CountdownState == true ? (
                      <>
                        {" "}
                        {getNft?.[0]?.PutOnSaleType == "TimedAuction" && (
                          // showlist == "true" &&
                          <div
                            style={{ textAlign: "center" }}
                            className="primary_btn dark w-100 mb-4"
                          >
                            <Countdown
                              date={enddate}
                              autoStart={true}
                              onStart={() => startdate}
                              renderer={renderer}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {getNft?.[0]?.PutOnSaleType == "TimedAuction" && (
                          // showlist == "true" &&
                          // display_item > 0 &&
                          <div
                            style={{ textAlign: "center" }}
                            className="primary_btn dark w-100 mb-4"
                          >
                            <Countdown
                              date={startdate}
                              autoStart={true}
                              onStart={() => Date.now()}
                              renderer={renderer1}
                              onComplete={() => setCountdownState(true)}
                            />
                          </div>
                        )}
                      </>
                    )}

                    {getNft?.[0]?.PutOnSaleType == "TimedAuction" ? (
                      <>
                        {timdputonsale == true &&
                          showlist == "true" &&
                          getNft?.[0]?.tokenOwner !== getUsers?.curraddress && (
                            <button
                              className="primary_btn w-100 mb-4"
                              data-bs-toggle="modal"
                              data-bs-target="#placebid"
                            >
                              <span>
                                {biddingData.filter(
                                  (e) =>
                                    e.bidderAddress == walletConnection.address
                                ).length > 0
                                  ? "Edit a bid"
                                  : "Place a bid"}
                              </span>
                              <img
                                src={btnarw}
                                alt="img"
                                className="img-fluid ms-3"
                              />
                            </button>
                          )}
                        {getNft?.[0]?.tokenOwner == getUsers?.curraddress && (
                          <>
                            {" "}
                            {showlist == "false" ? (
                              <button
                                className="primary_btn w-100 mb-4"
                                data-bs-toggle="modal"
                                data-bs-target="#change_price"
                              >
                                <span>Put on sale</span>
                                <img
                                  src={btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            ) : (
                              <button
                                className="primary_btn w-100 mb-4"
                                data-bs-toggle="modal"
                                data-bs-target="#cancel_auction"
                              >
                                <span>Cancel Auction</span>
                                <img
                                  src={btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            )}
                          </>
                        )}

                        {biddingData.filter(
                          (e) => e.bidderAddress == walletConnection.address
                        ).length > 0 && (
                          <button
                            className="primary_btn w-100 mb-4"
                            data-bs-toggle="modal"
                            data-bs-target="#cancel_bid"
                          >
                            <span>Cancel Bid</span>
                            <img
                              src={btnarw}
                              alt="img"
                              className="img-fluid ms-3"
                            />
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        {getNft?.[0]?.tokenOwner == getUsers?.curraddress ? (
                          <>
                            <button
                              className="primary_btn w-100 mb-4"
                              data-bs-toggle="modal"
                              data-bs-target="#transfer_burn"
                            >
                              <span>Transfer or Burn Item</span>
                              <img
                                src={btnarw}
                                alt="img"
                                className="img-fluid ms-3"
                              />
                            </button>
                            {getNft?.[0]?.price > 0 ? (
                              <>
                                <button
                                  className="primary_btn w-100 mb-4"
                                  data-bs-toggle="modal"
                                  data-bs-target="#change_price1"
                                >
                                  <span>Change price</span>
                                  <img
                                    src={btnarw}
                                    alt="img"
                                    className="img-fluid ms-3"
                                  />
                                </button>
                                <button
                                  className="primary_btn w-100 mb-4"
                                  data-bs-toggle="modal"
                                  data-bs-target="#cancel_sale"
                                >
                                  <span>Cancel Sale</span>
                                  <img
                                    src={btnarw}
                                    alt="img"
                                    className="img-fluid ms-3"
                                  />
                                </button>
                              </>
                            ) : (
                              <button
                                className="primary_btn w-100 mb-4"
                                data-bs-toggle="modal"
                                data-bs-target="#change_price"
                              >
                                <span>Put on sale</span>
                                <img
                                  src={btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            )}
                          </>
                        ) : (
                          <>
                            {getNft?.[0]?.price > 0 && (
                              <button
                                className="primary_btn dark w-100 mb-4 px-1"
                                onClick={handleBuyNow}
                              >
                                <span>
                                  Buy now at {Numberconvert(getNft?.[0]?.price)}{" "}
                                  {config.Currency}
                                </span>
                                <img
                                  src={btnarw_white}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            )}
                            {walletConnection.address ? (
                              <button
                                className="primary_btn w-100 mb-4"
                                data-bs-toggle="modal"
                                data-bs-target="#placebid"
                              >
                                <span>
                                  {biddingData.filter(
                                    (e) =>
                                      e.bidderAddress ==
                                      walletConnection.address
                                  ).length > 0
                                    ? "Edit a bid"
                                    : "Place a bid"}
                                </span>
                                <img
                                  src={btnarw}
                                  alt="img"
                                  className="img-fluid ms-3"
                                />
                              </button>
                            ) : (
                              <></>
                            )}
                            {biddingData.filter(
                              (e) => e.bidderAddress == walletConnection.address
                            ).length > 0 && (
                              <>
                                <button
                                  className="primary_btn w-100 mb-4"
                                  data-bs-toggle="modal"
                                  data-bs-target="#cancel_bid"
                                >
                                  <span>Cancel Bid</span>
                                  <img
                                    src={btnarw}
                                    alt="img"
                                    className="img-fluid ms-3"
                                  />
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <h3 style={{ textAlign: "center" }}>Please connect wallet</h3>
              )}
            </div>
          </div>
        </div>

        <section className="popular_nft more_nft">
          <div className="container">
            <h2 className="h2tag">More from this collection</h2>
            <div className="popular_nft_scroll">
              <div className="row">
                {moreNfts.length > 0 &&
                  moreNfts.map((nft) => {
                    return (
                      <div className="col-lg-4 col-xl-3">
                        <div
                          className="box"
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            (window.location.href = `/nft-detail/${nft.contractAddress}/${nft.nftId}`)
                          }
                        >
                          <div className="img_box">
                            {nft.localImage.split(".").pop() == "mp4" ? (
                              <>
                                {nft.isCollNft ? (
                                  <video
                                    src={`${config.baseUrl}/${nft.localImage}`}
                                    style={{ width: "100%" }}
                                    type="video/mp4"
                                    alt="Collections"
                                    className="lazy nft__item_preview nft__item_preview_video"
                                    controls
                                    controlsList="nodownload"
                                  />
                                ) : (
                                  <video
                                    src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                    style={{ width: "100%" }}
                                    type="video/mp4"
                                    alt="Collections"
                                    className={
                                      nft.sensitiveContent
                                        ? "lazy nft__item_preview nft__item_preview_video blue_img"
                                        : "lazy nft__item_preview nft__item_preview_video"
                                    }
                                    // className="lazy nft__item_preview nft__item_preview_video"
                                    controls
                                    controlsList="nodownload"
                                  />
                                )}
                              </>
                            ) : nft.localImage.split(".").pop() == "mp3" ? (
                              <>
                                {nft.isCollNft ? (
                                  <div style={{ textAlign: "center" }}>
                                    <img
                                      style={{
                                        padding: "16px",
                                        width: "256px",
                                        height: "256px"
                                      }}
                                      src={
                                        config.baseUrl +
                                        "/" +
                                        "images/music.png"
                                      }
                                      alt="img"
                                      className="img-fluid"
                                    />
                                    <audio
                                      src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                      type="audio/mp3"
                                      controls
                                      controlsList="nodownload"
                                      className="audio home"
                                    ></audio>
                                  </div>
                                ) : (
                                  <div style={{ textAlign: "center" }}>
                                    <img
                                      style={{
                                        padding: "16px",
                                        width: "256px",
                                        height: "256px"
                                      }}
                                      src={
                                        config.baseUrl +
                                        "/" +
                                        "images/music.png"
                                      }
                                      alt="img"
                                      className="img-fluid"
                                    />
                                    <audio
                                      src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                      type="audio/mp3"
                                      controls
                                      controlsList="nodownload"
                                      className="audio home"
                                    ></audio>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {nft.isCollNft ? (
                                  <img
                                    src={`${config.baseUrl}/${nft.localImage}`}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                ) : (
                                  <img
                                    src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                    alt="img"
                                    // className="img-fluid"
                                    className={
                                      nft.sensitiveContent
                                        ? "img-fluid blue_img"
                                        : "img-fluid"
                                    }
                                  />
                                )}
                              </>
                            )}
                          </div>
                          <div className="blw_box">
                            <h5>{nft?.nftName}</h5>
                            <div className="flx_ctn">
                              <div className="mb-3">
                                <span>Price</span>
                                <h6>
                                  {" "}
                                  {Numberconvert(nft.price)} {config.Currency}
                                </h6>
                              </div>
                              <div>
                                <span>Highest bid</span>
                                <p>
                                  {nft.highestBid ? (
                                    <>
                                      {Numberconvert(nft.highestBid.maxAmount)}{" "}
                                      {config.Currency}
                                    </>
                                  ) : (
                                    "Not yet"
                                  )}
                                </p>
                                {/* <p>4.56 </p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="text-center">
              {moreNfts?.length > 0 && (
                <Link to={`/collection/${getNft?.[0]?.contractAddress}`}>
                  <button className="primary_btn">
                    <span> View collection</span>
                    <img src={btnarw} alt="img" className="ms-3 img-fluid" />
                  </button>
                </Link>
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />

      {/* <!-- placebid Modal -->  */}
      <div
        class="modal fade modal-md placebid_modal"
        id="placebid"
        tabIndex={-1}
        aria-labelledby="placebid_Label"
        aria-hidden="true"
      >
        <Formik
          initialValues={{ nftBidPrice: "" }}
          validationSchema={
            getNft?.[0]?.PutOnSaleType == "TimedAuction"
              ? BidSchema1
              : BidSchema
          }
          onSubmit={async ({ nftBidPrice }) => {
            console.log(nftBidPrice, "nftBidPrice");
            if (walletConnection.address) {
              if (TimedBid) {
                if (selectedValue == "Choose your bid expiration date") {
                  toastAlert("error", "Please select expire bid date");
                  setLoading(false);
                  return false;
                }
              }

              const payload = {
                address: walletConnection.address,
                nftId: getNft?.[0]?.nftId,
                contractAddress: getNft?.[0]?.contractAddress,
                tokenOwner: getNft?.[0].tokenOwner
              };

              const data = await checkforplaceBid(payload);

              console.log(data, "placeaBId");

              if (!data.data.status) {
                setLoading(false);

                toastAlert(
                  "error",
                  "Your not able to proceed this action please refresh your page"
                );

                setTimeout(() => {
                  // window.location.reload()
                }, 2000);
                return false;
              }

              setLoading(true);
              const web3 = new Web3(walletConnection.provider);
              const ContractToken = new web3.eth.Contract(
                TokenABI,
                config.tokenContract
              );

              let decimal = await ContractToken.methods.decimals().call();
              let tokenBal = await ContractToken.methods
                .balanceOf(walletConnection.address)
                .call();
              let wMaticBal = tokenBal / config.decimalValues;
              console.log(wMaticBal, "ContractToken", typeof wMaticBal);

              const ContractNFT = new web3.eth.Contract(
                SingleNFTABI,
                getNft[0]?.contractAddress
              );

              console.log(ContractNFT, "ContractNFT");

              let serFee = await ContractNFT.methods.getServiceFee().call();
              let Service = serFee / 1e18;
              let feeval = (Number(nftBidPrice) * Service) / 100;
              console.log(feeval, "dfs");
              var Prival = Number(nftBidPrice) * 1e18;
              Prival = Numberconvert(Prival);
              var mulWei = (feeval + Number(nftBidPrice)) * 1e18;
              let PayVal = mulWei / 1e18;
              // mulWei = ChecktokenDecimal(mulWei, 1e18);
              console.log(
                mulWei,
                "mulWei",
                (feeval + Number(nftBidPrice)) * 1e18,
                feeval,
                Number(nftBidPrice)
              );

              if (wMaticBal == 0) {
                toastAlert("error", "Insufficient token balance");
                setLoading(false);
                return false;
              }
              if (PayVal > wMaticBal) {
                toastAlert("error", "Your token balance is low");
                setLoading(false);
                return false;
              }

              let getAllowance = await ContractToken.methods
                .allowance(
                  walletConnection.address,
                  getNft?.[0]?.contractAddress
                )
                .call();

              console.log(getAllowance, "getAllowance");

              let sendVal = Number(mulWei / 1e18) + Number(getAllowance / 1e18);
              sendVal = Number(sendVal) * 1e18;
              sendVal = Numberconvert(sendVal);
              sendVal = ChecktokenDecimal(sendVal, config.decimalValues).then(
                async (value) => {
                  console.log(value, "SendVA");
                  await ContractToken.methods
                    .approve(getNft?.[0]?.contractAddress, value.toString())
                    .send({ from: walletConnection.address })
                    .then(async (result) => {
                      const payload = {
                        nftId: nftId,
                        nftBidAddr: walletConnection.address,
                        userAddr: walletConnection.address,
                        nftBidAmt: nftBidPrice.toString(),
                        transactionHash: result.transactionHash,
                        contractAddress: contractAddress,
                        ExpiredDate: TimedBid ? ExpiredDate : "",
                        TimedBid: TimedBid
                      };
                      console.log(payload);
                      const Resp = await PlaceABid(payload);
                      if (Resp.data.status == true) {
                        // setLoading(false);
                        toastAlert("success", Resp.data.messages);
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      setLoading(false);
                      toastAlert("error", "User rejected transaction");
                    });
                }
              );
            } else {
              toastAlert("error", "Please connect wallet");
            }
          }}
        >
          {({ errors, touched, resetForm, values }) => (
            <Form>
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-body">
                    <div className="head">
                      <h4>Place a Bid</h4>
                      <p>You are about to place a bid for this item</p>
                    </div>
                    {getNft?.[0]?.PutOnSaleType == "TimedAuction" && (
                      <div className="mb-4">
                        <div className="price_box">
                          <div className="fl_x">
                            <span> Minimum bid</span>
                            <span>
                              {getNft?.[0]?.minimumBid} {config.TokenCurrency}
                            </span>
                          </div>
                          <div className="fl_x">
                            <span>Maximum bid</span>
                            <span className="dark">
                              {getNft?.[0]?.maximumBid} {config.TokenCurrency}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="create_nft p-0">
                      <div className="mb-4">
                        <label for="exampleInputEmail1" className="form-label">
                          Bid price<span className="star">*</span>
                        </label>
                        <div className="input-group mb-3">
                          <Field
                            name="nftBidPrice"
                            type="text"
                            className="form-control"
                            validate={
                              getNft?.[0]?.PutOnSaleType == "TimedAuction"
                                ? validateTimed
                                : ""
                            }
                          />
                          <span className="input-group-text" id="basic-addon2">
                            {config.TokenCurrency}
                          </span>
                        </div>
                        {errors.nftBidPrice && touched.nftBidPrice ? (
                          <span className="error-msg">
                            {errors.nftBidPrice}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-4">
                        <div className="price_box">
                          <div className="fl_x">
                            <span> Your price</span>
                            <span>
                              {values.nftBidPrice} {config.TokenCurrency}
                            </span>
                          </div>
                          <div className="fl_x">
                            <span>Your balance</span>
                            <span className="dark">
                              {myTokBalance} {config.TokenCurrency}
                            </span>
                          </div>
                          <div className="fl_x bdr">
                            <span>
                              Transaction fee
                              <span
                                class="d-inline-block"
                                tabindex="0"
                                data-bs-toggle="tooltip"
                                title="Transaction fee"
                              >
                                <img
                                  src={info}
                                  alt="img"
                                  className="ms-2 img-fluid"
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                            </span>
                            <span className="dark">
                              {serviceFee.singleServiceFee}%
                            </span>
                          </div>
                          <div className="fl_x">
                            <span>You get</span>
                            <span>
                              {" "}
                              {(
                                values.nftBidPrice -
                                (values.nftBidPrice *
                                  serviceFee.singleServiceFee) /
                                  100
                              ).toFixed(8)}{" "}
                              {config.TokenCurrency}
                            </span>
                          </div>
                        </div>
                      </div>
                      {getNft?.[0]?.PutOnSaleType !== "TimedAuction" && (
                        <div className="mb-4">
                          <div className="form-check form-switch">
                            <p>
                              Timed bid
                              <span
                                class="d-inline-block"
                                tabindex="0"
                                data-bs-toggle="tooltip"
                                title="Timed bid"
                              >
                                <img
                                  src={info}
                                  alt="img"
                                  className="ms-2 img-fluid"
                                  style={{ cursor: "pointer" }}
                                />
                              </span>
                            </p>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              role="switch"
                              id="flexSwitchCheckDefault"
                              name="TimedBid"
                              onChange={CheckedChange}
                            />
                          </div>
                        </div>
                      )}

                      {TimedBid && (
                        <DropdownButton
                          id="dropdown-basic-button"
                          className="mb-4"
                          title={selectedValue}
                          onSelect={handleDropdownSelect}
                        >
                          <Dropdown.Item eventKey="2 Day">2 days</Dropdown.Item>
                          <Dropdown.Item eventKey="7 Day">7 days</Dropdown.Item>
                          <Dropdown.Item eventKey="14 Day">
                            14 days
                          </Dropdown.Item>
                          <Dropdown.Item eventKey="30 Day">
                            30 days
                          </Dropdown.Item>
                        </DropdownButton>
                      )}

                      <button
                        type="submit"
                        className="primary_btn dark w-100 mb-4"
                      >
                        <span>Place bid</span>
                        <img
                          src={btnarw_white}
                          alt="img"
                          className="img-fluid ms-2"
                        />
                      </button>

                      <div
                        className="primary_btn w-100"
                        data-bs-dismiss="modal"
                        onClick={resetForm}
                        aria-label="Close"
                        style={{ textAlign: "center", cursor: "pointer" }}
                      >
                        <span>Cancel bid</span>
                        <img
                          src={btnarw}
                          alt="img"
                          className="img-fluid ms-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {/* <!-- accept Modal -->  */}
      <div
        class="modal fade modal-md placebid_modal"
        id="acceptbid"
        tabIndex={-1}
        aria-labelledby="acceptbidLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div className="head">
                <h4>Accept Bid</h4>
                <p>
                  Below are the information about the bid, you can either choose
                  to accept or reject it
                </p>
              </div>
              <div className="nft p-0">
                <div className="text-center">
                  <div className="div_flx mt-5 border-0 justify-content-center align-items-center">
                    <h6 className="mb-0">From</h6>
                  </div>
                </div>

                <div className="div_flx mt-4">
                  <div className="d-flex">
                    <img
                      src={require("../assets/images/nft_activity_user.png")}
                      alt="img"
                      className="img-fluid me-3"
                    />
                    <div className="fl_x">
                      <div>
                        <h6>{halfAddrShow(bidAccepter?.bidderAddress)}</h6>
                        <p>{moment(bidAccepter?.createdAt).format("lll")}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <div className="div_flx mt-4 mb-3 border-0 p-0 justify-content-center align-items-center">
                    <h6 className="mb-0">Bid</h6>
                  </div>
                  <h4 className="mb-5 pb-3">
                    {bidAccepter?.amount} {config.TokenCurrency}{" "}
                    <span>
                      {" "}
                      (${(conversionRate * bidAccepter?.amount).toFixed(3)})
                    </span>
                  </h4>
                </div>

                <button
                  className="primary_btn dark w-100 mb-4"
                  onClick={() => handleBidAccept(bidAccepter)}
                >
                  <span>Accept bid</span>
                  <img
                    src={btnarw_white}
                    alt="img"
                    className="img-fluid ms-2"
                  />
                </button>

                <button
                  className="primary_btn w-100"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span>Cancel bid</span>
                  <img src={btnarw} alt="img" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- transfer_burn Modal -->  */}
      <div
        class="modal fade modal-md placebid_modal"
        id="transfer_burn"
        tabIndex={-1}
        aria-labelledby="transfer_burnLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body tabmodal">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="one-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#one-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="one-tab-pane"
                    aria-selected="true"
                  >
                    Transfer
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="two-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#two-tab-pane"
                    type="button"
                    role="tab"
                    aria-controls="two-tab-pane"
                    aria-selected="false"
                  >
                    Burn
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="one-tab-pane"
                  role="tabpanel"
                  aria-labelledby="one-tab"
                  tabIndex={0}
                >
                  <div className="head mb-5">
                    <p>
                      Complete the form below to transfer your Nft to your
                      desired address
                    </p>
                  </div>
                  <Formik
                    initialValues={{ toAddr: "" }}
                    validationSchema={TrasferSchema}
                    onSubmit={async ({ toAddr }) => {
                      if (walletConnection.address) {
                        setLoading(true);
                        const web3 = new Web3(walletConnection.provider);

                        if (!web3.utils.isAddress(toAddr)) {
                          toastAlert("error", "To address is invalid");
                          setLoading(false);
                          return false;
                        }
                        if (toAddr == walletConnection.address) {
                          toastAlert(
                            "error",
                            "You are not able to transfer yourself "
                          );
                          setLoading(false);
                          return false;
                        }
                        const userpayload = {
                          address: walletConnection.address,
                          nftId: getNft?.[0].nftId,
                          contractAddress: getNft?.[0]?.contractAddress,
                          reciveraddress: toAddr
                        };
                        const result = await checknftowner(userpayload);
                        console.log(result, "ContractNFT");
                        if (!result.data.status) {
                          setLoading(false);
                          toastAlert("error", result.data.message);
                          setTimeout(() => {
                            // window.location.reload()
                          }, 2000);
                          return false;
                        }

                        const ContractNFT = new web3.eth.Contract(
                          SingleNFTABI,
                          getNft[0]?.contractAddress
                        );

                        console.log(ContractNFT, "ContractNFT");
                        await ContractNFT.methods
                          .safeTransferFrom(
                            getNft?.[0]?.tokenOwner,
                            toAddr,
                            nftId
                          )
                          .send({ from: walletConnection.address })
                          .then(async (result) => {
                            const payload = {
                              nftOwner: getNft?.[0]?.tokenOwner,
                              toAddr: toAddr,
                              userAddr: walletConnection.address,
                              nftId: nftId,
                              contractAddr: contractAddress,
                              price: getNft?.[0]?.price,
                              nftName: getNft?.[0]?.nftName,
                              transactionHash: result.transactionHash,
                              ipfsImage: getNft?.[0]?.ipfsimageUrl,
                              image: getNft?.[0]?.localImage
                            };
                            const Resp = await TrasferNFT(payload);
                            if (Resp.data.status == true) {
                              // setLoading(false);
                              toastAlert("success", Resp.data.messages);
                              setTimeout(() => {
                                window.location.reload();
                              }, 2000);
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                            toastAlert("error", "Transaction rejeted");
                            setLoading(false);
                          });
                      }
                    }}
                  >
                    {({ errors, touched, resetForm }) => (
                      <Form>
                        <div className="create_nft p-0">
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Enter the address below
                            </label>

                            <Field
                              type="text"
                              className="form-control"
                              name="toAddr"
                              placeholder="e.g 0x4dfgsj..."
                            />
                            {errors.toAddr && touched.toAddr ? (
                              <span className="error-msg">{errors.toAddr}</span>
                            ) : null}
                          </div>

                          <button
                            type="submit"
                            className="primary_btn dark w-100 mb-3 mt-5"
                          >
                            <span>Confirm</span>
                            <img
                              src={btnarw_white}
                              alt="img"
                              className="img-fluid ms-2"
                            />
                          </button>

                          <button
                            type="reset"
                            className="primary_btn w-100 mb-4"
                            data-bs-toggle="modal"
                            data-bs-target="#transfer_burn"
                            onClick={resetForm}
                          >
                            <span>Cancle</span>
                            <img
                              src={btnarw}
                              alt="img"
                              className="img-fluid ms-2"
                            />
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
                <div
                  class="tab-pane fade"
                  id="two-tab-pane"
                  role="tabpanel"
                  aria-labelledby="two-tab"
                  tabIndex={0}
                >
                  <div className="head mb-5">
                    <p>
                      The process of burning this Nft means it will be sent to a
                      bin address on polygon and it can never be recovered again
                    </p>
                  </div>
                  <div className=" head text-center">
                    <label for="exampleInputEmail1" className="form-label">
                      Are you sure you want to burn this NFT?
                    </label>
                  </div>

                  <button
                    onClick={BurnNFT}
                    className="primary_btn dark w-100 mb-3 mt-5"
                  >
                    <span>Yes burn</span>
                    <img
                      src={btnarw_white}
                      alt="img"
                      className="img-fluid ms-2"
                    />
                  </button>
                  <button
                    className="primary_btn w-100 mb-3"
                    data-bs-toggle="modal"
                    data-bs-target="#transfer_burn"
                  >
                    <span>No don’t burn</span>
                    <img src={btnarw} alt="img" className="img-fluid ms-2" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CanceModal Sale */}
      <div
        class="modal fade modal-md placebid_modal"
        id="cancel_sale"
        tabIndex={-1}
        aria-labelledby="swapLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div className="head">
                <h4>Cancel Sale</h4>

                <div className="head mb-5">
                  {/* <p>
                    The process of burning this Nft means it will be sent to a
                    bin address on polygon and it can never be recovered again
                  </p> */}
                </div>
                <div className=" head text-center">
                  <label for="exampleInputEmail1" className="form-label">
                    Are you sure you want to cancel sale this NFT?
                  </label>
                </div>

                <button
                  onClick={CancelSale}
                  className="primary_btn dark w-100 mb-3 mt-5"
                >
                  <span>Yes Cancel</span>
                  <img
                    src={btnarw_white}
                    alt="img"
                    className="img-fluid ms-2"
                  />
                </button>
                <button
                  className="primary_btn w-100 mb-3"
                  data-bs-toggle="modal"
                  data-bs-target="#cancel_sale"
                >
                  <span>No don’t cancel</span>
                  <img src={btnarw} alt="img" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CanceModal Aucion */}
      <div
        class="modal fade modal-md placebid_modal"
        id="cancel_auction"
        tabIndex={-1}
        aria-labelledby="swapLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div className="head">
                <h4>Cancel Auction</h4>

                <div className="head mb-5">
                  {/* <p>
                    The process of burning this Nft means it will be sent to a
                    bin address on polygon and it can never be recovered again
                  </p> */}
                </div>
                <div className=" head text-center">
                  <label for="exampleInputEmail1" className="form-label">
                    Are you sure you want to cancel auction this NFT?
                  </label>
                </div>

                <button
                  onClick={CancelAuction}
                  className="primary_btn dark w-100 mb-3 mt-5"
                >
                  <span>Yes Cancel</span>
                  <img
                    src={btnarw_white}
                    alt="img"
                    className="img-fluid ms-2"
                  />
                </button>
                <button
                  className="primary_btn w-100 mb-3"
                  data-bs-toggle="modal"
                  data-bs-target="#cancel_auction"
                >
                  <span>No don’t cancel</span>
                  <img src={btnarw} alt="img" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CanceModal Bid */}
      <div
        class="modal fade modal-md placebid_modal"
        id="cancel_bid"
        tabIndex={-1}
        aria-labelledby="swapLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div className="head">
                <h4>Cancel Bid</h4>

                <div className="head mb-5">
                  {/* <p>
                    The process of burning this Nft means it will be sent to a
                    bin address on polygon and it can never be recovered again
                  </p> */}
                </div>
                <div className=" head text-center">
                  <label for="exampleInputEmail1" className="form-label">
                    Are you sure you want to cancel Bid this NFT?
                  </label>
                </div>

                <button
                  onClick={CancelBid}
                  className="primary_btn dark w-100 mb-3 mt-5"
                >
                  <span>Yes Cancel</span>
                  <img
                    src={btnarw_white}
                    alt="img"
                    className="img-fluid ms-2"
                  />
                </button>
                <button
                  className="primary_btn w-100 mb-3"
                  data-bs-toggle="modal"
                  data-bs-target="#cancel_bid"
                >
                  <span>No don’t cancel</span>
                  <img src={btnarw} alt="img" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- swap Modal -->  */}
      <div
        class="modal fade modal-md placebid_modal"
        id="swap"
        tabIndex={-1}
        aria-labelledby="swapLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body swap_modal">
              <div className="head">
                <h4>Swap</h4>
                <p>
                  Find or search the person you want to swap with and choose the
                  item you want to swap with
                </p>
              </div>
              <div className="explore">
                <div className="h_option">
                  <div className="input-group sr_ch mx-2">
                    <span className="input-group-text">
                      <img src={search} alt="img" className="img-fluid" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      id="floatingInputGroup1"
                      placeholder="Search users"
                      onChange={(e) => setSwapFilt(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="nft p-0">
                <div className="nft p-0">
                  {swapList?.length > 0 ? (
                    swapList.map((user) => {
                      return (
                        <div className="div_flx mt-4 mb-0">
                          <div className="d-flex">
                            <img
                              src={
                                getNft?.[0]?.nftUser?.image
                                  ? config.baseUrl +
                                    "/usersProfile/" +
                                    getNft?.[0]?.nftUser?.image
                                  : require("../assets/images/LogosSampleAvator.png")
                              }
                              alt="img"
                              className="img-fluid me-3"
                            />
                            <div className="fl_x fl_x flex-row">
                              <div>
                                <h6>
                                  {user?.mergeUsers?.name
                                    ? user?.mergeUsers?.name
                                    : halfAddrShow(
                                        user?.mergeUsers?.curraddress
                                      )}
                                </h6>
                                <p>
                                  {user?.mergeUsers?.username
                                    ? user?.mergeUsers?.username
                                    : halfAddrShow(
                                        user?.mergeUsers?.curraddress
                                      )}
                                </p>
                              </div>
                              <div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    name="swap"
                                    id=""
                                    onChange={() => handleChg(user)}
                                    type="radio"
                                    value="checkedValue"
                                    aria-label="Text for screen reader"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No Data</div>
                  )}

                  {/* <div className="div_flx mb-0">
                    <div className="d-flex">
                      <img
                        src={require("../assets/images/usr_prf.png")}
                        alt="img"
                        className="img-fluid me-3"
                      />
                      <div className="fl_x fl_x flex-row">
                        <div>
                          <h6>Djaina</h6>
                          <p>@djaina343</p>
                        </div>
                        <div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              name=""
                              id=""
                              type="checkbox"
                              value="checkedValue"
                              aria-label="Text for screen reader"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div_flx mb-0">
                    <div className="d-flex">
                      <img
                        src={require("../assets/images/usr_prf.png")}
                        alt="img"
                        className="img-fluid me-3"
                      />
                      <div className="fl_x fl_x flex-row">
                        <div>
                          <h6>Djaina</h6>
                          <p>@djaina343</p>
                        </div>
                        <div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              name=""
                              id=""
                              type="checkbox"
                              value="checkedValue"
                              aria-label="Text for screen reader"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div_flx mb-0">
                    <div className="d-flex">
                      <img
                        src={require("../assets/images/usr_prf.png")}
                        alt="img"
                        className="img-fluid me-3"
                      />
                      <div className="fl_x fl_x flex-row">
                        <div>
                          <h6>Djaina</h6>
                          <p>@djaina343</p>
                        </div>
                        <div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              name=""
                              id=""
                              type="checkbox"
                              value="checkedValue"
                              aria-label="Text for screen reader"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="div_flx mb-0">
                    <div className="d-flex">
                      <img
                        src={require("../assets/images/usr_prf.png")}
                        alt="img"
                        className="img-fluid me-3"
                      />
                      <div className="fl_x fl_x flex-row">
                        <div>
                          <h6>Djaina</h6>
                          <p>@djaina343</p>
                        </div>
                        <div>
                          <div class="form-check">
                            <input
                              class="form-check-input"
                              name=""
                              id=""
                              type="checkbox"
                              value="checkedValue"
                              aria-label="Text for screen reader"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
              {swapList?.length > 0 ? (
                <button
                  className="primary_btn dark w-100 mb-4"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => checkuserForswap()}
                >
                  <span>Continue to swap</span>
                  <img
                    src={btnarw_white}
                    alt="img"
                    className="img-fluid ms-2"
                  />
                </button>
              ) : (
                <button
                  className="primary_btn dark w-100 mb-4"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  disabled={true}
                >
                  <span>Continue to swap</span>
                  <img
                    src={btnarw_white}
                    alt="img"
                    className="img-fluid ms-2"
                  />
                </button>
              )}

              <button
                className="primary_btn w-100 mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span>No don’t swap</span>
                <img src={btnarw} alt="img" className="img-fluid ms-2" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- swapconfirm Modal -->  */}
      <div
        class="modal fade modal-md placebid_modal"
        id="swapconfirm"
        tabIndex={-1}
        aria-labelledby="swapconfirmLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body tabmodal">
              <div className="row mb-5">
                <div className="col-md-12 text-center mx-auto">
                  <h3>You’re about to swap this item</h3>
                  <p>
                    The process of swapping your item means you’re exchanging
                    the ownership with another person and it can’t be reversed
                    after the process.
                  </p>
                </div>
              </div>
              <div className=" head text-center">
                <label for="exampleInputEmail1" className="form-label">
                  Are you sure you want to swap this NFT?
                </label>
              </div>

              <button
                className="primary_btn dark w-100 mb-3 mt-5"
                data-bs-toggle="modal"
                data-bs-target="#swap"
                // onClick={()=>swapFunction()}
              >
                <span>Yes swap</span>
                <img src={btnarw_white} alt="img" className="img-fluid ms-2" />
              </button>

              <button
                className="primary_btn w-100 mb-3"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span>No don’t swap</span>
                <img src={btnarw} alt="img" className="img-fluid ms-2" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- change_price Modal -->  */}
      <div
        class="modal fade modal-md placebid_modal"
        id="change_price"
        tabIndex={-1}
        aria-labelledby="change_priceLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div className="row mb-3">
                <div className="col-md-11 mx-auto text-center">
                  <h4>
                    {getNft?.[0]?.price > 0 ? "Change price" : "Put on sale"}
                  </h4>
                  <p>
                    You’re about to change the price of this item and it can be
                    changed as many times as want to
                  </p>
                </div>
              </div>
              <div className="create_nft p-0">
                <div className="create_nftbox mb-4">
                  <div className="flx">
                    <div className="flx_box ms-0">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="saleType"
                          id="flexRadioDefault1"
                          onChange={handleSaleTypeChange}
                          value="FixedPrice"
                          defaultChecked
                        />

                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          <img
                            src={fixed}
                            alt="img"
                            className="img-fluid mb-3"
                          />
                          <span> Fixed price</span>
                        </label>
                      </div>
                    </div>
                    <div className="flx_box me-0">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="saleType"
                          id="flexRadioDefault2"
                          onChange={handleSaleTypeChange}
                          value="TimedAuction"
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          <img
                            src={timed}
                            alt="img"
                            className="img-fluid mb-3"
                          />
                          <span>Time auctioned</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <Formik
                  initialValues={{
                    nftPrice: "",
                    nftMaxPrice: "",
                    nftMinPrice: ""
                  }}
                  validationSchema={
                    saleType == "FixedPrice" ? NFTSchema : NFTSchema1
                  }
                  onSubmit={async ({ nftPrice, nftMaxPrice, nftMinPrice }) => {
                    if (walletConnection.address) {
                      const userpayload = {
                        address: walletConnection.address,
                        nftId: getNft?.[0].nftId,
                        contractAddress: getNft?.[0]?.contractAddress
                      };
                      const result = await checknftowner(userpayload);
                      console.log(result, "result");
                      if (!result.data.status) {
                        setLoading(false);
                        toastAlert("error", result.data.message);
                        setTimeout(() => {
                          // window.location.reload()
                        }, 2000);
                        return false;
                      }

                      if (saleType == "FixedPrice") {
                        setLoading(true);
                        const web3 = new Web3(walletConnection.provider);
                        const ContractNFT = new web3.eth.Contract(
                          SingleNFTABI,
                          getNft[0]?.contractAddress
                        );
                        console.log(ContractNFT, "ContractNFT");

                        let newPrice = nftPrice;
                        newPrice = bignumber(newPrice);
                        newPrice = multiply(newPrice, 10 ** 18);
                        // newPrice = parseFloat(nftPrice) * config.decimalValues;
                        newPrice = await Numberconvert(newPrice);
                        // newPrice = await ChecktokenDecimal(newPrice, 1e18);
                        // console.log(nftId, newPrice, "newPrice");

                        await ContractNFT.methods
                          .orderPlace(nftId, newPrice.toString())
                          .send({ from: walletConnection.address })
                          .then(async (result) => {
                            const payload = {
                              nftOwner: getNft?.[0]?.tokenOwner,
                              userAddr: walletConnection.address,
                              nftId: nftId,
                              contractAddr: contractAddress,
                              price: nftPrice,
                              nftName: getNft?.[0]?.nftName,
                              transactionHash: result.transactionHash,
                              ipfsImage: getNft?.[0]?.ipfsimageUrl,
                              image: getNft?.[0]?.localImage,
                              priceChange: false
                            };
                            const Resp = await PutOnSale(payload);
                            if (Resp.data.status == true) {
                              // setLoading(false);
                              toastAlert("success", Resp.data.messages);
                              setTimeout(() => {
                                window.location.reload();
                              }, 2000);
                            }
                          })
                          .catch((err) => {
                            setLoading(false);
                            toastAlert("error", "Transaction rejected by user");
                            console.log(err);
                          });
                      } else {
                        var currDate = new Date();
                        var sDate = new Date(Clocktime);

                        const userpayload = {
                          address: walletConnection.address,
                          nftId: getNft?.[0].nftId,
                          contractAddress: getNft?.[0]?.contractAddress
                        };
                        const result = await checknftowner(userpayload);

                        if (!result.data.status) {
                          setLoading(false);
                          toastAlert(
                            "error",
                            "Your not able to proceed this action please refresh your page"
                          );
                          setTimeout(() => {
                            // window.location.reload()
                          }, 2000);
                          return false;
                        }

                        if (StartDate == "Right after listing") {
                          set_Clocktime(currDate);
                          sDate = currDate;
                        }
                        var startDate = new Date(Clocktime);
                        var eDate = new Date(EndClocktime);
                        startDate.setSeconds(0);
                        eDate.setSeconds(0);
                        if (
                          moment().add(1, "minute") > moment(sDate) &&
                          !(StartDate == "Right after listing")
                        ) {
                          toastAlert(
                            "error",
                            "Start date & Time must be greater than now"
                          );
                          return false;
                        } else if (Date.parse(startDate) >= Date.parse(eDate)) {
                          toastAlert(
                            "error",
                            "End date & Time must be greater than start date and Time"
                          );
                          return false;
                        }
                        setLoading(true);
                        const payload = {
                          nftOwner: getNft?.[0]?.tokenOwner,
                          userAddr: walletConnection.address,
                          nftId: nftId,
                          contractAddr: contractAddress,
                          nftName: getNft?.[0]?.nftName,
                          ipfsImage: getNft?.[0]?.ipfsimageUrl,
                          image: getNft?.[0]?.localImage,
                          MinimumPrice: nftMinPrice,
                          MaximumPrice: nftMaxPrice,
                          Clocktime: Clocktime,
                          EndClocktime: EndClocktime
                        };

                        const Resp = await PutOnAuction(payload);
                        if (Resp.data.status == true) {
                          // setLoading(false);
                          toastAlert("success", Resp.data.messages);
                          setTimeout(() => {
                            window.location.reload();
                          }, 2000);
                        }
                      }
                    } else {
                      toastAlert("error", "Please connect wallet");
                    }
                  }}
                >
                  {({ errors, touched, resetForm, values }) => (
                    <Form>
                      {saleType == "FixedPrice" ? (
                        <div className="mb-4 pb-4">
                          <label
                            for="exampleInputEmail1"
                            className="form-label"
                          >
                            Price<span className="star">*</span>
                          </label>
                          <div className="input-group mb-3">
                            <Field
                              name="nftPrice"
                              type="text"
                              className="form-control"
                            />
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                            >
                              {config.Currency}
                            </span>
                          </div>
                          {errors.nftPrice && touched.nftPrice ? (
                            <span className="error-msg">{errors.nftPrice}</span>
                          ) : null}
                        </div>
                      ) : (
                        <>
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Minimum price<span className="star">*</span>
                            </label>
                            <div className="input-group">
                              <Field
                                type="text"
                                className="form-control"
                                name="nftMinPrice"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                {config.TokenCurrency}
                              </span>
                            </div>
                            {errors.nftMinPrice && touched.nftMinPrice ? (
                              <span className="error-msg">
                                {errors.nftMinPrice}
                              </span>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Maximum price<span className="star">*</span>
                            </label>
                            <div className="input-group">
                              <Field
                                type="text"
                                className="form-control"
                                name="nftMaxPrice"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                {config.TokenCurrency}
                              </span>
                            </div>
                            {errors.nftMaxPrice && touched.nftMaxPrice ? (
                              <span className="error-msg">
                                {errors.nftMaxPrice}
                              </span>
                            ) : null}
                          </div>

                          <div className="drobdown_section mb-3">
                            <label className="primary_label">
                              Starting date
                            </label>
                            <div className="dropdown">
                              <button
                                class="btn btn-secondary dropdown-toggle w-100"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span id="menuBut">{StartDate}</span>
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  id="RightAfterListing"
                                  onClick={() => {
                                    DateChange(
                                      "StartDateDropDown",
                                      "RightAfterListing"
                                    );
                                  }}
                                >
                                  Right after listing
                                </div>
                                <div
                                  id="PickStart"
                                  onClick={() => {
                                    DateChange(
                                      "StartDateDropDown",
                                      "PickStartDate"
                                    );
                                  }}
                                >
                                  Pick specific date
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="drobdown_section mb-3">
                            <label className="primary_label">
                              Expiration date
                            </label>
                            <div class="dropdown">
                              <button
                                class="btn btn-secondary dropdown-toggle filter_btn inp_btn m-0 text-left w-100"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span id="menuBut">{EndDate}</span>
                              </button>
                              <div
                                class="dropdown-menu filter_menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "1 Day");
                                  }}
                                >
                                  1 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "3 Day");
                                  }}
                                >
                                  3 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "5 Day");
                                  }}
                                >
                                  5 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "7 Day");
                                  }}
                                >
                                  7 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange(
                                      "EndDateDropDown",
                                      "PickEndDate"
                                    );
                                  }}
                                >
                                  Pick specific date
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <button
                        className="primary_btn dark w-100 mb-4"
                        type="submit"
                      >
                        <span>Put on sale</span>
                        <img
                          src={btnarw_white}
                          alt="img"
                          className="img-fluid ms-2"
                        />
                      </button>

                      <button
                        type="reset"
                        className="primary_btn w-100 mb-4"
                        data-bs-toggle="modal"
                        onClick={resetForm}
                        data-bs-target="#change_price"
                      >
                        <span>Cancle</span>
                        <img
                          src={btnarw_white}
                          alt="img"
                          className="img-fluid ms-2"
                        />
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- change_price1 Modal -->  */}
      <div
        class="modal fade modal-md placebid_modal"
        id="change_price1"
        tabIndex={-1}
        aria-labelledby="change_priceLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-body">
              <div className="row mb-3">
                <div className="col-md-11 mx-auto text-center">
                  <h4>
                    {getNft?.[0]?.price > 0 ? "Change price" : "Put on sale"}
                  </h4>
                  <p>
                    You’re about to change the price of this item and it can be
                    changed as many times as want to
                  </p>
                  <h5>
                    Current Price : {getNft?.[0]?.price} {config.Currency}
                  </h5>
                </div>
              </div>
              <div className="create_nft p-0">
                {/* <div className="create_nftbox mb-4">
                  <div className="flx">
                    <div className="flx_box ms-0">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="saleType"
                          id="flexRadioDefault1"
                          onChange={handleSaleTypeChange}
                          value="FixedPrice"
                          defaultChecked
                        />

                        <label
                          className="form-check-label"
                          for="flexRadioDefault1"
                        >
                          <img
                            src={fixed}
                            alt="img"
                            className="img-fluid mb-3"
                          />
                          <span> Fixed price</span>
                        </label>
                      </div>
                    </div>
                    <div className="flx_box me-0">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="saleType"
                          id="flexRadioDefault2"
                          onChange={handleSaleTypeChange}
                          value="TimedAuction"
                        />
                        <label
                          className="form-check-label"
                          for="flexRadioDefault2"
                        >
                          <img
                            src={timed}
                            alt="img"
                            className="img-fluid mb-3"
                          />
                          <span>Time auctioned</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div> */}
                <Formik
                  initialValues={{
                    nftPrice: "",
                    nftMaxPrice: "",
                    nftMinPrice: ""
                  }}
                  validationSchema={
                    saleType == "FixedPrice" ? NFTSchema : NFTSchema1
                  }
                  onSubmit={async ({ nftPrice, nftMaxPrice, nftMinPrice }) => {
                    if (walletConnection.address) {
                      setLoading(true);
                      const userpayload = {
                        address: walletConnection.address,
                        nftId: getNft?.[0].nftId,
                        contractAddress: getNft?.[0]?.contractAddress
                      };
                      const result = await checknftowner(userpayload);
                      console.log(result, "datatas");
                      if (!result.data.status) {
                        setLoading(false);
                        toastAlert(
                          "error",
                          "Your not able to proceed this action please refresh your page"
                        );
                        setTimeout(() => {
                          // window.location.reload()
                        }, 2000);

                        return false;
                      }
                      const web3 = new Web3(walletConnection.provider);
                      const ContractNFT = new web3.eth.Contract(
                        SingleNFTABI,
                        getNft[0]?.contractAddress
                      );

                      console.log(ContractNFT, "ContractNFT");

                      let newPrice = nftPrice;
                      newPrice = bignumber(newPrice);
                      newPrice = multiply(newPrice, 10 ** 18);
                      newPrice = Numberconvert(newPrice);
                      // newPrice = parseFloat(nftPrice) * config.decimalValues;
                      // newPrice = await Numberconvert(newPrice);
                      // newPrice = await ChecktokenDecimal(newPrice);
                      await ContractNFT.methods
                        .orderPlace(nftId, newPrice.toString())
                        .send({ from: walletConnection.address })
                        .then(async (result) => {
                          const payload = {
                            nftOwner: getNft?.[0]?.tokenOwner,
                            userAddr: walletConnection.address,
                            nftId: nftId,
                            contractAddr: contractAddress,
                            price: nftPrice,
                            nftName: getNft?.[0]?.nftName,
                            transactionHash: result.transactionHash,
                            ipfsImage: getNft?.[0]?.ipfsimageUrl,
                            image: getNft?.[0]?.localImage,
                            priceChange: true
                          };
                          const Resp = await PutOnSale(payload);
                          if (Resp.data.status == true) {
                            // setLoading(false);
                            toastAlert("success", Resp.data.messages);
                            setTimeout(() => {
                              window.location.reload();
                            }, 2000);
                          }
                        })
                        .catch((err) => {
                          setLoading(false);
                          toastAlert("error", "Transaction rejected by user");
                          console.log(err);
                        });
                    }
                  }}
                >
                  {({ errors, touched, resetForm, values }) => (
                    <Form>
                      {saleType == "FixedPrice" ? (
                        <div className="mb-4 pb-4">
                          <label
                            for="exampleInputEmail1"
                            className="form-label"
                          >
                            New Price<span className="star">*</span>
                          </label>
                          <div className="input-group mb-3">
                            <Field
                              name="nftPrice"
                              type="text"
                              className="form-control"
                            />
                            <span
                              className="input-group-text"
                              id="basic-addon2"
                            >
                              {config.Currency}
                            </span>
                          </div>
                          {errors.nftPrice && touched.nftPrice ? (
                            <span className="error-msg">{errors.nftPrice}</span>
                          ) : null}
                        </div>
                      ) : (
                        <>
                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Minimum price<span className="star">*</span>
                            </label>
                            <div className="input-group">
                              <Field
                                type="text"
                                className="form-control"
                                name="nftMinPrice"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                {config.TokenCurrency}
                              </span>
                            </div>
                            {errors.nftMinPrice && touched.nftMinPrice ? (
                              <span className="error-msg">
                                {errors.nftMinPrice}
                              </span>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Maximum price<span className="star">*</span>
                            </label>
                            <div className="input-group">
                              <Field
                                type="text"
                                className="form-control"
                                name="nftMaxPrice"
                              />
                              <span
                                className="input-group-text"
                                id="basic-addon2"
                              >
                                {config.TokenCurrency}
                              </span>
                            </div>
                            {errors.nftMaxPrice && touched.nftMaxPrice ? (
                              <span className="error-msg">
                                {errors.nftMaxPrice}
                              </span>
                            ) : null}
                          </div>

                          <div className="drobdown_section mb-3">
                            <label className="primary_label">
                              Starting date
                            </label>
                            <div className="dropdown">
                              <button
                                class="btn btn-secondary dropdown-toggle w-100"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <span id="menuBut">{StartDate}</span>
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  id="RightAfterListing"
                                  onClick={() => {
                                    DateChange(
                                      "StartDateDropDown",
                                      "RightAfterListing"
                                    );
                                  }}
                                >
                                  Right after listing
                                </div>
                                <div
                                  id="PickStart"
                                  onClick={() => {
                                    DateChange(
                                      "StartDateDropDown",
                                      "PickStartDate"
                                    );
                                  }}
                                >
                                  Pick specific date
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="drobdown_section mb-3">
                            <label className="primary_label">
                              Expiration date
                            </label>
                            <div class="dropdown">
                              <button
                                class="btn btn-secondary dropdown-toggle filter_btn inp_btn m-0 text-left w-100"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span id="menuBut">{EndDate}</span>
                              </button>
                              <div
                                class="dropdown-menu filter_menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "1 Day");
                                  }}
                                >
                                  1 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "3 Day");
                                  }}
                                >
                                  3 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "5 Day");
                                  }}
                                >
                                  5 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange("EndDateDropDown", "7 Day");
                                  }}
                                >
                                  7 Day
                                </div>
                                <div
                                  onClick={() => {
                                    DateChange(
                                      "EndDateDropDown",
                                      "PickEndDate"
                                    );
                                  }}
                                >
                                  Pick specific date
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <button
                        className="primary_btn dark w-100 mb-4"
                        type="submit"
                      >
                        <span>Change price</span>
                        <img
                          src={btnarw_white}
                          alt="img"
                          className="img-fluid ms-2"
                        />
                      </button>
                      <button
                        type="reset"
                        className="primary_btn w-100 mb-4"
                        onClick={resetForm}
                        data-bs-toggle="modal"
                        data-bs-target="#change_price1"
                      >
                        <span>Cancel</span>
                        <img
                          src={btnarw}
                          alt="img"
                          className="img-fluid ms-2"
                        />
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loadBefore">
          <HashLoader
            color={"#9a55ff"}
            loading={loading}
            cssOverride={override}
            size={60}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}

      {/* DataModals */}
      <div
        class="modal fade primary_modal"
        id="calendar_modal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="calendar_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-module">
              <div class="modal-header text-center">
                <h5 class="modal-title" id="calendar_modalLabel">
                  Start date
                </h5>
                {/* <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="cancelcalender"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div class="modal-body">
                <div className="pb-3">
                  <Datetime
                    minDate={new Date().getTime()}
                    input={false}
                    value={Clocktime}
                    onChange={(value) => handlesetClocktime(value)}
                  />
                </div>
              </div>
              <div class="text-center mb-3">
                <input
                  type="submit"
                  name="Done"
                  value="Done"
                  className="primary_btn"
                  id="doneStartDate"
                  disabled={startTimeErr ? true : false}
                  onClick={() => ModalAction("calendar_modal", "hide")}
                />
                {startTimeErr && (
                  <span className="error-msg">{startTimeErr}</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade primary_modal"
        id="calendar_modal_expire"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="calendar_modalCenteredLabel"
        aria-hidden="true"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-module">
              <div class="modal-header text-center">
                <h5 class="modal-title" id="calendar_modalLabel">
                  End date
                </h5>
                {/* <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="cancelcalender"
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div class="modal-body">
                <div className="pb-3">
                  <Datetime
                    input={false}
                    value={EndClocktime}
                    minDate={new Date().getTime()}
                    onChange={(value) => handleendClocktime(value)}
                  />
                </div>
              </div>
              <div class="text-center mb-3">
                <input
                  type="submit"
                  name="Done"
                  value="Done"
                  className="primary_btn"
                  id="doneEndDate"
                  disabled={endTimeErr ? true : false}
                  onClick={() => ModalAction("calendar_modal_expire", "hide")}
                />
                {endTimeErr && <span className="error-msg">{endTimeErr}</span>}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Report Modal */}
      <div
        class="modal fade modal-md placebid_modal"
        id="Reportconfirm"
        tabIndex={-1}
        aria-labelledby="swapconfirmLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <h3>Report Page Model</h3>
            <div class="modal-body tabmodal">
              <Formik
                initialValues={{
                  reporttitle: "",
                  reportcontent: ""
                }}
                validationSchema={ReportPageValidate}
                onSubmit={async ({ reporttitle, reportcontent }) => {
                  setdisabledbtn(true);
                  console.log(reporttitle, reportcontent, "Formik");
                  const payload = {
                    nftId: nftId,
                    nftname: getNft?.[0]?.nftName,
                    reporttitle: reporttitle,
                    reportcontent: reportcontent,
                    currentuser: localStorage.getItem("nicholousWallAddr"),
                    reporttype: "nft_detail",
                    contractAddress
                  };
                  console.log(payload);
                  const result = await SetReportpage(payload);
                  if (result.status) {
                    toastAlert("success", result.message);
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  }
                }}
              >
                {({ errors, touched, resetForm }) => (
                  <Form>
                    <div className="row mb-5">
                      <div className="col-md-12 mx-auto">
                        <label for="exampleInputEmail1" className="form-label">
                          Title
                        </label>
                        <Field
                          name="reporttitle"
                          type="text"
                          placeholder="Report title"
                          style={{ width: "100%", padding: "10px" }}
                        />
                        <br />
                        {errors.reporttitle && touched.reporttitle ? (
                          <span className="error-msg">
                            {errors.reporttitle}
                          </span>
                        ) : null}
                      </div>
                      <div
                        className="col-md-12 mx-auto"
                        style={{ marginTop: "10px" }}
                      >
                        <label for="exampleInputEmail1" className="form-label">
                          Content
                        </label>
                        <Field
                          name="reportcontent"
                          type="text"
                          component="textarea"
                          rows="10"
                          style={{ width: "100%" }}
                        />
                        <br />
                        {errors.reportcontent && touched.reportcontent ? (
                          <span className="error-msg">
                            {errors.reportcontent}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <button
                      type="submit"
                      className="primary_btn dark w-100 mb-3 mt-2"
                      disabled={disabledbtn}
                    >
                      Submit
                    </button>
                    {/* {({resetForm})=> */}
                    <button
                      type="reset"
                      className="primary_btn dark w-100 mb-3 mt-2"
                      // disabled={disabledbtn}
                      onClick={resetForm}
                      data-bs-toggle="modal"
                      data-bs-target="#Reportconfirm"
                    >
                      Cancel
                    </button>
                    {/* } */}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
