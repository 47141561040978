import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes
} from "react-router-dom";

import Home from "./pages/home.js";
import Create from "./pages/create_selection.js";
import Choose_blockchain from "./pages/choose_blockchain.js";
import Choose_preferance from "./pages/choose_preferance.js";
import Create_nft from "./pages/create_nft.js";
import Create_collection from "./pages/create_collection.js";
import Connect_wallet from "./pages/connect_wallet.js";
import Explore from "./pages/explore.js";
import Profile from "./pages/profile.js";
import Profile1 from "./pages/profile1.js";
import Collection from "./pages/collection.js";
import Own_rofile from "./pages/own_profile.js";
import Swap from "./pages/swap.js";
import Nftdetail from "./pages/nft-detail.js";
import Profile_setting from "./pages/settings/profile.js";
import Wallet from "./pages/settings/wallet.js";
import Notification from "./pages/settings/notification.js";
import Account from "./pages/settings/account.js";
import Help from "./pages/help.js";
import Chat from "./pages/chat/chat.js";
import { ToastContainer } from "react-toastify";

import { Provider } from "react-redux";
import store from "./store";

import { WagmiConfig, createConfig, configureChains, mainnet } from "wagmi";
import { bsc, bscTestnet, polygon, polygonMumbai, goerli } from "viem/chains";
import { publicProvider } from "wagmi/providers/public";

import { InjectedConnector } from "wagmi/connectors/injected";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import UserProfile from "./pages/userprofile.js";
import UserCollection from "./pages/usercollection.js";
import TermsAndCondition from "./pages/termsandcondition.js";
import Policy from "./pages/policy.js";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [polygon, polygonMumbai],
  [publicProvider()]
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains, shimDisconnect: true }),

    new WalletConnectConnector({
      chains,
      options: {
        projectId: "681700e231a5aef269b7fe4adb34981a",
        version: "2"
      }
    }),
    new InjectedConnector({
      chains,
      options: {
        name: "Injected",
        shimDisconnect: true
      }
    })
  ],
  publicClient,
  webSocketPublicClient
});

const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "create", element: <Create /> },
    { path: "choose-blockchain", element: <Choose_blockchain /> },
    { path: "choose-preference", element: <Choose_preferance /> },
    { path: "create-nft", element: <Create_nft /> },
    { path: "create-collection", element: <Create_collection /> },
    { path: "connect-wallet", element: <Connect_wallet /> },
    { path: "explore", element: <Explore /> },
    { path: "profile", element: <Profile /> },
    { path: "user/:address", element: <Profile1 /> },
    { path: "collection/:conAddr", element: <Collection /> },
    { path: "own-profile", element: <Own_rofile /> },
    { path: "swap", element: <Swap /> },
    { path: "nft-detail/:contractAddress/:nftId", element: <Nftdetail /> },
    { path: "profile-setting", element: <Profile_setting /> },
    { path: "wallet", element: <Wallet /> },
    { path: "notification", element: <Notification /> },
    { path: "account", element: <Account /> },
    { path: "help", element: <Help /> },
    { path: "chat", element: <Chat /> },
    { path: "/:username", element: <UserProfile /> },
    { path: "/coll/:usercollection", element: <UserCollection /> },
    { path: "TermsAndCondition", element: <TermsAndCondition /> },
    { path: "Policy", element: <Policy /> },
    
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="/">
      <Provider store={store}>
        <ToastContainer />
        <WagmiConfig config={config}>
          <App />
        </WagmiConfig>
      </Provider>
    </Router>
  );
};

export default AppWrapper;
