import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import moment from "moment";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Countdown from "react-countdown";
import btnarw_white from "../assets/images/btnarw_white.svg";
import btnarw from "../assets/images/btnarw.svg";
import {
  getPopNFT,
  getTrandCollection,
  getTrandUsers,
  GetAllEvent,
  getSwapHist
} from "../actions/nfts";
import config from "../lib/config";
import { Link } from "react-router-dom";
import { halfAddrShow } from "../helper/halfAddr.js";
import { useSelector } from "react-redux";
import { Numberconvert } from "../helper/numberConvert.js";

// Scroll to Top
function ScrollToTopOnMount() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return null;
}

export default function Home(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [polularNfts, setPolularNfts] = useState([]);
  const [trandingColl, setTrandingColl] = useState([]);
  const [trandingUser, setTrandingUser] = useState([]);
  const [eventlist, setEventList] = useState([]);
  const [swapHis, setSwapHis] = useState([]);

  useEffect(() => {
    AOS.init();
  }, []);

  const halfAddrShow1 = (addr) => {
    if (addr) {
      return addr.substring(0, 3) + "......" + addr.slice(addr.length - 4);
    } else {
      return "";
    }
  };

  var slides = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  const renderer1 = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <span></span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s to start
        </span>
      );
    }
  };
  const renderer = ({
    days,
    Month,
    Year,
    hours,
    minutes,
    seconds,
    completed
  }) => {
    if (completed) {
      return <span>Ended</span>;
    } else {
      return (
        <span>
          {days}d {hours}h {minutes}m {seconds}s left
        </span>
      );
    }
  };

  useEffect(() => {
    getPopuNfts();
    getTradColl();
    getInitialDate();
    SwapHist();
  }, []);

  useEffect(() => {
    getUsersTrand();
  }, [walletConnection.address]);

  const getPopuNfts = async () => {
    const nfts = await getPopNFT();
    console.log(nfts, "nfts");
    setPolularNfts(nfts?.data?.data);
  };

  const getTradColl = async () => {
    const collection = await getTrandCollection();
    console.log(collection, "collection");
    setTrandingColl(collection?.data?.data);
  };

  const getUsersTrand = async () => {
    const payload = {
      address: walletConnection.address
    };
    const user = await getTrandUsers(payload);
    console.log(user, "g Creators an");
    const userProfile = user?.data?.data?.filter((el) => el.arraylength > 0);
    setTrandingUser(userProfile);
  };

  const getInitialDate = async () => {
    const result = await GetAllEvent();
    console.log(result, "resultresultresultresultresult");
    if (result && result?.data?.length > 0) {
      setEventList(result?.data);
    } else {
      setEventList([]);
    }
  };

  const SwapHist = async () => {
    // if (walletConnection.address) {
    const payload = {
      address: ""
    };
    const data = await getSwapHist(payload);
    console.log(data, "SwapDataHid");
    setSwapHis(data?.data?.data);
    // }
  };

  function findObjectWithLowestValue(objects) {
    const res = objects.filter((ele) => ele.bannedstatus !== true);
    const data = res.filter((ele) => ele.price > 0);
    const mm = data.map((ele) => ele.price);
    console.log(mm.sort(), "datadatadatadata");
    return mm[0];
  }

  function sumOfCollVolumn(objects) {
    const res = objects.filter((ele) => ele.bannedstatus !== true);
    const data = res.filter((ele) => ele.price > 0);
    const mm = data.map((ele) => ele.price);
    const sum = mm.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return sum.toFixed(4);
  }

  const differenct = (date) => {
    const TodaysDate = new Date();
    const todaysdate = moment(TodaysDate).format("MMMM DD YY");
    const newTodaysDate = new Date(todaysdate);
    // const postedDate=Date.parse(date)
    const postedDate = moment(date).format("MMMM DD YY");
    const newposteddate = new Date(postedDate);
    console.log(todaysdate, todaysdate);
    if (todaysdate === postedDate) {
      return "Present";
    } else if (newTodaysDate < newposteddate) {
      return "Up Coming";
    } else {
      return "Expired";
    }
  };

  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      <div className="min">
        <div className="page_header">
          <div className="container">
            <div className="row">
              <div
                className="col-md-6 m-auto"
                data-aos-duration="500"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <div>
                  <h1>Collect Super Rare Digital Artworks</h1>
                  <p>
                    Discover the best nft collections in the world on our site.
                  </p>
                  <Link to="/explore">
                    <button className="primary_btn">
                      <span> Explore Marketplace</span>
                      <img
                        src={btnarw_white}
                        alt="img"
                        className="ms-3 img-fluid"
                      />
                    </button>
                  </Link>
                </div>
              </div>
              <div
                className="col-md-6 m-auto"
                data-aos-duration="500"
                data-aos="fade-up"
                data-aos-delay="600"
              >
                <div className="img_box">
                  <img
                    src={require("../assets/images/banimg.png")}
                    alt="img"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main_wrapper">
          <section className="popular_nft">
            <div className="container">
              <h2
                className="h2tag"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="1000"
              >
                Popular NFTs
              </h2>
              <div className="popular_nft_scroll">
                <div className="row">
                  {polularNfts.length > 0 ? (
                    polularNfts.map((nft, i) => {
                      var currDate = new Date();
                      var startdate = new Date(nft.startTime);
                      var enddate = new Date(nft.endTime);
                      var auction = "false";
                      var finish = "";
                      var enddate1 = "";
                      var showlist = "true";
                      var display_item = 0;
                      if (nft.PutOnSaleType == "TimedAuction") {
                        auction = "true";
                        var a = moment(nft.startTime);
                        var b = moment(nft.endTime);
                        var c = moment();
                        a.diff(b); // 86400000
                        var diffInMs = a.diff(c);
                        display_item = a.diff(c);
                        finish = b.diff(c);
                        enddate1 = parseFloat(diffInMs);
                        if (finish > 0) {
                          showlist = "true";
                        } else {
                          var auctionTxt = "Ended";
                          showlist = "false";
                        }
                      }
                      return (
                        <div
                          className="col-lg-4"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                          data-aos-delay="1200"
                          key={i}
                        >
                          <Link
                            to={`/nft-detail/${nft.contractAddress}/${nft.nftId}`}
                          >
                            <div className="box mb-4">
                              {nft.PutOnSaleType == "TimedAuction" &&
                                showlist == "true" &&
                                display_item <= 0 && (
                                  <div className="de_countdown">
                                    <Countdown
                                      date={enddate}
                                      autoStart={true}
                                      onStart={() => startdate}
                                      renderer={renderer}
                                    />
                                  </div>
                                )}
                              {nft.PutOnSaleType == "TimedAuction" &&
                                showlist == "true" &&
                                display_item > 0 && (
                                  <div className="de_countdown">
                                    <Countdown
                                      date={startdate}
                                      autoStart={true}
                                      onStart={() => Date.now()}
                                      renderer={renderer1}
                                    />
                                  </div>
                                )}
                              <div className="img_box">
                                {/* <img
                                  src={nft.isCollNft ? `${config.baseUrl}/tokens/${nft.localImage}`}
                                  alt="img"
                                  className="img-fluid"
                                /> */}
                                {nft.localImage.split(".").pop() == "mp4" ? (
                                  <>
                                    {nft.isCollNft ? (
                                      <video
                                        src={`${config.baseUrl}/${nft.localImage}`}
                                        style={{ width: "100%" }}
                                        type="video/mp4"
                                        alt="Collections"
                                        className="lazy nft__item_preview nft__item_preview_video"
                                        controls
                                        controlsList="nodownload"
                                      />
                                    ) : (
                                      <video
                                        src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                        style={{ width: "100%" }}
                                        type="video/mp4"
                                        alt="Collections"
                                        // className="lazy nft__item_preview nft__item_preview_video"
                                        className={
                                          nft.sensitiveContent
                                            ? "lazy nft__item_preview nft__item_preview_video blue_img"
                                            : "lazy nft__item_preview nft__item_preview_video"
                                        }
                                        controls
                                        controlsList="nodownload"
                                      />
                                    )}
                                  </>
                                ) : nft.localImage.split(".").pop() == "mp3" ? (
                                  <>
                                    {nft.isCollNft ? (
                                      <div style={{ textAlign: "center" }}>
                                        <img
                                          style={{
                                            padding: "16px",
                                            width: "256px",
                                            height: "256px"
                                          }}
                                          src={
                                            config.baseUrl +
                                            "/" +
                                            "images/music.png"
                                          }
                                          alt="img"
                                          className="img-fluid"
                                        />
                                        <audio
                                          src={`${config.baseUrl}/${nft.localImage}`}
                                          type="audio/mp3"
                                          controls
                                          controlsList="nodownload"
                                          className="audio home"
                                          // className={nft.sensitiveContent?"audio home blue_img":"audio home"}
                                        ></audio>
                                      </div>
                                    ) : (
                                      <div style={{ textAlign: "center" }}>
                                        <img
                                          style={{
                                            padding: "16px",
                                            width: "256px",
                                            height: "256px"
                                          }}
                                          src={
                                            config.baseUrl +
                                            "/" +
                                            "images/music.png"
                                          }
                                          alt="img"
                                          className="img-fluid"
                                        />
                                        <audio
                                          src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                          type="audio/mp3"
                                          controls
                                          controlsList="nodownload"
                                          className="audio home"
                                          // className={nft.sensitiveContent?"audio home blue_img":"audio home"}
                                        ></audio>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {nft.isCollNft ? (
                                      <img
                                        src={`${config.baseUrl}/${nft.localImage}`}
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src={`${config.baseUrl}/tokens/${nft.localImage}`}
                                        alt="img"
                                        // className="img-fluid"
                                        className={
                                          nft.sensitiveContent
                                            ? "img-fluid blue_img"
                                            : "img-fluid"
                                        }
                                      />
                                    )}
                                  </>
                                )}
                              </div>
                              <div className="blw_box">
                                <h5>{nft.nftName}</h5>
                                <div className="flx_ctn">
                                  <div className="mb-3">
                                    {nft.PutOnSaleType == "TimedAuction" ? (
                                      <>
                                        <span>Min. Bid</span>
                                        <h6>{nft.minimumBid} MATIC</h6>
                                      </>
                                    ) : (
                                      <>
                                        <span>Price</span>
                                        <h6>
                                          {Numberconvert(nft.price)} MATIC
                                        </h6>
                                      </>
                                    )}
                                  </div>
                                  <div>
                                    <span>Highest bid</span>
                                    {nft.highestBid ? (
                                      <p>
                                        {Numberconvert(
                                          nft.highestBid.maxAmount
                                        )}{" "}
                                        MATIC
                                      </p>
                                    ) : (
                                      <p>Not yet</p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className="col-lg-4"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      data-aos-delay="1200"
                    >
                      No NFT's Yet
                    </div>
                  )}
                </div>
              </div>
              <div className="text-center">
                <Link to="/explore">
                  <button className="primary_btn">
                    <span> Discover More</span>
                    <img src={btnarw} alt="img" className="ms-3 img-fluid" />
                  </button>
                </Link>
              </div>
            </div>
          </section>

          <section className="recent_swap" data-aos="fade-up">
            <div className="container">
              <h2 className="h2tag" data-aos="fade-up">
                Recent Swaps
              </h2>
              <div className="boxes" data-aos="fade-up">
                <div className="rw_flx head_flx">
                  <div>#</div>
                  <div>PARTY A</div>
                  <div>ITEM A</div>
                  <div>STATUS</div>
                  <div>PARTY B</div>
                  <div>ITEM B</div>
                </div>
                <div className="bdr">
                  {swapHis.length > 0 ? (
                    swapHis.map((swap, i) => {
                      return (
                        <div className="rw_flx">
                          <div>{i + 1}</div>
                          <div>
                            <img
                              src={
                                swap?.formUser?.image
                                  ? config.baseUrl +
                                    "/usersProfile/" +
                                    swap?.formUser?.image
                                  : require("../assets/images/LogosSampleAvator.png")
                              }
                              alt="img"
                              className="img-fluid user"
                            />
                            <span>
                              {" "}
                              {swap?.formUser?.username
                                ? swap?.formUser?.username
                                : halfAddrShow1(swap?.fromUser)}
                            </span>
                          </div>
                          <div>{swap?.fromTokenName}</div>
                          <div>
                            <button
                              className={`${
                                swap?.status == 0 ? `pending` : `completed`
                              }`}
                            >
                              {swap?.status == 0 ? "Pending" : "Completed"}
                            </button>
                          </div>
                          {swap?.status == 1 && (
                            <div>
                              <img
                                src={
                                  swap?.TOUser?.[0]?.image
                                    ? config.baseUrl +
                                      "/usersProfile/" +
                                      swap?.TOUser?.[0]?.image
                                    : require("../assets/images/LogosSampleAvator.png")
                                }
                                alt="img"
                                className="img-fluid user"
                              />
                              <span>
                                {swap?.TOUser?.[0]?.username
                                  ? swap?.TOUser?.[0]?.username
                                  : halfAddrShow1(swap?.toUser)}
                              </span>
                            </div>
                          )}
                          <div> {swap?.status == 1 && swap?.toTokenName}</div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No Swaps Yet</div>
                  )}
                </div>
              </div>
            </div>
          </section>

          <section className="popular_nft trending">
            <div className="container">
              <h2 className="h2tag" data-aos="fade-up">
                Trending Creators and Collections
              </h2>

              <div className="user_flx" data-aos="fade-up">
                {trandingUser.length > 0 &&
                  trandingUser.map((user) => {
                    return (
                      <Link to={`/user/${user.curraddress}`}>
                        <div className="user">
                          <img
                            src={
                              user.image
                                ? config.baseUrl + "/usersProfile/" + user.image
                                : require("../assets/images/LogosSampleAvator.png")
                            }
                            alt="img"
                            className="img-fluid"
                          />
                          <span>
                            {user?.username
                              ? user?.username
                              : halfAddrShow1(user.curraddress)}
                          </span>
                        </div>
                      </Link>
                    );
                  })}
              </div>
              <div className="trending_scroll">
                <div className="row">
                  {trandingColl.length > 0 ? (
                    trandingColl.map((coll) => {
                      return (
                        <div
                          className="col-md-3"
                          data-aos="fade-up"
                          data-aos-duration="500"
                          data-aos-delay="300"
                        >
                          <div className="box">
                            <div className="img_box">
                              <img
                                src={
                                  config.baseUrl +
                                  "/collections/" +
                                  coll.bannerImage
                                }
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                            <div className="blw_box">
                              <h5>{coll?.name}</h5>
                              <div className="flx_ctn">
                                <div className="mb-3">
                                  <p>Floor Price</p>
                                  <p>
                                    {findObjectWithLowestValue(coll?.nftdata)
                                      ? findObjectWithLowestValue(coll?.nftdata)
                                      : 0}{" "}
                                    MATIC
                                  </p>
                                </div>
                                <div>
                                  <p>Volume</p>
                                  <p>{sumOfCollVolumn(coll?.nftdata)} MATIC</p>
                                </div>
                              </div>
                              <Link to={`/collection/${coll.conAddr}`}>
                                <button className="primary_btn w-100">
                                  {" "}
                                  <span> View Collection</span>
                                  <img
                                    src={btnarw}
                                    alt="img"
                                    className="ms-3 img-fluid"
                                  />
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div
                      data-aos="fade-up"
                      data-aos-duration="500"
                      data-aos-delay="300"
                      // style={{ textAlign: "center" }}
                    >
                      No Collections Yet
                    </div>
                  )}
                </div>
              </div>
              <div className="text-center">
                <Link to="/explore">
                  <button className="primary_btn">
                    <span> Discover More</span>
                    <img src={btnarw} alt="img" className="ms-3 img-fluid" />
                  </button>
                </Link>
              </div>
            </div>
          </section>

          <section className="slide slidechg">
            <div className="container">
              <h2 className="h2tag" data-aos="fade-up">
                Events
              </h2>
            </div>
            <div className="slidebg_out" data-aos="fade-up">
              <div className="slidebg">
                <Slider {...slides}>
                  {eventlist && eventlist.length >= 1
                    ? eventlist.map((list) => {
                        return (
                          <div>
                            <div className="box">
                              <div
                                className="box_background"
                                // style={{
                                //   background: `url(${config.baseUrl}/events/${list.Image}) no-repeat center cover`
                                // }}
                              >
                                <div className="flx">
                                  <h3>{list.Title}</h3>
                                  {/* <img
                                    style={{ width: "60px", borderRadius:'10px' }}
                                    src={`${config.baseUrl}/events/${list.Image}`}
                                  /> */}
                                  <button>{differenct(list.postDate)} </button>
                                </div>
                                <p className="event-content">
                                  {list?.content.replace(/<[^>]*>/g, "")}
                                </p>
                                <p>
                                  Discord & Telegram -{" "}
                                  {moment(list.postDate).format("LL")}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                  {/* <div>
                    <div className="box">
                      <div>
                        <div className="flx">
                          <h3>
                            Marketplac3 Community AMA Session (Ask me anything)
                          </h3>
                          <button>Up Coming</button>
                        </div>
                        <p>Discord & Telegram - 22/09/2023</p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="box">
                      <div>
                        <div className="flx">
                          <h3>
                            Marketplac3 Community AMA Session (Ask me anything)
                          </h3>
                          <button>Up Coming</button>
                        </div>
                        <p>Discord & Telegram - 22/09/2023</p>
                      </div>
                    </div>
                  </div> */}
                </Slider>
              </div>
            </div>
          </section>

          {/* {eventlist && eventlist.length > 0 ? (
            <section className="slide slidechg">
              <div className="container">
                <h2 className="h2tag" data-aos="fade-up">
                  Events
                </h2>
              </div>
              <div className="slidebg_out" data-aos="fade-up">
                <div className="slidebg">
                  <Slider {...slides}>
                    {eventlist && eventlist.length >= 1
                      ? eventlist.map((list) => {
                          return (
                            <a
                              href={`/events/${list._id}`}
                              style={{ textDecoration: "none" }}
                            >
                              <div>
                                <div className="box">
                                  <div
                                    className="box_background"
                                    style={{
                                      background: `url(${config.baseUrl}/events/${list.Image}) no-repeat center `
                                    }}
                                  >
                                    <div className="flx">
                                      <h3>{list.Title}</h3>
                                      <button>
                                        {differenct(list.postDate)}{" "}
                                      </button>
                                    </div>
                                    <p>
                                      Discord & Telegram -{" "}
                                      {moment(list.postDate).format("LL")}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          );
                        })
                      : ""}
                  </Slider>
                </div>
              </div>
            </section>
          ) : (
            ""
          )} */}
        </div>
      </div>
      <Footer />
    </div>
  );
}
