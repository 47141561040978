import axios from "axios";
import config from "../lib/config";

export const profileUpdate = async (data) => {
  try {
    const formData = new FormData();
    formData.append("profileImg", data.profileImg);
    formData.append("bannerImg", data.bannerImg);
    formData.append("customurl", data.customurl);
    formData.append("curraddress", data.curraddress);
    formData.append("personalsite", data.personalsite);
    formData.append("instagram", data.instagram);
    formData.append("twitter", data.twitter);
    formData.append("username", data.username);
    formData.append("email", data.email);
    formData.append("bio", data.bio);
    formData.append("name", data.name);
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/profileUpdate`,
      data: formData
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const getProfileData = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getProfileData`,
      data
    });
    // dispatch(respData.data);
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const notifivationEnables = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/notifivationEnables`,
      data
    });
    // dispatch(respData.data);
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const getChatUsers = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getChatUsers`,
      data
    });
    // dispatch(respData.data);
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const ReadMessage = async (data) => {
  console.log(data,"dataa");
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/ReadMessage`,
      data
    });
    // dispatch(respData.data);
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const getUsersCht = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getUsersCht`,
      data
    });
    // dispatch(respData.data);
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const getOwnerByAddr = async (data, search) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getOwnerByAddr`,
      data,
      params: { filter: search }
    });
    // dispatch(respData.data);
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const getParamsUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getParamsUser`,
      data
    });
    // dispatch(respData.data);
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const addNewUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/addNewUser`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const FollowCallAction = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/followCallAction`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const initMsgUpdate = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/initMsgUpdate`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const User_Follow_Get_Action = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getFollow`,
      data
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response?.data
    };
  }
};

export const DeleteAccountUser = async (data) => {
  try {
    const result = await axios({
      url: `${config.baseUrl}/api/routesUser/deleteaccount`,
      method: "post",
      data: data
    });
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const getsingleUsers = async (data) => {
  try {
    const result = await axios({
      url: `${config.baseUrl}/api/routesUser/getSinglUsers`,
      method: "post",
      data: data
    });
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyEmail = async (data) => {
  try {
    const result = await axios({
      url: `${config.baseUrl}/api/routesUser/verifyemail`,
      method: "post",
      data: data
    });
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const verifyOtp = async (data) => {
  try {
    const result = await axios({
      url: `${config.baseUrl}/api/routesUser/verifyOtp`,
      method: "post",
      data: data
    });
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const getCollection = async (data, filterCate) => {
  try {
    console.log(filterCate, "filter");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getallCollection`,
      data,
      params: { filter: filterCate }
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response.data
    };
  }
};

export const subscribeUser = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/usersubscribe`,
      data
    });
    console.log(respData);
    return {
      data: respData.data
    };
  } catch (error) {
    return {
      error: error.response?.data
    };
  }
};

export const SetReportpage = async (Payload) => {
  try {
    console.log(Payload);
    const result = await axios({
      url: `${config.baseUrl}/api/routesUser/collreportpage`,
      method: "post",
      data: Payload
    });
    console.log(result);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};
export const HeaderSearch = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/headerSearch`,
      data: { search: data }
    });
    console.log(respData);
    return {
      data: respData.data
    };
  } catch (error) {
    return {
      error: error.response?.data
    };
  }
};

export const readNotification = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/readNotification`,
      data
    });
    console.log(respData);
    return {
      data: respData.data
    };
  } catch (error) {
    return {
      error: error.response?.data
    };
  }
};

export const getOwnCollectionList = async (data, filterCate) => {
  try {
    console.log(filterCate, "filter");
    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}/api/routesUser/getOwnCollectionList`,
      data,
      params: { filter: filterCate }
    });
    return {
      data: respData.data
    };
  } catch (err) {
    return {
      error: err.response.data
    };
  }
};


export const checkUserName=async(data)=>{
  try {
    console.log(data,'datassssssssssss');

    if(data.address==null){
      return false
    }else {

      let respData = await axios({
        method: "post",
        url: `${config.baseUrl}/api/routesUser/checkUsername`,
        data,
      });
      return {
        data: respData.data
      };
    }
      
  } catch (error) {
    console.log(error);
  }
}


export const checkCollectionUrl=async(data)=>{
  try {
    console.log(data,'datassssssssssss1');

   

      let respData = await axios({
        method: "post",
        url: `${config.baseUrl}/api/routesUser/checkCollectionUrl`,
        data,
      });
      return {
        data: respData.data
      };
    
      
  } catch (error) {
    console.log(error);
  }
}

export const gettermsandcondition=async()=>{
  try {
      let respData = await axios({
        method: "get",
        url: `${config.baseUrl}/api/routesUser/gettermsandcondition`,
        
      });
      return {
        data: respData.data
      };
    
      
  } catch (error) {
    console.log(error);
  }
}


export const getPolicy=async()=>{
  try {
      let respData = await axios({
        method: "get",
        url: `${config.baseUrl}/api/routesUser/getPolicy`,
        
      });
      return {
        data: respData.data
      };
    
      
  } catch (error) {
    console.log(error);
  }
}
