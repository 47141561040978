import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";
import copyIcon from "../../assets/images/copy.svg";
import metamask from "../../assets/images/metamask.svg";
import Sidebar from "../../components/sidebar.js";
import { useSelector } from "react-redux";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import copy from "copy-to-clipboard";
import { toastAlert } from "../../lib/toastAlert.js";

export default function Wallet(props) {
  const navigate = useNavigate();
  const [copyTxt, setCopyTxt] = useState(false);
  const [copyTxt1, setCopyTxt1] = useState(false);
  const walletConnection = useSelector((state) => state.walletConnection);
  if (
    !walletConnection ||
    walletConnection.connect == "" ||
    walletConnection.address == ""
  ) {
    navigate("/");
  }
  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 18) + "......" + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  return (
    <div>
      <Navbar />
      {localStorage.getItem("nicholousWallAddr") ? (
        <div className="page_box">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <Sidebar />
              </div>
              <div className="col-lg-7">
                <div className="right_box">
                  <div class="create_nft half_side">
                    <div className="mb-4">
                      <label for="exampleInputEmail1" className="form-label">
                        Wallets{" "}
                      </label>
                      <p>Below are your connected and available wallets</p>
                    </div>
                    <div className="mb-4">
                      <label
                        for="exampleInputEmail1"
                        className="form-label label_flx"
                      >
                        Polygon<span className="connected">Connected</span>
                      </label>
                      <div className="input-group mb-3 two_grp">
                        <span
                          className="input-group-text left_gp"
                          id="basic-addon2"
                        >
                          <img
                            src={metamask}
                            alt="img"
                            className="img-fluid meta"
                          />
                        </span>
                        <input
                          type="text"
                          className="form-control border_left"
                          value={halfAddrShow(walletConnection.address)}
                        />
                        <span
                          onClick={() => {
                            copy(localStorage.getItem("nicholousWallAddr"));
                            setCopyTxt(true);
                            toastAlert('success','Copied')
                            setTimeout(function () {
                              setCopyTxt(false);
                            }, 800);
                            toastAlert("success", "Copied");
                          }}
                          className="input-group-text"
                          id="basic-addon2"
                        >
                          {copyTxt ? (
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                              style={{ color: "green", fontSize: "22px" }}
                            ></i>
                          ) : (
                            <img
                              src={copyIcon}
                              style={{ cursor: "pointer" }}
                              alt="img"
                              className="img-fluid"
                            />
                          )}
                        </span>
                      </div>
                    </div>

                    {/* <div className="mb-4">
                      <label
                        for="exampleInputEmail1"
                        className="form-label label_flx"
                      >
                        Ethereum
                        <span className="connected red">Disconnected</span>
                      </label>
                      <div className="input-group mb-3 two_grp">
                        <span
                          className="input-group-text left_gp"
                          id="basic-addon2"
                        >
                          <img
                            src={metamask}
                            alt="img"
                            className="img-fluid meta"
                          />
                        </span>
                        <input
                          type="text"
                          className="form-control border_left"
                          value={halfAddrShow(walletConnection.address)}
                        />
                        <span
                          className="input-group-text"
                          id="basic-addon2"
                          onClick={() => {
                            copy(localStorage.getItem("nicholousWallAddr"));
                            setCopyTxt1(true);
                            setTimeout(function () {
                              setCopyTxt1(false);
                            }, 800);
                          }}
                        >
                          {copyTxt1 ? (
                            <i
                              class="fa fa-check-circle"
                              aria-hidden="true"
                              style={{ color: "green", fontSize: "22px" }}
                            ></i>
                          ) : (
                            <img
                              src={copyIcon}
                              style={{ cursor: "pointer" }}
                              alt="img"
                              className="img-fluid"
                            />
                          )}
                        </span>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h3 style={{ textAlign: "center" }}>Please connect wallet</h3>
      )}

      <Footer />
    </div>
  );
}
