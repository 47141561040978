import React, { useEffect, useRef, useState } from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import btnarw_white from "../assets/images/btnarw_white.svg"; 
import dropdown_arw from "../assets/images/dropdown_arw.svg";
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { gettermsandcondition } from "../actions/users.js";


export default function TermsAndCondition() {
    const aRef=useRef()
    const [termsandcondition,settermsandcondition]=useState('')
    const [termsstatus,settermsstatus]=useState(false)
    
    useEffect(()=>{
      window.scrollTo({ top: 0, behavior: 'smooth' });
  },[])
  
    useEffect(()=>{
        getLoader()
    },[])
    
    const getLoader=async()=>{
        const result=await gettermsandcondition()
        console.log(result,'gettermsandcondition');
        if(result.data.status){
            settermsandcondition( result.data.data.content)
            settermsstatus(true)
        }
    }

    useEffect(()=>{
        if(aRef==null)return
        if(termsandcondition !==''){

            aRef.current.innerHTML = termsandcondition
        }
    },[aRef, termsandcondition]
    )

   

 
  return (
    <div>
      <Navbar />
      <div className="page_box">
        <div className="help">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="text-center">
                {/* <h2 className="h2tag mb-2">Terms And Condition</h2>
                <p className="para mb-5">Below are guides to using Terms And Condition</p> */}
                </div>
                {
                    termsstatus?
                    <div ref={aRef} />:'No Terms And Condition are available'
                }

              </div>
            </div>
          </div>
        </div> 
      </div> 
      <Footer />
    </div>
  );
}
