import React, { useEffect } from "react";
import Navbar from "../components/Navbar.js";
import { useNavigate } from "react-router-dom";

export default function Create() {
  const navigate = useNavigate();
  return (
    <div>
      <Navbar />
      <div className="page_box">
        <div className="create_box">
          <div className="container">
            <div className="text-center">
              <h2 className="h2tag mb-2">What do you want to create?</h2>
              <p className="para">
                Choose what you want to create from the option below
              </p>
            </div>

            <div className="flx">
              <div className="flx_box" onClick={() => navigate("/create-nft")}>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label" for="flexRadioDefault1">
                    NFT
                  </label>
                </div>
              </div>
              <div
                className="flx_box"
                onClick={() => navigate("/create-collection")}
              >
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label" for="flexRadioDefault2">
                    Collection
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
