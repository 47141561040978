import React, { useEffect } from "react";
import Navbar from "../components/Navbar.js";
import Footer from "../components/Footer.js";
import btnarw_white from "../assets/images/btnarw_white.svg"; 
import dropdown_arw from "../assets/images/dropdown_arw.svg";
import { Dropdown, DropdownButton } from 'react-bootstrap';

export default function Help() {
 
  return (
    <div>
      <Navbar />
      <div className="page_box">
        <div className="help">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 mx-auto">
                <div className="text-center">
                <h2 className="h2tag mb-2">Help center</h2>
                <p className="para mb-5">Below are guides to using Marketplac3</p>
                </div>
                 <a className="box">
                    <span>Getting Started</span>
                 </a>
                 <a className="box">
                    <span>Purchase NFTs</span>
                 </a>
                 <a className="box">
                    <span>Creating NFTs</span>
                 </a>
                 <a className="box">
                    <span>FAQs</span>
                 </a>
                 <a className="box">
                    <span>User safety</span>
                 </a>

                
              </div>
            </div>
          </div>
        </div> 
      </div> 
      <Footer />
    </div>
  );
}
