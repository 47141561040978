import React, { useEffect, useState } from "react";
import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";
import btnarw_red from "../../assets/images/btnarw_red.svg";
import btnarw_white from "../../assets/images/btnarw_white.svg";
import Sidebar from "../../components/sidebar.js";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  DeleteAccountUser,
  verifyEmail,
  verifyOtp,
  getsingleUsers
} from "../../actions/users";
import { toastAlert } from "../../lib/toastAlert.js";
import { useSelector } from "react-redux";

const EmailValidation = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .email("Email not valid")
    .matches(/^(?!.*@[^,]*,)/)
});
const otpValidation = Yup.object().shape({
  email: Yup.string()
    .required("Required")
    .email("Email not valid")
    .matches(/^(?!.*@[^,]*,)/),
  otp: Yup.number().required("Required")
});
export default function Account(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [showinput, setShowInput] = useState(false);
  const [user, setUser] = useState({});
  const [btndisable, setbtndisable] = useState(false);

  const [otpBtn, setotpBtn] = useState(false);

  useEffect(() => {
    getInitialData();
  }, [walletConnection.address]);

  const getInitialData = async () => {
    const Payload = {
      currAddr: walletConnection.address
    };
    const result = await getsingleUsers(Payload);
    console.log(result, "resulefsedfet");
    setUser(result?.user);
  };

  const DeleteAccount = async () => {
    const Payload = {
      currAddr: walletConnection.address
    };
    const result = await DeleteAccountUser(Payload);
    if (result.status) {
      toastAlert("success", result.message);
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      toastAlert("error", result.message);
    }
  };
  return (
    <div>
      <Navbar />
      {localStorage.getItem("nicholousWallAddr") ? (
        <div className="page_box settingpad">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <Sidebar />
              </div>
              <div className="col-lg-7">
                <div className="right_box">
                  {user && user.emailverified && user.otpverified ? (
                    <div class="create_nft half_side">
                      <div className="mb-4">
                        <label for="exampleInputEmail1" className="form-label">
                          Delete your account{" "}
                        </label>
                        <p>
                          Please note: when you delete your account there’s no
                          going back
                        </p>
                      </div>
                      <button
                        className="primary_btn red w-75"
                        type="button"
                        id=""
                        onClick={DeleteAccount}
                      >
                        <span>Yes delete</span>
                        <img
                          src={btnarw_red}
                          alt="img"
                          className="ms-2 img-fluid"
                        />
                      </button>
                    </div>
                  ) : (
                    <Formik
                      initialValues={{
                        email: "",
                        otp: ""
                      }}
                      validationSchema={
                        showinput ? otpValidation : EmailValidation
                      }
                      onSubmit={async ({ email, otp }) => {
                        console.log(email, otp);
                        if (!showinput) {
                          setbtndisable(true);
                          const payload = {
                            currAddr: walletConnection.address,
                            email: email
                          };
                          const data = await verifyEmail(payload);
                          console.log(data);
                          if (data.status) {
                            toastAlert("success", data.message);
                            setShowInput(data.status);
                          } else {
                            toastAlert("error", data.message);
                          }
                        } else {
                          const payload = {
                            email: email,
                            otp: otp
                          };
                          setotpBtn(true);
                          const data = await verifyOtp(payload);
                          console.log(data);
                          if (data.status) {
                            toastAlert("success", data.message);
                            setShowInput(data.status);
                            setTimeout(() => {
                              window.location.reload();
                            }, 2000);
                          } else {
                            toastAlert("error", data.message);
                          }
                        }
                      }}
                    >
                      {({ errors, touched, isSubmitting }) => (
                        <Form>
                          <div class="create_nft p-0">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Email{" "}
                            </label>
                            <p>Your email for notification</p>
                            <div className="input-group mb-3 flex_align">
                              <Field
                                type="text"
                                name="email"
                                className="form-control bdr"
                              />

                              <br />
                              {!showinput ? (
                                <button
                                  className="primary_btn dark"
                                  type="submit"
                                  // disabled={isSubmitting}
                                  id=""
                                  disabled={btndisable}
                                >
                                  <span> Confirm email</span>
                                  <img
                                    src={btnarw_white}
                                    alt="img"
                                    className="ms-2 img-fluid"
                                  />
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                            {errors.email && touched.email ? (
                              <span className="error-msg">{errors.email}</span>
                            ) : null}
                          </div>

                          {showinput ? (
                            <div class="create_nft p-0">
                              <label
                                for="exampleInputEmail1"
                                className="form-label"
                              >
                                Verify OTP{" "}
                              </label>
                              <p>Your OTP will expire after 5 Minutes</p>
                              <div className="input-group mb-3 flex_align">
                                <Field
                                  type="text"
                                  name="otp"
                                  className="form-control bdr"
                                />

                                <br />

                                <button
                                  className="primary_btn dark"
                                  type="submit"
                                  id=""
                                  disabled={otpBtn}
                                >
                                  <span>Verify OTP</span>
                                  <img
                                    src={btnarw_white}
                                    alt="img"
                                    className="ms-2 img-fluid"
                                  />
                                </button>
                              </div>
                              {errors.otp && touched.otp ? (
                                <span className="error-msg">{errors.otp}</span>
                              ) : null}
                            </div>
                          ) : (
                            ""
                          )}
                        </Form>
                      )}
                    </Formik>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <h3 style={{ textAlign: "center" }}>Please connect wallet</h3>
      )}

      <Footer />
    </div>
  );
}
