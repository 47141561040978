import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";
import btnarw_white from "../../assets/images/btnarw_white.svg";
import camera from "../../assets/images/camera.svg";
import Sidebar from "../../components/sidebar.js";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import config from "../../lib/config";
import { profileUpdate, getProfileData } from "../../actions/users";
import { useSelector, useDispatch } from "react-redux";
import { toastAlert } from "../../lib/toastAlert.js";
import { useNavigate } from "react-router-dom";
import { getUsers } from "../../reducers/Actions.js";

const UserSchema = Yup.object().shape({
  Name: Yup.string()
    .required("Required")
    .test("value", "Required", (value) => value.trim().length != 0)
    .matches(
      /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð '])+$/u,
      "Numbers & Spcial charactor not allowed"
    ),
  userName: Yup.string()
    .required("Required")
    .test("value", "Required", (value) => value.trim().length != 0),
  Email: Yup.string()
    .required("Required")
    .email()
    .test("value", "Required", (value) => value.trim().length != 0)
    .matches(/^(?!.*@[^,]*,)/),
  shortBio: Yup.string(),
  twitter: Yup.string(),
  instagram: Yup.string(),
  socialLink: Yup.string()
});

export default function Profile_setting(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const walletConnection = useSelector((state) => state.walletConnection);
  const [users, setUsers] = useState("");
  const [bannerImg, setBannerImg] = useState("");
  const [bannerImgPrev, setBannerImgPrev] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [profileImgPrev, setProfileImgPrev] = useState("");

  if (
    localStorage.getItem("nicholousWallAddr") == "" ||
    localStorage.getItem("nicholousWallAddr") == null
  ) {
    navigate("/");
  }

  useEffect(() => {
    getProfile();
  }, [walletConnection.address]);

  const getProfile = async () => {
    if (walletConnection.address) {
      const payload = {
        address: walletConnection.address
      };
      console.log(payload, "efef");
      const data = await getProfileData(payload);
      console.log(data, "data");
      // dispatch(getUsers(data?.data?.data));
      setUsers(data?.data?.data);
    }
  };

  const handleFileChange = (e) => {
    var file = e.target.files[0];
    const fileSize = file.size / 1024 / 1024;
    console.log(fileSize, "fileSize");
    if (fileSize > 10) {
      toastAlert("error", "file size must be below 10 MB");
      return false;
    }
    if (e.target.name == "profileImg") {
      setProfileImg(e.target.files[0]);
      setProfileImgPrev(URL.createObjectURL(e.target.files[0]));
    } else {
      setBannerImg(e.target.files[0]);
      setBannerImgPrev(URL.createObjectURL(e.target.files[0]));
    }
  };

  return (
    <div>
      <Navbar />
      {localStorage.getItem("nicholousWallAddr") ? (
        <div className="page_box">
          <div className="container">
            <div className="row">
              <div className="col-lg-3">
                <Sidebar />
              </div>
              <Formik
                initialValues={{
                  Name: users?.name,
                  userName: users?.username,
                  shortBio: users?.bio,
                  socialLink: users?.personalsite,
                  twitter: users?.twitter,
                  instagram: users?.instagram,
                  Email: users?.email
                }}
                enableReinitialize={true}
                validationSchema={UserSchema}
                onSubmit={async ({
                  Name,
                  userName,
                  shortBio,
                  twitter,
                  instagram,
                  socialLink,
                  Email
                }) => {
                  if (walletConnection.address) {
                    const payload = {
                      name: Name,
                      bio: shortBio,
                      username: userName,
                      twitter: twitter,
                      instagram: instagram,
                      personalsite: socialLink,
                      curraddress: walletConnection.address,
                      profileImg: profileImg,
                      bannerImg: bannerImg,
                      customurl: `${config.frontUrl}/${userName}`,
                      email: Email
                    };
                    console.log(payload, "efeffe");
                    const result = await profileUpdate(payload);
                    console.log(result, "result");
                    if (result.data.status == true) {
                      toastAlert("success", result.data.messages);
                      setTimeout(() => {
                        // window.location.reload();
                      }, 1000);
                    } else {
                      toastAlert("error", result.data.messages);
                    }
                    console.log(result, "result");
                  }
                }}
              >
                {({ errors, touched, isSubmitting, values }) => (
                  <div className="col-lg-7">
                    <Form>
                      <div className="right_box">
                        <div className="prf_ban">
                          <div className="img choose">
                            <img
                              src={
                                bannerImgPrev
                                  ? bannerImgPrev
                                  : users.coverimage
                                  ? `${config.baseUrl}/usersProfile/${users.coverimage}`
                                  : require("../../assets/images/profile_setting_ban.png")
                              }
                              alt="img"
                              className="img-fluid prf_banimg"
                            />

                            <img
                              src={camera}
                              alt="img"
                              className="img-fluid camera"
                            />
                            <input
                              type="file"
                              name="bannerImg"
                              accept="image/*"
                              className="choosefile_bg"
                              onChange={handleFileChange}
                            />
                          </div>
                          <div className="prf_detail">
                            <div className="prf_ban_user">
                              <div className="choosefile">
                                <img
                                  src={
                                    profileImgPrev
                                      ? profileImgPrev
                                      : users.image
                                      ? `${config.baseUrl}/usersProfile/${users.image}`
                                      : require("../../assets/images/LogosSampleAvator.png")
                                  }
                                  alt="img"
                                  className="img-fluid"
                                />
                                <input
                                  type="file"
                                  name="profileImg"
                                  accept="image/*"
                                  onChange={handleFileChange}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="create_nft half_side">
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Name<span className="star">*</span>
                            </label>
                            <p>
                              This is your name that will appear on your profile{" "}
                            </p>
                            <Field
                              type="text"
                              name="Name"
                              className="form-control"
                            />
                            {errors.Name && touched.Name ? (
                              <span className="error-msg">{errors.Name}</span>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Username<span className="star">*</span>
                            </label>
                            <p>
                              Your profile will be available @
                              marketplac3.io/username
                            </p>
                            <Field
                              type="text"
                              name="userName"
                              className="form-control"
                            />
                            {errors.userName && touched.userName ? (
                              <span className="error-msg">
                                {errors.userName}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Email<span className="star">*</span>
                            </label>

                            <Field
                              type="text"
                              name="Email"
                              className="form-control"
                            />
                            {errors.Email && touched.Email ? (
                              <span className="error-msg">{errors.Email}</span>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Short bio{" "}
                            </label>
                            <Field
                              name="shortBio"
                              type="text"
                              className="form-control"
                            />
                          </div>
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Social links{" "}
                            </label>
                            <p>
                              Add your existing social links to build a strong
                              portfolio
                            </p>
                            <Field
                              type="text"
                              name="socialLink"
                              className="form-control"
                              onKeyDown={(evt) =>
                                ["+", "-", ",", " "].includes(evt.key) &&
                                evt.preventDefault()
                              }
                            />
                          </div>
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Twitter{" "}
                            </label>
                            <Field
                              name="twitter"
                              type="text"
                              className="form-control"
                              onKeyDown={(evt) =>
                                ["+", "-", ",", " "].includes(evt.key) &&
                                evt.preventDefault()
                              }
                            />
                          </div>
                          <div className="mb-4">
                            <label
                              for="exampleInputEmail1"
                              className="form-label"
                            >
                              Instagram{" "}
                            </label>
                            <Field
                              name="instagram"
                              type="text"
                              className="form-control"
                              onKeyDown={(evt) =>
                                ["+", "-", ",", " "].includes(evt.key) &&
                                evt.preventDefault()
                              }
                            />
                          </div>
                          <button
                            className="primary_btn dark w-100 mt-5"
                            type="submit"
                          >
                            Save Changes
                            <img
                              src={btnarw_white}
                              alt="img"
                              className="ms-3 img-fluid"
                            />
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          </div>
        </div>
      ) : (
        <h3 style={{ textAlign: "center" }}>Please connect wallet</h3>
      )}

      <Footer />
    </div>
  );
}
