import React, { useState, useRef, useEffect } from "react";
import Navbar from "../../components/Navbar.js";
import option from "../../assets/images/option.svg";
import btnarw from "../../assets/images/btnarw.svg";
import telegram from "../../assets/images/telegram.svg";
import leftarw_dark from "../../assets/images/leftarw_dark.svg";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useLocation } from "react-router-dom";
import config from "../../lib/config";
import { getChatUsers, ReadMessage } from "../../actions/users";
import { getCompleteSwap } from "../../actions/nfts";
import { halfAddrShow } from "../../helper/halfAddr.js";

const socket = io(config.baseUrl);
export default function Chat(props) {
  const { state } = useLocation();
  const navigate = useNavigate();

  const scrollRef = useRef();

  // redux
  const walletConnection = useSelector((state) => state.walletConnection);
  const getUsers = useSelector((state) => state.getUsers);
  const getUsersData = useSelector((state) => state.getUsers);

  // state
  const [isActive, setIsActive] = useState(false);
  const [msg, setMsg] = useState("");
  const [contacts, setContacts] = useState([]);
  const [currentChat, setCurrentChat] = useState([]);
  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentSelected, setCurrentSelected] = useState(undefined);
  const [messages, setMessages] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState(null);
  const [swapComp, setSwapComp] = useState([]);
  const [notiMsg, setNotiMsg] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState({});
  const [unReadMsg, setUnreadMsg] = useState([]);
  const [messcount, setmessCount] = useState(false);
  const [shownew, setshownew] = useState(true);

  // function
  const halfAddrShow1 = (addr) => {
    if (addr) {
      return addr.substring(0, 3) + "..." + addr.slice(addr.length - 2);
    } else {
      return "";
    }
  };

  useEffect(() => {
    Vali();
    getCurrentChatUser();
  }, [walletConnection.address]);

  useEffect(() => {
    getUnreadMsg();
  }, [walletConnection.address, getUsers]);

  useEffect(() => {
    getAllMessages();
    CompleteSwap();
  }, [walletConnection.address, currentChat, messcount]);

  const Vali = () => {
    setCurrentUser(walletConnection.address);
  };

  const getAllMessages = async () => {
    const recieveMessageRoute = `${config.baseUrl}/api/routesUser/getMsg`;
    const response = await axios.post(recieveMessageRoute, {
      from: getUsers?._id,
      to: currentChat?._id
    });
    console.log(response.data, "Gtmdg");
    setMessages(response?.data);
  };

  const getCurrentChatUser = async () => {
    if (walletConnection.address) {
      const payload = {
        address: walletConnection.address
      };
      const data = await getChatUsers(payload); // list of user messaged by curr user
      console.log(data, "ChatUser");
      setContacts(data?.data?.data);
      let dd;
      let ii;
      const DefaultCont = data?.data?.data?.map((el, i) => {
        if (el.curraddress == state) {
          dd = el;
          ii = i;
        }
      });
      console.log(DefaultCont, "DefaultCont", dd, ii, state);
      setCurrentChat(dd);
      setCurrentSelected(ii);
    }
  };

  const showchatbox = async (contact, index) => {
    console.log(
      contact,
      index,
      "index___Indec",
      contact?.curraddress,
      getUsers?.curraddress
    );
    setIsActive(true);
    setCurrentSelected(index);
    setCurrentChat(contact);
    setshownew(index);

    socket.emit("readMesage", {
      toData: contact?.curraddress
    });

    await getUnreadMsg();
  };

  const closechatbox = () => {
    setIsActive(false);
  };

  const sendChat = async (e) => {
    e.preventDefault();
    if (msg.length > 0 && walletConnection.address) {
      socket.emit("send-msg", {
        to: currentChat?._id,
        from: getUsers?._id,
        msg
      });

      var alreadyMsg = [...messages];

      alreadyMsg.push({
        fromSelf: true,
        to: currentChat?._id,
        from: getUsers?._id,
        message: msg,
        _id: getUsers?._id
      });
      setTimeout(function () {
        setMessages(alreadyMsg);
      }, 200);

      setMsg("");
    }
  };

  useEffect(() => {
    console.log(getUsers, "getUsers", currentChat, messages);
    if (socket && getUsers && getUsers._id) {
      console.log("1_Naveen");
      var sckUser = getUsers?._id?.toString() + "-msg-recieve";
      socket.on(sckUser, async (respmsg) => {
        console.log("2_Naveen", respmsg);
        // setMessages((prev) => [...prev, ...[respmsg]]);

        setmessCount(true);
        console.log(
          currentChat?._id,
          currentChat?.curraddress,
          "asdassasdssssssssssssssssss"
        );
        if (currentChat) {
          if (respmsg.from === currentChat?._id) {
            console.log(
              respmsg.from === currentChat?._id,
              "_ididididi",
              respmsg.from,
              currentChat?._id
            );
            setArrivalMessage({ fromSelf: false, message: respmsg.message });

            socket.emit("readMesage", {
              toData: currentChat?.curraddress
            });

            await getUnreadMsg();
          } else {
            await getUnreadMsg();
          }
        } else {
          await getUnreadMsg();
        }
      });
    }

    return () => {
      if (socket && getUsers && getUsers._id) {
        var sckUser = getUsers?._id?.toString() + "-msg-recieve";
        socket.off(sckUser);
      }
    };
  }, [getUsers, currentChat]);

  const getUnreadMsg = async () => {
    console.log("CheckkkkkWorking");
    socket.emit("notifyme", {
      toAddrs: getUsers?.curraddress
    });
    socket.on("notifyCount", async (data) => {
      console.log(data, "fccffcsdfdsfdsfdsfsdfdsfdsfdsfdsf");
      setUnreadMsg(data);
    });
  };

  useEffect(() => {
    arrivalMessage && setMessages((prev) => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  console.log(messages, "&&&&&&&&&&&&&&&&&");

  useEffect(() => {
    if (scrollRef) {
      scrollRef.current.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }
  }, [messages]);

  const CompleteSwap = async () => {
    if (walletConnection.address) {
      const payload = {
        from: walletConnection.address,
        to: currentChat?.curraddress
      };
      console.log(payload, "payload");
      const data = await getCompleteSwap(payload);
      console.log(data, "fffh__fhedfhed");
      setSwapComp(data?.data?.data);
    }
  };

  console.log(state, "statestatestate");
  return (
    <div>
      <Navbar />
      <div className="chat">
        <div className="container">
          <div className="chat_flx">
            <div className="msg">
              <h5>Messages</h5>
              {contacts.length > 0 &&
                contacts.map((contact, index) => {
                  return (
                    <div
                      className={`box ${
                        index === currentSelected ? "selected" : ""
                      }`}
                      onClick={() => showchatbox(contact, index)}
                    >
                      <img
                        src={
                          contact.image
                            ? `${config.baseUrl}/usersProfile/${contact?.image}`
                            : require("../../assets/images/LogosSampleAvator.png")
                        }
                        style={{ borderRadius: "50px" }}
                        alt="img"
                        className="img-fluid me-3 us_r"
                      />
                      <div>
                        <h5
                          className={`${
                            unReadMsg?.count?.filter(
                              (el) => el._id == contact?.curraddress
                            )?.[0]?.count > 0
                              ? "fw-bold"
                              : ""
                          }`}
                        >
                          {contact?.username
                            ? contact?.username
                            : halfAddrShow(contact?.curraddress)}
                        </h5>
                        <p
                          className={`${
                            unReadMsg?.count?.filter(
                              (el) => el._id == contact?.curraddress
                            )?.[0]?.count > 0
                              ? "fw-bold"
                              : ""
                          }`}
                        >
                          {shownew !== index ? (
                            <span>
                              {unReadMsg?.count?.filter(
                                (el) => el._id == contact?.curraddress
                              )?.[0]?.count > 0 && (
                                <strong
                                  class="badge bg-success"
                                  style={{ marginRight: "7px" }}
                                >
                                  New Message
                                </strong>
                              )}
                              <span>
                                <strong
                                  class="badge bg-success"
                                  style={{ marginRight: "7px", float: "right" }}
                                >
                                  {
                                    unReadMsg?.count?.filter(
                                      (el) => el._id == contact?.curraddress
                                    )?.[0]?.count
                                  }
                                </strong>
                              </span>
                            </span>
                          ) : (
                            ""
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className={`chat_box ${isActive ? "show" : ""}`}>
              <div className="chat_abv">
                {state != null ? (
                  <div
                    className="back"
                    onClick={() => {
                      setIsActive(false);
                    }}
                  >
                    {/* <Link to={`${config.frontUrl}/user/${state}`}> */}
                      <img
                        src={leftarw_dark}
                        alt="img"
                        className="img-fluid me-3"
                        onClick={closechatbox}
                      />
                    {/* </Link> */}
                    <span>Back</span>
                  </div>
                ) : (
                  <div
                    className="back"
                    onClick={() => {
                      setIsActive(false);
                    }}
                  >
                    {/* <Link to={`${config.frontUrl}/profile`}> */}
                      <img
                        src={leftarw_dark}
                        alt="img"
                        className="img-fluid me-3"
                        onClick={closechatbox}
                      />
                    {/* </Link> */}
                    <span>Back</span>
                  </div>
                )}

                <div className="userprf">
                  <img
                    src={
                      currentChat?.image
                        ? `${config.baseUrl}/usersProfile/${currentChat?.image}`
                        : require("../../assets/images/LogosSampleAvator.png")
                    }
                    alt="img"
                    className="img-fluid"
                  />
                  <h5>
                    {currentChat?.username
                      ? currentChat?.username
                      : halfAddrShow(currentChat?.curraddress)}
                  </h5>
                  {/* <span>Last seen at 12:32 AM</span> */}
                </div>
                {/* <div class="btn-group">
                  <button
                    class="btn btn-secondary dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="true"
                    aria-expanded="false"
                  >
                    <img src={option} alt="img" className="img-fluid" />
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <a class="dropdown-item" href="#">
                        options
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>

              <div className="chats" ref={scrollRef}>
                <ul>
                  {messages &&
                    messages.length > 0 &&
                    messages?.map((message) => {
                      var fromImg = require("../../assets/images/LogosSampleAvator.png");
                      var toImg = require("../../assets/images/LogosSampleAvator.png");
                      console.log(message, "+---------message", getUsers?._id);
                      if (
                        message?.from == getUsers?._id ||
                        message?.to == getUsers?._id
                      ) {
                        fromImg = getUsers?.image
                          ? `${config.baseUrl}/usersProfile/${getUsers?.image}`
                          : require("../../assets/images/LogosSampleAvator.png");
                      }
                      if (
                        message?.from == currentChat?._id ||
                        message?.to == currentChat?._id
                      ) {
                        toImg = currentChat?.image
                          ? `${config.baseUrl}/usersProfile/${currentChat?.image}`
                          : require("../../assets/images/LogosSampleAvator.png");
                      }

                      return (
                        <>
                          {console.log(message, "HTMlmessage")}
                          {message?.from == getUsers._id ? (
                            <li className="sended">
                              <img
                                src={fromImg}
                                alt="img"
                                className="img-fluid user"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = require("../../assets/images/LogosSampleAvator.png");
                                }}
                              />
                              <p>{message.message}</p>
                            </li>
                          ) : (
                            <li className="recieved">
                              <img
                                src={toImg}
                                alt="img"
                                className="img-fluid user"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = require("../../assets/images/LogosSampleAvator.png");
                                }}
                              />
                              <p>{message.message}</p>
                            </li>
                          )}
                        </>
                      );
                    })}
                </ul>
              </div>

              <div className="chat_blw">
                <div class="create_nft p-0">
                  <form>
                    {currentChat ? (
                      <div className="input-group mb-3 ">
                        <input
                          type="text"
                          className="form-control me-3"
                          placeholder="Type your message here"
                          onChange={(e) => setMsg(e.target.value)}
                          value={msg}
                        />
                        {msg !== "" && currentChat && (
                          <button
                            onClick={sendChat}
                            type="submit"
                            className="primary_btn p-0 px-3"
                          >
                            <img
                              src={telegram}
                              alt="img"
                              className="img-fluid"
                            />
                          </button>
                        )}
                      </div>
                    ) : (
                      <p class="noswaps">No user selected</p>
                    )}
                  </form>
                </div>
              </div>
            </div>

            <div className="msg swaps">
              <h5>Complete Swaps</h5>
              {swapComp.length > 0 ? (
                <>
                  <table>
                    <tr>
                      <td>From</td>
                      <td>To</td>
                      <td>NFT</td>
                    </tr>
                    {swapComp?.map((swap, i) => {
                      return (
                        <tr>
                          <td>{halfAddrShow1(swap.fromUser)}</td>
                          <td>{halfAddrShow1(swap.toUser)}</td>
                          <td>{swap.fromTokenName}</td>
                        </tr>
                      );
                    })}
                  </table>
                </>
              ) : (
                <>No swaps yet</>
              )}
              {/* 
              <button className="primary_btn w-100">
                <span>Swap New NFT</span>
                <img src={btnarw} alt="img" className="img-fluid ms-3" />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
